import React, {useEffect} from "react";
import { Box, Container, Typography, Button, Grid } from "@mui/material";
import InforestLogo from '../assets/INFOREST.svg'
import InfohotelLogo from '../assets/INFOHOTEL.svg'
import InfobackLogo from '../assets/INFOBACK.svg'
import InfofactLogo from '../assets/INFOFACT.svg'
import AMCLogo from '../assets/AMC_186X60.svg'


import InforestImagen from '../assets/post.jpg'

import InfohotelImagen from '../assets/clienteInfohotel.jpg'
import SolucionBox from "../components/Shared/SolucionBox";

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import SolucionBox2 from "../components/Shared/SolucionBox2";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const soluciones = [
    { logo: InforestLogo, descripcion: '', pasivo: '#42C67E', activo: '#79DD8B', imagen: InforestImagen, ruta: '/producto/software/inforest'},
    { logo: InfohotelLogo, descripcion: '', pasivo: '#4884E2', activo: '#69C4DE', imagen: InfohotelImagen, ruta: '/producto/software/infohotel' },
    { logo: InfobackLogo, descripcion: '', pasivo: '#F07B43', activo: '#F5A458', imagen: '', ruta: '/producto/software/infoback'},
    { logo: InfofactLogo, descripcion: '', pasivo: '#80CFBC', activo: '#69C4DE', imagen: '', ruta: '/producto/software/infoback'},
    { logo: AMCLogo, descripcion: '', pasivo: '#30ADD2', activo: '#69C4DE', imagen: '', ruta: '/productos' }
]

const SolucionesPage = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box
                sx={{
                    width: '100%',
                    height: { xs: '360px', md: '400px' },
                    backgroundColor: '#4884E2',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    alignItems: { xs: '', md: 'center' },
                    justifyContent: 'center',
                    paddingLeft: { xs: '20px', md: '0px', lg: '0px', xl: '0px' },
                    paddingTop: '100px',
                    gap: { xs: '20px', md: '148px' },
                    borderRadius: '0px 0px 80px 0px'
                }}
            >
                <Typography component={'h1'} color={'white'} sx={{ lineHeight: '68px', letterSpacing: '1.5px', fontSize: { xs: '32px', md: '42px' }, textAlign: 'left', fontFamily: 'PoppinsSemiBold' }}>
                    Soluciones
                </Typography>
                <Typography component={'p'} color={'white'} sx={{ lineHeight: { xs: '24px', md: '32px' }, fontSize: { xs: '18px', md: '20px' }, textAlign: 'left', fontFamily: 'Poppins', width: { xs: '100%', md: '820px' } }}>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing eli. Aenean commo do ligula eget dolor. Aenean massa Cum sociis nat.
                </Typography>

            </Box>


            {/* <Grid container
                sx={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    paddingLeft: { xs: '0px', md: '0px' },
                    flexDirection: {xs: 'column', sm: 'row', md:'column'},
                    alignItems: {xs:'', md: ''},
                    justifyContent: '',
                    marginTop: '100px',
                    gap: '40px',
                    backgroundColor: 'transparent',
                    
                }}
                
               
            >

                

                {soluciones.map((sol, index) => (

                    <Grid item key={index} xs={12} sm={12} md={6} lg={12} xl={12}>
                        <SolucionBox solucion={sol} />
                    </Grid>



                ))}





            </Grid> */}




            <Box 
            
                width={'100%'}
                sx={{
                    display: 'flex',
         
                    justifyContent: 'center'
                }}
            >

            <Grid container spacing={2} sx={{
                    width: {xs:'90%', md: '100%', lg: '100%', xl:'70%'}
                }}>

                    {soluciones.map((sol, index) => (

                        <Grid item key={index} xs={12} sm={6} md={12} lg={12} xl={12}>
                            <SolucionBox2 solucion={sol} />
                        </Grid>



                    ))}


                </Grid>


            </Box>
                





        </Box>)
}

export default SolucionesPage

{/* <Box
                    sx={{
                        width: '1200px',
                        height: '360px',
                        borderRadius: '60px 20px',
                        boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                        backgroundColor: '#FFF',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',

                        ':hover': {

                            '.bgCapacitacion': {
                                boxShadow: "none",
                            },
                            '.textCapacitacion': {
                                display: "none"
                            }
                        }
                    }}
                >

                    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', width: '600px', paddingX: '40px', paddingBottom: '40px', paddingTop: '60px', justifyContent: 'center', alignItems: 'center' }}>

                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '24px',

                            }}

                        >
                            <img width={'280px'} height={'60px'} src={Logo}></img>
                        </Box>

                        <Box sx={{ width: '760px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component={'p'}

                                sx={{
                                    color: '#1E2947',
                                    fontFamily: 'PoppinsLight',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    width: '520px',
                                    height: '96px'
                                }}

                            >
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium.
                            </Typography>
                            <Button variant="contained"
                                sx={{
                                    backgroundColor: '#42C67E', borderRadius: '30px', padding: '12px 40px', width: '240px', height: '56px', textTransform: 'uppercase',
                                    ":hover": {
                                        backgroundColor: '#79DD8B'
                                    },
                                    boxShadow: 'none',
                                    fontSize: '14px',
                                    paddingX: '24px',
                                    paddingY: '12px',
                                    fontFamily: 'PoppinsLight',
                                    alignSelf: 'center',marginTop: '24px'                                }}>aprende con nosotros</Button>
                        </Box>

                    </Box>

                    <Box


                        className='bgCapacitacion'
                        sx={{
                            backgroundImage: `url(${Imagen})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            borderRadius: '60px 20px',
                            boxShadow: 'inset 0 0 0 1000px rgba(30, 41, 71, 0.4)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flex: 1,
                        }}
                    >


                    </Box>







                </Box> */}