import React, {useState, useRef} from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import PasivoLogo from '../assets/pasivologo.svg'
import HoverLogo from '../assets/hoverlogo.svg'
import FacebookLogo from '../assets/fbpasivo.svg'
import InstagramLogo from '../assets/igpasivo.svg'
import LinkedInLogo from '../assets/lipasivo.svg'
import TikTokLogo from '../assets/ttpasivo.svg'
import FbHover from '../assets/fbhover.svg'
import IgHover from '../assets/ighover.svg'
import LiHover from '../assets/lihover.svg'
import TTHover from '../assets/tthover.svg'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';



const logoNoScroll = {
    height: '84px',
    width: '240px',
    backgroundImage: `url(${PasivoLogo})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${HoverLogo})`
    },
}


const Footer = () => {


    const [arrowUp, setArrowUp] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setArrowUp(true)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'static', left: 0, bottom: 0, right: 0 }}>

            <Box

                sx={{
                    backgroundColor: '#19437F',
                    width: '100%',
                    paddingY: '40px',
                    paddingX: { xs: '20px', md: '80px' },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: { xs: '', md: 'center' },
                    alignItems: 'center'
                }}
            >

                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: 'row'
                    },

                }} gap={'40px'} marginRight={'140px'}>

                    <Box
                    >
                        <Typography sx={{ fontFamily: 'PoppinsBold', marginBottom: {xs:'0px' , md:'32px'}, color: 'white' }}>Explora</Typography>
                        {arrowUp ? <KeyboardArrowUpIcon onClick={() => setArrowUp(false)}
                                style={{color: 'white'}} /> : <KeyboardArrowDownIcon onClick={handleClick} style={{color: 'white'}} />}
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex'
                                }
                            }}
                        >
                            <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Nombre de entrada a proyecto</Typography>
                            <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Nombre de entrada a proyecto</Typography>
                            <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Nombre de entrada a proyecto</Typography>
                            <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Nombre de entrada a proyecto</Typography>
                            <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Nombre de entrada a proyecto</Typography>
                        </Box>

                    </Box>
                    <Box>
                        <Typography sx={{ fontFamily: 'PoppinsBold', marginBottom: '32px', color: 'white' }}>Nosotros</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Nuestra historia</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Nuestro equipo</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Nuestros proyectos</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>Trabaja con nosotros</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontFamily: 'PoppinsBold', marginBottom: '32px', color: 'white' }}>Nuestros servicios</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>INFORest</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>INFOHotel</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>INFOBack</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>INFOfact</Typography>
                        <Typography sx={{ marginBottom: '24px', fontFamily: 'PoppinsLight', color: 'white' }}>AMC</Typography>
                    </Box>

                    <Box width={'360px'} height={"240px"}>
                        <Typography sx={{ fontFamily: 'PoppinsBold', color: 'white' }}>Enterate de todo</Typography>
                        <Typography sx={{ marginBottom: '20px', fontFamily: 'PoppinsLight', color: 'white' }}>¡Síguenos y no te pierdas de nada!</Typography>
                        <Box display={"flex"} flexDirection={'row'} gap={'40px'} marginBottom={"40px"}>
                            <Link
                                href='https://www.facebook.com/InfomaticaPeru'
                                sx={{
                                    backgroundImage: `url(${FacebookLogo})`,
                                    width: '48px',
                                    height: '48px',
                                    padding: '12px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '24px',
                                    boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',

                                    ":hover": {
                                        backgroundImage: `url(${FbHover})`
                                    }
                                }}

                            >

                            </Link>
                            <Link
                                href='https://www.instagram.com/infomatica.peru/?hl=es'
                                sx={{
                                    backgroundImage: `url(${InstagramLogo})`,
                                    width: '48px',
                                    height: '48px',
                                    padding: '12px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '24px',
                                    boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                                    ":hover": {
                                        backgroundImage: `url(${IgHover})`
                                    }
                                }}

                            >

                            </Link>
                            <Link

                                href="https://www.linkedin.com/company/infomatica"
                                sx={{
                                    backgroundImage: `url(${LinkedInLogo})`,
                                    width: '48px',
                                    height: '48px',
                                    padding: '12px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '24px',
                                    boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                                    ":hover": {
                                        backgroundImage: `url(${LiHover})`
                                    }

                                }}

                            >

                            </Link>
                            <Link
                                href="https://www.tiktok.com/@infomatica.peru"
                                sx={{
                                    backgroundImage: `url(${TikTokLogo})`,
                                    width: '48px',
                                    height: '48px',
                                    padding: '12px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '24px',
                                    boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                                    ":hover": {
                                        backgroundImage: `url(${TTHover})`
                                    }
                                }}
                            >
                            </Link>
                        </Box>

                        <Box sx={logoNoScroll}></Box>
                    </Box>

                </Box>



            </Box>

            <Box style={{ display: 'flex', backgroundColor: '#232323', height: { xs: '92px', md: '48px' }, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontFamily: 'PoppinsLight', fontWeight: '500px', color: 'white', fontSize: '14px' }}>
                    Copyright © 2023 Infomática. Todos los derechos reservados | Términos y condiciones | Política de privacidad
                </Typography>
            </Box>

        </Box>

    )

}

export default Footer;