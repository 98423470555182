import React, {useState, useEffect, useContext} from "react";
import Navbar from "./Navbar";
import Footer from "../Footer";
import { Outlet, useNavigate} from "react-router-dom";
import Formulario from "../Formulario";
import Navbar2 from "./Navbar2";
import BootstrapNavbar from "./ResponsiveNavbar";
import MuiNavbar from "./MuiNavbar";
import Formulario2 from "./Formulario2";
import Footer2 from "../Footer2";
import { LandingContext } from "../context/LandingContext";

import WhatsappButton from "./WhatsappButton";

const Layout = () => {


    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const show = window?.scrollY > 100
            if (show) {
                setScrolling(true)
            } else {
                setScrolling(false)
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <>
            {/* <Navbar /> */}
            {/* <BootstrapNavbar /> */}
            {/* <Navbar2 /> */}
            <MuiNavbar />
            <WhatsappButton />
            <Outlet />
            {/* {scrolling && <Box

onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}
sx={{
    width: '48px',
    height: '48px',
    padding: '12px',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '24px',
    backgroundImage: `url(${GoBackPasive})`,
    position: 'fixed',
    top: 810,
    right: {
        xs: 300,
        md: 100
    },
    backgroundColor: "#FFF",

    ':hover': {
        cursor: 'pointer',
        backgroundImage: `url(${GoBackActive})`,

    }
}}

>

</Box>} */}
            {/* <Formulario /> */}
            <Formulario2 />
            {/* <Footer /> */}
            <Footer2 />
        </>
    );
}

export default Layout