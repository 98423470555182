import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

const uData = [4000, 3000, 2000, 2780, 1890, 2390];
const pData = [2400, 1398, 9800, 3908, 4800, 3800 ];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F'
];

export default function SimpleLineChart() {
  return (
    <LineChart
      
      // width={window.inn}
      height={300}
      series={[
        { data: pData, label: 'Por Pagar' },
        { data: uData, label: 'Pagados' },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      sx={{
        // width: {xs: '100%',  sm:'100%'},
        // height: {xs: '360px', sm:'100%'},
        backgroundColor: {xs: '', sm: ''}
      }}
    />
  );
}