import { Box, Grid, Typography, Button, FormControl, TextField, Checkbox, FormControlLabel, Paper } from "@mui/material";
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import axios from "axios";

import SelectInput from "@mui/material/Select/SelectInput";
import DropdownMenu from "./DropdownMenu";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Formulario2 = () => {


    const [nombre, setNombre] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [showError, setShowError] = useState(false)


    const [tipoNegocio, setTipoNegocio] = useState('Tipo de Negocio');

    const negocioOpciones = ['Restaurante', 'Hotel', 'Cafeteria', 'Pasteleria', 'Panaderia', 'Catering', 'Club', 'Restobar', 'Minimarket', 'Otro'];

    const [redSocial, setRedSocial] = useState('¿Cómo supiste de nosotros?');

    const redes = ['Facebook', 'Google', 'LinkedIn', 'Instagram', 'TV', 'Radio', 'Recomendaciones']



    const handleSubmit = async () => {
        if ([nombre, empresa, email, telefono, descripcion].includes('') || tipoNegocio === 'Tipo de Negocio' || redSocial === '¿Cómo supiste de nosotros?') {

            setShowError(true)
            return;
        }
        else {
            const formulario = {
                "nombre": `${nombre}`, 
                "correo": `${email}`, 
                "telefono": `${telefono}`, 
                "empresa": `${empresa}`, 
                "referencia": `${redSocial}`, 
                "descripcion": `${descripcion}`
            }
            console.log(formulario);
            setShowError(false);
            try {

                var requestOptions = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        formulario
                    )
                  };
                
    
                const response = await fetch('https://localhost:7299/api/WebInfomatica/send-email', requestOptions)
                console.log(response);
            } catch (error) {
                console.error(error);
            }
            
            return;
            // Enviar el correo

        }


    }

    const Error = () => {
        return (
            <Typography sx={{
                color: '#FF655A',
                fontFamily: 'Poppins',
                fontSize: '12px'
            }}>
                No olvides completar este campo
            </Typography>)
    }


    return (
        <Box sx={{ width: '100%', height: '902px', backgroundColor: 'transparent', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingY: '60px', marginBottom: { xs: '140px', md: '0px' } }}>
            <Box sx={{ width: { xs: '100%', md: '1200px' }, height: "880px", display: 'flex', alignItems: 'center', borderColor: '#000', borderWidth: '10px', flexDirection: 'column' }}>
                <Typography color={'#4884E2'} sx={{ fontSize: { xs: '24px', md: '36px' }, fontWeight: '500px', fontFamily: 'Gotham', marginBottom: { xs: '0px', md: '40px' }, textAlign: 'center' }}>¡Contáctanos y te daremos la mejor solución!</Typography>

                <FormControl sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '60px', marginTop: '40px', width: { xs: '90%', md: 'auto' }, height: { xs: 'auto', md: 'auto' }, boxShadow: ' 0px 4px 24px 0px rgba(25, 67, 127, 0.10)', borderRadius: '20px', padding: '40px', flexDirection: 'column', backgroundColor: 'white' }}>

                    {/* Inputs */}
                    {/* <Box sx={{ marginBottom: '40px' }}>

                        <Box gap={'20px'} marginBottom={'20px'} sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', md:'row'},
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <TextField

                                sx={{ width: {xs: '296px', md:'320px'}, boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Nombre y apellido"

                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight',
                                        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',
                                       
                                    }
                                }}

                            />
                            <TextField sx={{ width: {xs: '296px', md:'420px'}, boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Nombre de tu negocio"
                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight'
                                    }
                                }} />
                            <TextField sx={{  width: {xs: '296px', md:'240px'}, boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Tipo de negocio"
                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight'
                                    }
                                }}
                            />
                        </Box>
                        <Box gap={'20px'} marginBottom={'40px'}
                        
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', md:'row'},
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        
                        >
                            <TextField sx={{ width: {xs: '296px', md:'440px'}, boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Correo electronico"
                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight'
                                    }
                                }}
                            />
                            <TextField sx={{ width: {xs: '296px', md:'240px'}, boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Telefono"
                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight'
                                    }
                                }}
                            />
                            <TextField sx={{ width: '300px', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="¿Cómo supiste de nosotros?"
                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight'
                                    }
                                }}
                            />
                        </Box>

                        <Box>
                            <Typography component={"h3"} sx={{ fontFamily: 'PoppinsLight', fontSize: '20px', letterSpacing: '1px', color: '#88919E', marginBottom: '12px' }}>¿Qué temas te interesan?</Typography>
                            <Box flexDirection={'row'} gap={"20px"} sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex'
                                }
                            }}>
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>INFORest</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>INFOHotel</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>INFOBack</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>INFOFact</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>AMC</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>Autoservicio</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>Equipos</Typography>} />
                            </Box>

                            <TextField sx={{ marginTop: '28px', width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Cuentanos en que podemos ayudarte"
                                multiline
                                rows={4}
                                maxRows={4} 
                                
                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight'
                                    }
                                }}
                                
                                />
                        </Box>

                    </Box> */}



                    {/* <Button
                        sx={{
                            width: '320px',
                            height: '56px',
                            backgroundColor: '#42C67E',
                            borderRadius: '30px',
                            boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                            color: '#FFF',
                            fontFamily: 'PoppinsLight',
                            textTransform: 'uppercase'
                            ,
                            ":hover": {

                                backgroundColor: '#79DD8B'

                            }
                        }}

                    >
                        ¡estemos en contacto!
                    </Button> */}
                    <Grid container columnSpacing={2} rowSpacing={3} >
                        <Grid item xs={12} md={3}>
                            <TextField
                                // variant="filled"
                                label="Nombre y apellido"
                                InputLabelProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight',
                                    }
                                }}
                                sx={{ width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }}

                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight',
                                        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',

                                    }
                                }}

                                onChange={(e) => setNombre(e.target.value)}
                                value={nombre}
                            />


                            {(showError && nombre === '') && <Typography sx={{
                                color: '#FF655A',
                                fontFamily: 'Poppins'
                            }}>No olvides completar este campo</Typography>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField

                                sx={{ width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Nombre de tu negocio"


                                value={empresa}
                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight',
                                        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',

                                    }
                                }}

                                onChange={(e) => setEmpresa(e.target.value)}

                            />


                            {(showError && empresa === '') && <Error />}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {/* <TextField

                                sx={{ width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Tipo de negocio"

                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight',
                                        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',

                                    }
                                }}

                            /> */}
                            <DropdownMenu valorDefault={tipoNegocio} setValorDefault={setTipoNegocio} opciones={negocioOpciones} />

                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField

                                sx={{ width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Correo electronico"

                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight',
                                        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',

                                    }
                                }}

                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />

                            {(showError && email === '') && <Error />}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField

                                sx={{ width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Telefono"

                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight',
                                        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',

                                    }
                                }}


                                onChange={(e) => setTelefono(e.target.value)}
                                value={telefono}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {/* <TextField

                                sx={{ width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="¿Cómo supiste de nosotros?"

                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight',
                                        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',

                                    }
                                }}

                            /> */}
                            <DropdownMenu valorDefault={redSocial} setValorDefault={setRedSocial} opciones={redes} />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{
                            display: {
                                xs: 'none',
                                lg: 'block'
                            }
                        }}>
                            <Typography component={"h3"} sx={{ fontFamily: 'PoppinsLight', fontSize: '20px', letterSpacing: '1px', color: '#88919E', marginBottom: '12px' }}>¿Qué temas te interesan?</Typography>
                            <Box flexDirection={'row'} gap={"20px"} sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex'
                                }
                            }}>
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>INFORest</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>INFOHotel</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>INFOBack</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>INFOFact</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>AMC</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>Autoservicio</Typography>} />
                                <FormControlLabel control={<Checkbox sx={{ color: '#4884E2' }} />} label={<Typography sx={{ fontFamily: 'PoppinsLight', color: '#1E2947', fontSize: '14px' }}>Equipos</Typography>} />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField sx={{ width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Cuentanos en que podemos ayudarte"
                                multiline
                                rows={4}
                                maxRows={4}

                                inputProps={{
                                    sx: {
                                        fontFamily: 'PoppinsLight'
                                    }
                                }}

                                onChange={(e) => setDescripcion(e.target.value)}

                            />

                            {(showError && descripcion === '') && <Error />}
                        </Grid>

                    </Grid>

                    <Button
                        sx={{
                            width: '320px',
                            height: '56px',
                            backgroundColor: '#42C67E',
                            borderRadius: '30px',
                            boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                            color: '#FFF',
                            fontFamily: 'PoppinsLight',
                            textTransform: 'uppercase'
                            ,
                            ":hover": {

                                backgroundColor: '#79DD8B'

                            },
                            marginTop: '40px'
                        }}

                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        ¡estemos en contacto!
                    </Button>


                </FormControl>



            </Box>

        </Box>
    )

};

export default Formulario2;