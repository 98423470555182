import {  useState, createContext } from 'react';

const SidebarContext = createContext();

const SidebarProvider = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [selectedIndexMenu, setSelectedIndexMenu] = useState(+localStorage.getItem("selectedIndexMenu"));
  const [cantPage, setCantPage] = useState(15);
  const [numfilas, setNumFilas] = useState(15);
  const [moneda, setMoneda] = useState('PEN-SOL');

  const idSelectedIndexMenu = (idSelectedIndexMenu) => {
    localStorage.setItem("selectedIndexMenu", idSelectedIndexMenu.toString());    
    setSelectedIndexMenu(idSelectedIndexMenu);
  }


  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ 
        sidebarToggle, 
        toggleSidebar, 
        closeSidebar, 
        selectedIndexMenu, 
        idSelectedIndexMenu, 
        cantPage, 
        setCantPage, 
        numfilas, 
        setNumFilas,
        moneda, 
        setMoneda 
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export {SidebarProvider, SidebarContext};
