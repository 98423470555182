import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import Solucion from "./Shared/Solucion";
import CartaResumen from "./Shared/CartaResumen";
import BgExperiencia from '../assets/experiencia.jpg'
import Grupo from '../assets/Grupo.jpg'
import ProductosBg from '../assets/post.jpg'
import Capacitacion from '../assets/capacitacion.jpg'
import Barranco from '../assets/barranco.jpg'
import BgNosotros from '../assets/FONDONOSOTROS.webp'



import '../styles/Nosotros.css';

const Resumen = () => {

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', backgroundColor: ''}}>
            <Box sx={{ width: '100%', height: "auto", display: 'flex', alignItems: 'center', borderColor: '#000', borderWidth: '10px', flexDirection: 'column', 
            // backgroundImage: `url(${BgNosotros})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' 
            }} className='nosotrosBg'>
                <Typography color={'#4884E2'} sx={{ fontSize: '36px', fontWeight: '500px', fontFamily: 'Gotham' }}>Conoce un poco más sobre nosotros</Typography>
                <Grid container sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: '60px', marginTop: '40px' }} spacing={'20px'}>

                    <Grid item borderRadius={'60px 20px 20px 20px'}>
                        <CartaResumen titulo={'Nuestra experiencia'} texto={'Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?'} textoBtn={'Una larga trayectoria'} imageUrl={BgExperiencia} />
                    </Grid>

                    <Grid item borderRadius={'60px 20px 20px 20px'}>
                        <CartaResumen titulo={'Nuestro equipo'} texto={'Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?'} textoBtn={'quienes somos'} imageUrl={Grupo} />
                    </Grid>
                    <Grid item borderRadius={'60px 20px 20px 20px'}>
                        <CartaResumen titulo={'Nuestros Proyectos'} texto={'Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?'} textoBtn={'lo que logramos'} imageUrl={ProductosBg} />
                    </Grid>
                    <Grid item borderRadius={'60px 20px 20px 20px'}>
                        <CartaResumen titulo={'Oportunidades laborales'} texto={'Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?'} textoBtn={'unete a la familia'} imageUrl={Capacitacion} />
                    </Grid>
                    <Grid item borderRadius={'60px 20px 20px 20px'} sx={{
                        display: {
                            md: 'none'
                        }
                    }}>
                        <CartaResumen titulo={'Capacitaciones certificadas'} texto={'Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?'} textoBtn={'aprende con nosotros'} imageUrl={Barranco} />
                    </Grid>

                </Grid>

                <Box
                    sx={{
                        width: '1260px',
                        height: '176px',
                        borderRadius: '60px 20px 20px 20px',
                        boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                        backgroundColor: '#FFF',
                        display: { xs: 'none', sm: 'none', md: 'flex'},
                        flexDirection: 'row',
                        justifyContent: 'space-between',

                        ':hover': {

                            '.bgCapacitacion': {
                                boxShadow: "none",
                            },
                            '.textCapacitacion': {
                                display: "none"
                            }
                        }
                    }}
                >
                    <Box

                        
                        className='bgCapacitacion'


                        sx={{
                            backgroundImage: `url(${Barranco})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            width: '460px',
                            height: '176px',
                            borderRadius: '60px 0px 0px 20px',
                            boxShadow: 'inset 0 0 0 1000px rgba(30, 41, 71, 0.4)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            transition: 'box-shadow 0.5s',
                        }}
                    >

                        <Typography

                        className="textCapacitacion"
                        
                        sx={{
                            color: 'white',
                            fontFamily: 'GothamLight',
                            fontSize: '24px'

                        }}
                        
                        >Capacitaciones certificadas</Typography>
                    </Box>

                    {/* <Box width={"760px"} display={"flex"} flexDirection={"column"} sx={{
                        
                    }}
                    >
                        <Box sx={{
                            paddingTop: '40px',
                            paddingX: '20px',
                        }}>

                            <Typography component={'p'}

                                sx={{
                                    color: '#1E2947',
                                    fontFamily: 'PoppinsLight',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    marginBottom: '40px',
                                    marginTop: '40px'
                                }}

                            >
                                Matricúlate en nuestras capacitaciones de INFOhotel® e INFOrest® con nuestros profesores certificados en nuestros sistemas y equipos innovadores, para que aprendas como si estuvieras en situaciones reales.
                            </Typography>
                        </Box>

                        <Box display={'flex'} justifyContent={'flex-end'} sx={{ width: '100%' }}>

                            <Button variant="contained"
                                sx={{
                                    backgroundColor: '#4884E2', borderRadius: '20px 0px', padding: '12px 40px', width: '260px', height: '48px', textTransform: 'uppercase',
                                    ":hover": {
                                        backgroundColor: '#69C4DE'
                                    },
                                    fontSize: '14px',
                                    paddingX: '24px',
                                    paddingY: '12px',
                                    fontFamily: 'PoppinsLight'
                                }}>aprende con nosotros</Button>

                        </Box>
                    </Box> */}

                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

                        <Box sx={{ width: '760px' }}>
                            <Typography component={'p'}

                                sx={{
                                    color: '#1E2947',
                                    fontFamily: 'PoppinsLight',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    marginTop: '40px',
                                    width: '100%',
                                    height: '48px'
                                }}

                            >
                                Matricúlate en nuestras capacitaciones de <Typography component={'span'} sx={{fontFamily: 'PoppinsSemiBold', color: '#1E2947'}}>INFOhotel®</Typography> e <Typography component={'span'} sx={{fontFamily: 'PoppinsSemiBold', color: '#1E2947'}}>INFOrest®</Typography> con nuestros profesores certificados en nuestros sistemas y equipos innovadores, para que aprendas como si estuvieras en situaciones reales.
                            </Typography>
                        </Box>

                        

                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#4884E2', borderRadius: '20px 0px', padding: '12px 40px', width: '260px', height: '48px', textTransform: 'uppercase',
                                ":hover": {
                                    backgroundColor: '#69C4DE'
                                },
                                fontSize: '14px',
                                paddingX: '24px',
                                paddingY: '12px',
                                fontFamily: 'PoppinsLight',
                                alignSelf: 'flex-end'
                            }}>aprende con nosotros</Button>

                    
                    </Box>

                </Box>
            </Box>
            </Box>

    )

};

export default Resumen;