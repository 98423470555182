import React, {useEffect} from "react";
import {
    Box, Typography
} from "@mui/material";

import Logo from '../assets/INFOREST.svg'
import DescripcionSolucion from "../components/DescripcionSolucion";

import InforestImg from '../assets/clienteInforest.jpg'
import ModulesComplements from "../components/ModulesComplements";

import Grid from "@mui/material";
import Modulo from "../components/Modulo";

import MobileInforest from '../assets/INFORESTMOB.webp'
import Agregadores from '../assets/CONSOLA.webp'
import KDS from '../assets/KDS_TV.webp'
import CartaDigital from '../assets/CARTADIGITAL.webp'
import Patio from '../assets/PATIO.webp'




const inforestDesc = {
    description: 'Software modular que permite automatizar la gestión y operación de negocios gastronómicos y afines.',
    items: [
        'Optimiza los tiempos de producción',
        'Genera reportes y estadísticas.',
        'Gestiona tus ventas.',
        'Unifica los procesos.',
        'Servicio técnico 24/7',
    ],
    image: InforestImg
}

const inforestComp = {
    modulos: [
        {
            title: 'Administración y Reglas de Negocio:',
            desc: 'Organiza y gestiona parámetros que regirán los procesos de INFOrest punto de venta y adición.'
        },
        {
            title: 'Adición:',
            desc: 'Toma de nota de los pedidos solicitados por los clientes, permitiendo añadir inclusiones y exclusiones a las recetas estándar.'
        },
        {
            title: 'Punto de Venta – Tradicional:',
            desc: 'Gestiona ventas, registra comprobantes y diferentes formas de cobranza.'
        },
        
        {
            title: 'Consultas y Reportes:',
            desc: 'Revisa tablas de pedidos, documentos, informes y reportes que brinda el sistema agrupado.'
        },
        {
            title: 'Punto de Venta – Caja Rápida:',
            desc: 'Dirigido a negocios que deben resolver rápido el proceso de venta, facturación y pago.'
        },
    ],
    complementos: [{
        title: 'Chef Control:',
        desc: 'Dirigido al área de cocina y producción. Controla la llegada de los platos, compara el tiempo de preparación y genera reportes de eficiencia en cocina.'
    },
    {
        title: 'Anfitriona:',
        desc: 'Desarrollado para el apoyo de espera de clientes en puertas y reservas. Podrá visualizar los diferentes estados de las mesas.'
    },
    {
        title: 'Motor Contable: ',
        desc: 'Servicio de información de carácter contable. Permite actualizar las ventas, compras, cobranzas e inventario.'
    },
    {
        title: 'Central delivery:',
        desc: 'Dirigido a restaurantes que centralizan sus pedidos de delivery, similar al servicio Call Center.'
    },
    {
        title: 'Administración Centralizada y Consultas Integradas:',
        desc: 'Unifica los parámetros de gestión de todos tus locales. Genera consultas y reportes a nivel de cadena.'
    },]
}



const inforestModulos = [
    {
        description: 'Mobile INFOrest',
        items: [
            'Tus meseros enviarán los pedidos mientras se comunican con el cliente.',
            'Funciona con Wi-Fi.',
            'Cuenta con todas las funciones del módulo de escritorio.',
            'Utiliza cualquier explorador web.',
        ],
        image: MobileInforest,
        imgWidth: '570px',
        imgHeight: '570px'
    },
    {
        description: 'Consola de Agregadores',
        items: [
            'Integra todos los pedidos de las app de delivery en una sola plataforma ( rappi, justo y pedidos ya)',
            'Olvídate de los errores de digitación.',
            'Ahorra tiempo.',
        ],
        image: Agregadores,
        imgWidth: '570px',
        imgHeight: '373px'
    },
    {
        description: 'KDS (Kitchen Display System)',
        items: [
            'Auto atención de pedidos.',
            'Realizar los pagos de sus pedidos.',
            'Optimiza sus tiempos y ventas.',
        ],
        image: KDS,
        imgWidth: '570px',
        imgHeight: '432px'
    },
    {
        description: 'Carta Digital',
        items: [
            'Realiza y paga los pedidos en la plataforma desde tu mesa.',
            'Reduce el tiempo de la atención.',
            'Permite dejar propina a los mozos al pagar el pedido.',
            'Disminuye la sobrecarga laboral de tus colaboradores.',
        ],
        image: CartaDigital,
        imgWidth: '570px',
        imgHeight: '460px'
    },
    {
        description: 'Patio de Comidas',
        items: [
            'E-Commerce.',
            'Gestiona tus ventas en internet y redes sociales.',
            'Seguimiento de pedidos propios o de recojo en tienda.'
        ],
        image: Patio,
        imgWidth: '570px',
        imgHeight: '373px'
    },
]

const Inforest = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, []);


    return (
        <Box

            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}
        >

            <Box
                sx={{
                    width: '100vw',
                    height: '80px',
                    backgroundColor: '#6CD39B',
                    display: 'flex',
                    alignItems: 'center',
            
                }}
            >
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}

            >
                <img width={'300px'} height={'200px'} src={Logo}></img>
            </Box>

            <DescripcionSolucion info={inforestDesc} />
            <ModulesComplements info={inforestComp} colorPrimary={'#42C67E'} />

            <Box
                sx={{
                    width: '90%',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginTop: '60px',
                }}
            >

                <Typography component={'h3'} sx={{
                    color: '#42C67E',
                    fontFamily: 'PoppinsSemiBold',
                    fontSize: {xs: '32px',  md:'38px'}
                }}>Módulos opcionales de INFOrest</Typography>

            </Box>


            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >

            {inforestModulos.map((item, index) => 
            (
                <Modulo infomodule={item} key={index}/>
            ))}


            </Box>


        </Box>
    )
}

export default Inforest;