import React, { cloneElement, useState, useEffect, useRef } from "react";
import PasivoLogo from '../../assets/INFOMATICA-PASIVO.svg'
import HoverLogo from '../../assets/hoverlogo.svg'
import PasivoScroll from '../../assets/scrollPasivo.svg';
import HoverScroll from '../../assets/scrollHover.svg';
import CarritoPasivoNoScroll from '../../assets/CARRITO-PASIVO-BLANCO.svg'
import CarritoPasivo from '../../assets/CARRITOPASIVOAZUL.svg'
import CarritoHover from '../../assets/CARRITO-ACTIVO.svg'
import UsuarioPasivo from '../../assets/USUARIO_PASIVO.svg'
import UsuarioHover from '../../assets/USUARIO_HOVER.svg'

import '../../styles/NavbarStyles.css'
import { Link } from "react-router-dom";
import { AppBar, Stack, useScrollTrigger, Box, List, ListItem, createTheme, Container } from "@mui/material";
import { Theme } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";


const appBarSticky = {
    backgroundColor: '#1877F2',
    boxShadow: 'none',
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
   // width: '100%',
    height: '80px',
    justifyContent: {
        xs: 'space-between',
        md: 'space-evenly'
    },

}

const appBarScroll = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: 'none',
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    borderRadius: '0px 0px 20px 20px',
    boxShadow: '0px 4px 20px 0px rgba(25, 67, 127, 0.12)',
    width: '100%',
    height: '80px',
    justifyContent: {
        xs: 'space-between',
        md: 'space-evenly'
    },

}


// const theme = createTheme({
//     breakpoints: {
//       values: {
//         mobile: 0,
//         tablet: 640,
//         laptop: 1024,
//         desktop: 1200,
//       },
//     },
//   });

//   const styles = (theme) => ({
//     root: {
//       backgroundColor: 'blue',
//       // Match [md, ∞)
//       //       [900px, ∞)
//       [theme.breakpoints.up('mobile')]: {
//         backgroundColor: 'red',
//       },
//     },
//   });

// const navbar = styles(theme)



const logoNoScroll = {
    height: { xs: '30px', md: '63px' },
    width: { xs: '100px', md: '180px' },
    backgroundImage: `url(${PasivoLogo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${HoverLogo})`
    },
}


const logoOnScroll = {
    height: '42px',
    width: '120px',
    backgroundImage: `url(${PasivoScroll})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${HoverScroll})`
    },
}
const carritoNoScroll = {
    height: '44px',
    width: '44px',
    borderRadius: '24px',
    backgroundImage: `url(${CarritoPasivoNoScroll})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${CarritoHover})`,
        cursor: 'pointer'
    },
}


const carritoOnScroll = {
    height: '44px',
    width: '44px',
    borderRadius: '24px',
    backgroundImage: `url(${CarritoPasivo})`,
    background: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${CarritoHover})`,
        cursor: 'pointer'
    },
}


const Navbar = () => {

    const navigate = useNavigate()

    const [navBackground, setNavBackground] = useState(appBarSticky)
    const [linkFonts, setLinkFonts] = useState('links')

    const [logo, setLogo] = useState(logoNoScroll);
    const [carrito, setCarrito] = useState(carritoNoScroll);



    const changeBackground = () => {
        console.log(window.scrollY);
    }

    const navRef = useRef()
    const linksRef = useRef()
    const bgRef = useRef()
    const carritoRef = useRef()


    navRef.current = navBackground
    linksRef.current = linkFonts
    bgRef.current = logo;

    carritoRef.current = carrito;

    window.addEventListener('scroll', changeBackground)


    useEffect(() => {
        const handleScroll = () => {
            const show = window?.scrollY > 200
            if (show) {
                setNavBackground(appBarScroll)
                setLinkFonts('linksDark')
                setLogo(logoOnScroll);
                setCarrito(carritoOnScroll);
            } else {
                setNavBackground(appBarSticky)
                setLinkFonts('links')
                setLogo(logoNoScroll);
                setCarrito(carritoNoScroll)
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => document.removeEventListener('scroll', handleScroll)
        
    })




    return (


        <AppBar
            sx={[navRef.current]}
            position="sticky"
        >

            {/* <Box sx={{display: 'flex', flexDirection:'row', justifyContent:"space-between"}}> */}
            <Box className="imgLogo"
                sx={[bgRef.current]} onClick={() => navigate("/")}></Box>


            <Box className="navbarRoutes" sx={{
                display: {
                    xs: 'none',
                    md: 'flex'
                }
            }}>
                <Link className={`${linksRef.current}`} to="nosotros" >Nosotros</Link>
                <Link className={`${linksRef.current}`} to="productos">Productos</Link>
                <Link className={`${linksRef.current}`}>Soluciones</Link>
                <Link className={`${linksRef.current}`} to="soporte">Soporte</Link>
                <Link className={`${linksRef.current}`}>Blog</Link>
                <Link className={`${linksRef.current}`}>Iniciar Sesion</Link>
                <Box sx={carritoRef.current}></Box>
                <Box sx={{

                    height: '36px',
                    width: '36px',
                    backgroundImage: `url(${UsuarioPasivo})`,

                    ':hover': {
                        backgroundImage: `url(${UsuarioHover})`
                    }

                }}></Box>
            </Box>
            <Box className="navbarRoutes" sx={{
                display: {
                    xs: 'flex',
                    md: 'none'
                }
            }}>
                <Box sx={carritoRef.current}></Box>
                <Box sx={{

                    height: '36px',
                    width: '36px',
                    backgroundImage: `url(${UsuarioPasivo})`,

                    ':hover': {
                        backgroundImage: `url(${UsuarioHover})`
                    }

                }}></Box>

                <MenuIcon sx={{
                    color: 'black',
                    display: {
                        xs: 'flex',
                        sm: 'block',
                        md: 'none',
                        lg: 'none',
                        xl: 'none'
                    }
                }}
                />
            </Box>

            {/* </Box> */}
        </AppBar >

    )
}


{/* <Box className="navbarRoutes" sx={{
                display: {
                    xs: 'flex',
                    md: 'none'
                },
                flexDirection: 'column',
                marginTop: '80px',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                backgroundColor: 'red'
            }}>
                <List
                    sx={{
                        listStyleType: 'none',
                        listStylePosition: 'inside'
                    }}


                >
                    <ListItem sx={{ display: 'list-item' }}>
                        <Link className={`${linksRef.current}`} to="nosotros" >Nosotros</Link>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Link className={`${linksRef.current}`}>Proyectos</Link>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Link className={`${linksRef.current}`}>Soluciones</Link>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Link className={`${linksRef.current}`} to="soporte">Soporte</Link>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Link className={`${linksRef.current}`}>Blog</Link>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Link className={`${linksRef.current}`}>Iniciar Sesion</Link>
                    </ListItem>
                </List>

                 <Box sx={carritoRef.current}></Box>
                <Box sx={{

                    height: '36px',
                    width: '36px',
                    backgroundImage: `url(${UsuarioPasivo})`,

                    ':hover': {
                        backgroundImage: `url(${UsuarioHover})`
                    }

                }}></Box> 
            </Box> */}


export default Navbar;