import React, { useState } from "react";
import HeaderBackground from '../assets/headerBackground.webp'
import HeaderImage1 from '../assets/HeaderUsuario1.webp'
import { Box, Button, makeStyles } from "@mui/material";
import styled from "styled-components";
import Carousel from 'react-material-ui-carousel';
import '../styles/HeaderStyles.css'
import Navbar from "./Shared/Navbar";
import Logo1 from '../assets/restaurantelogo.svg'
import Logo2 from '../assets/hoteleslogo.svg'
import Logo3 from '../assets/cafeterialogo.svg'
import Logo4 from '../assets/bareslogo.svg'
import Logo5 from '../assets/pastelerialogo.svg'
import BoxClientes from "./BoxClientes";


const SpanIndustria = styled.span`
opacity: 0;
transition: 1s,
&.loaded {
    opacity: 1;
}
`


const Header = () => {


    const industrias = ['restaurantes', 'hoteles', 'cafeterias', 'bares', 'pastelerias', 'panaderias']
    const images = ['headerUsuario1.webp', 'headerUsuario2.webp', 'headerUsuario3.webp']

    const [indexIndustrias, setIndexIndustrias] = useState(0);
    const [industriaSelected, setIndustriaSelected] = useState(industrias[0]);

    const selectNewIndustria = (index, industrias, next = true) => {
        const condition = next ? indexIndustrias < industrias.length - 1 : indexIndustrias > 0;
        const nextIndex = next ? condition ? indexIndustrias + 1 : 0 : condition ? indexIndustrias - 1 : industrias.length - 1
        setIndustriaSelected(industrias[nextIndex]);
        setIndexIndustrias(nextIndex);
    }

    const previousIndustria = () => {
        selectNewIndustria(indexIndustrias, industrias, false);
    }

    const nextIndustria = () => {
        selectNewIndustria(indexIndustrias, industrias);
    }



    return (
        <>
            <div className="headerContent" style={{ backgroundImage: `url(${HeaderBackground})` }}>
                <Navbar />
                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <div className="description">
                        <div>
                            <h1>Soluciones tecnologicas para
                                <span>{" "}{industriaSelected}</span>
                                {/* <button onClick={() => previousIndustria()}>{"<"}</button>
                                <button onClick={() => nextIndustria()}>{">"}</button> */}
                            </h1>

                            <p>Somos pioneros con 20 años de experiencia en el desarrollo e innovación de la industria HORECA. </p>

                            <div style={{ marginTop: '24px' }}>
                                <img src={Logo1} />
                                <img src={Logo2} />
                                <img src={Logo3} />
                                <img src={Logo4} />
                                <img src={Logo5} />
                            </div>

                            <div style={{ marginTop: '60px' }}>
                                <Button variant="contained"
                                    sx={{
                                        backgroundColor: '#42C67E', borderRadius: '30px', padding: '12px 40px', width: '300px', height: '56px', textTransform: 'uppercase',
                                        ":hover": {
                                            backgroundColor: '#76FF7A'

                                        }
                                    }}>Encuentra tu solucion</Button>
                            </div>
                        </div>
                    </div>
                    <div >
                        <img className="imgHeader" src={HeaderImage1} />
                    </div>


                </div>
                    
                    <BoxClientes />


            </div>

        </>
    )
}

export default Header