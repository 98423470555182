import { AppBar, Toolbar, Box, Hidden, Dialog, Drawer, SwipeableDrawer, Menu, MenuItem, Fade, backdropClasses, Collapse, Typography, Divider } from "@mui/material";




import React, { useState, useEffect, useRef, useContext } from "react";

import { useNavigate, Link } from "react-router-dom";

import { Menu as MenuIcon } from "@mui/icons-material";


import PasivoLogo from '../../../assets/INFOMATICA-PASIVO.svg'
import HoverLogo from '../../../assets/hoverlogo.svg'
import PasivoScroll from '../../../assets/scrollPasivo.svg';
import HoverScroll from '../../../assets/scrollHover.svg';
import CarritoPasivoNoScroll from '../../../assets/CARRITO-PASIVO-BLANCO.svg'
import CarritoPasivo from '../../../assets/CARRITO-PASIVO-AZUL.svg'
import CarritoHover from '../../../assets/CARRITO-ACTIVO.svg'
import UsuarioPasivo from '../../../assets/USUARIO_PASIVO.svg'
import UsuarioHover from '../../../assets/USUARIO_HOVER.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { red } from "@mui/material/colors";
import ResponsiveDialog from "../../Shared/DialogScreen";
import { LandingContext } from "../LandingContext";
// import CerrarSesion from '../../../assets/CERRAR SESION-PASIVO_24X24.svg'

import { SidebarContext } from '../SidebarContext';




import homePasivo from '../../../assets/intranet/INICIO-PASIVO_68X56.svg';
import homeActivo from '../../../assets/intranet/INICIO-ACTIVO_68X56.svg';
import homeHover from '../../../assets/intranet/INICIO-HOVER_68X56.svg';

import pagoActivo from '../../../assets/intranet/PAGOS-ACTIVO_68X56.svg';
import pagoPasivo from '../../../assets/intranet/PAGOS-PASIVO_68X56.svg';
import pagoHover from '../../../assets/intranet/PAGOS-HOVER_68X56.svg';

import sbOpen from '../../../assets/intranet/SIDEBAR-ABRIR_52X48.svg';
import sbClose from '../../../assets/intranet/SIDEBAR-CERRAR_52X48.svg';



const appBarSticky = {
    // backgroundColor: '#1877F2',
    backgroundColor: '#FFF',
    boxShadow: 'none',
    display: { xs: 'none', sm: 'none', md: "flex" },
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
    paddingLeft: '0px',
    paddingRight: '0px',
    width: '68px',
    height: '100%',
    // justifyContent: {
    //     xs: 'space-between',
    //     md: 'space-evenly'
    // },
    zIndex: 9999
}


const appBarScroll = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    // backgroundColor: 'red',
    boxShadow: 'none',
    display: "flex",
    flexDirection: 'column',
    // alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    borderRadius: '0px 0px 8px 8px',
    boxShadow: '0px 4px 20px 0px rgba(25, 67, 127, 0.12)',
    width: '100%',
    height: '80px',
    justifyContent: {
        xs: 'space-between',
        md: 'space-evenly'
    },
}

const logoNoScroll = {
    height: { xs: '30px', md: '42px' },
    width: { xs: '100px', md: '120px' },
    backgroundImage: `url(${PasivoLogo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${HoverLogo})`
    },
}


const logoOnScroll = {
    height: '42px',
    width: '120px',
    backgroundImage: `url(${PasivoScroll})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${HoverScroll})`
    },
}
const carritoNoScroll = {
    height: '44px',
    width: '44px',
    borderRadius: '24px',
    backgroundImage: `url(${CarritoPasivoNoScroll})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${CarritoHover})`,
        cursor: 'pointer'
    },
}


const carritoOnScroll = {
    height: '44px',
    width: '44px',
    borderRadius: '24px',
    //backgroundColor: 'red',
    backgroundImage: `url(${CarritoPasivo})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${CarritoHover})`,
        cursor: 'pointer'
    },
}

function disableScroll() {
    // Store the current X & Y scroll positions.
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    // If any scroll event is attempted on the window, change the window's 
    // onscroll function to always scroll back to the saved X & Y positions.
    window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
    };
}
function enableScroll() {
    // Reset the window's onscroll function.
    window.onscroll = function () { };
}


const menuItems = [
    // {
    //   imgPasivo: inicioPasivo,
    //   imgHover: inicioHover,
    //   imgActivo: inicioActivo,
    //   descripcion: "Página de Inicio",
    //   rutaPrincipal: "/menu/comprobantes",
    //   opciones: [

    //   ]
    // },
    {
        imgPasivo: homePasivo,
        imgHover: homeHover,
        imgActivo: homeActivo,
        descripcion: "Pagina Principal",
        rutaPrincipal: "/intranet",
        opciones: [
            // { label: 'Submenú opción', link: '#' },
            // { label: 'Submenú opción', link: '#' },
        ]

    },
    {
        imgPasivo: pagoPasivo,
        imgHover: pagoHover,
        imgActivo: pagoActivo,
        descripcion: "Documentos de Pago",
        rutaPrincipal: "/intranet/pagos",
        opciones: [
        ]
    },

];

const Sidebar = () => {



    const navigate = useNavigate()

    const [navBackground, setNavBackground] = useState(appBarSticky)
    const [linkFonts, setLinkFonts] = useState('links')


    const { sidebarToggle, toggleSidebar, selectedIndexMenu, idSelectedIndexMenu } = useContext(SidebarContext);


    const handleListItemClick = (index) => {
        idSelectedIndexMenu(index)
    };



    const [logo, setLogo] = useState(logoNoScroll);
    const [carrito, setCarrito] = useState(carritoNoScroll);
    const [arrowUp, setArrowUp] = useState(false);
    const [arrowColor, setArrowColor] = useState({ color: 'white' })

    const [loggedIn, setLoggedIn] = useState(false)

    const changeBackground = () => {
        console.log(window.scrollY);
    }

    const navRef = useRef()
    const linksRef = useRef()
    const bgRef = useRef()
    const carritoRef = useRef()
    const arrowRef = useRef()


    navRef.current = navBackground
    linksRef.current = linkFonts
    bgRef.current = logo;
    arrowRef.current = arrowColor;

    carritoRef.current = carrito;

    window.addEventListener('scroll', changeBackground)


    useEffect(() => {
        const handleScroll = () => {
            const show = window?.scrollY > 60
            if (show) {
                setNavBackground(appBarScroll)
                setLinkFonts('linksDark')
                setLogo(logoOnScroll);
                setCarrito(carritoOnScroll);
                setArrowColor({ color: 'black' })
            } else {
                setNavBackground(appBarSticky)
                setLinkFonts('links')
                setLogo(logoNoScroll);
                setCarrito(carritoNoScroll);
                setArrowColor({ color: 'white' })
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => document.removeEventListener('scroll', handleScroll)

    })

    const [open, setOpen] = useState(false)

    const onOpenHandler = () => {
        setOpen(true);
    }

    const onCloseHandler = () => {
        setOpen(false);
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setArrowUp(true)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };




    useEffect(() => {
        const handleWindowWheel = (event) => {
            if (open) {
                event.preventDefault();
            }
        };

        window.addEventListener('wheel', handleWindowWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWindowWheel);
        };
    }, [open]);

    const [openDialog, setOpenDialog] = useState(false);

    const { auth, setAuth } = useContext(LandingContext);


    const [showMenu, setShowMenu] = useState(false);
  

    return (
        <AppBar component={'nav'} position="fixed" sx={[appBarSticky, { boxShadow: '4px 0px 10px 0px rgba(25, 67, 127, 0.12)', top: 0, left: 0, paddingTop: '40px' }]}>
            <Toolbar sx={{
                width: '68px',
                display: 'flex',
                backgroundColor: '',
                flexDirection: 'column',
                gap: '40px'

            }}>


                {menuItems.map((item, index) =>
                    (<Box
                        sx={{

                            width: '68px',
                            height: '56px',

                            
                        }}



                        onClick={() => {handleListItemClick(index), setShowMenu(false)}}
                        onMouseEnter={() => {handleListItemClick(index); setShowMenu(true)}}
                        onMouseLeave={() => setShowMenu(false)}
                    >

                        <Box

                            sx={{

                                display:((selectedIndexMenu === index && showMenu)? 'none': 'block' ),
                                backgroundImage: `url(${(selectedIndexMenu === index ? item?.imgActivo : item?.imgPasivo)})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                width: '68px',
                                height: '56px',
                            }}

                            className='icono'
                        >
                        </Box>
                        <Box

                            sx={{
                                display:((selectedIndexMenu === index && showMenu)? 'flex': 'none' ),
                                backgroundColor: '#E7EFFC',
                                width: 'max-content',
                                height: '56px',
                                boxShadow: '0px 4px 14px 0px rgba(25, 67, 127, 0.16)',
                                ':hover': {
                                    cursor: 'pointer'
                                }
                            }}

                            onClick={() => navigate(item?.rutaPrincipal)}

                            className='hoverItem'
                        >

                            <img src={item?.imgHover} width={'68px'} height={'56px'}
                                style={{
                                  
                                    backgroundColor: ''
                                }}
                            />
                            <Typography
                                sx={{
                                    color: '#2664C7',
                                    fontFamily: 'Poppins',
                                    width: 'auto',
                                    height: 'auto',
                                    fontSize: '16px',
                                    marginY: '16px',
                                    marginX: '20px',
                                    backgroundColor: ''
                                }}

                            >{item?.descripcion}</Typography>
                        </Box>
                    </Box>
                    
                    
                    ))
                    
                    }


                {/* {menuItems.map((item, index) => (

                        

                    ))} */}

                {/* </Box> */}

            </Toolbar>
        </AppBar>
    )
}

export default Sidebar

