import React from "react";
import { Box, Button, Typography } from "@mui/material";
import NosotrosBg from '../../assets/solucion1.jpg'
import '../../styles/Soluciones.css'
import LogoInforest from '../../assets/INFOREST.svg'


const Solucion = ({btnColorPasivo, btnColorHover, logo}) => {
    return (
        <Box className='solucionBg'
            sx={
                {
 
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    borderRadius: '60px 20px 20px 20px',
                    
                }}>
            <Box className="solucionImage" sx={{borderRadius: '60px 20px 20px 20px', width: '100%', height: '260px', backgroundImage: `url(${NosotrosBg})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <img src={logo} />
            </Box>
            <Typography color={'#1E2947'} sx={{ paddingY: '20px', paddingX: '16px', fontFamily: 'PoppinsLight' }}>¡Todo lo que necesitas para que tu restaurante sea exitoso en un solo lugar!</Typography>

            <Box display={'flex'} justifyContent={'flex-end'} sx={{ width: '100%' }}>

                <Button variant="contained"
                    sx={{
                        backgroundColor: `${btnColorPasivo}`, borderRadius: '20px 0px', padding: '12px 40px', width: '160px', height: '48px', textTransform: 'uppercase',
                        ":hover": {
                            backgroundColor: `${btnColorHover}`

                        },
                        fontSize: '14px',
                        paddingX: '24px',
                        paddingY: '12px',
                        fontFamily: 'PoppinsLight'
                    }}>¡me interesa!</Button>

            </Box>



        </Box>
    )
}

export default Solucion;