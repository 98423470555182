import { AppBar, Toolbar, Box, Hidden, Dialog, Drawer, SwipeableDrawer, Menu, MenuItem, Fade, backdropClasses, Collapse, Typography, Divider } from "@mui/material";

import React, { useState, useEffect, useRef, useContext } from "react";

import { useNavigate, Link } from "react-router-dom";

import { Menu as MenuIcon } from "@mui/icons-material";

import { SidebarContext } from "../context/SidebarContext";

import PasivoLogo from '../../assets/INFOMATICA-PASIVO.svg'
import HoverLogo from '../../assets/hoverlogo.svg'
import PasivoScroll from '../../assets/scrollPasivo.svg';
import HoverScroll from '../../assets/scrollHover.svg';
import CarritoPasivoNoScroll from '../../assets/CARRITO-PASIVO-BLANCO.svg'
import CarritoPasivo from '../../assets/CARRITO-PASIVO-AZUL.svg'
import CarritoHover from '../../assets/CARRITO-ACTIVO.svg'
import UsuarioPasivo from '../../assets/USUARIO_PASIVO.svg'
import UsuarioHover from '../../assets/USUARIO_HOVER.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { red } from "@mui/material/colors";
import ResponsiveDialog from "./DialogScreen";
import { LandingContext } from "../context/LandingContext";
import CerrarSesion from '../../assets/CERRAR SESION-PASIVO_24X24.svg'



const appBarSticky = {
    // backgroundColor: '#1877F2',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    width: '100%',
    height: '80px',
    justifyContent: {
        xs: 'space-between',
        md: 'space-evenly'
    },
}


const appBarScroll = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    // backgroundColor: 'red',
    boxShadow: 'none',
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    borderRadius: '0px 0px 8px 8px',
    boxShadow: '0px 4px 20px 0px rgba(25, 67, 127, 0.12)',
    width: '100%',
    height: '80px',
    justifyContent: {
        xs: 'space-between',
        md: 'space-evenly'
    },
}

const logoNoScroll = {
    height: { xs: '30px', md: '63px' },
    width: { xs: '100px', md: '180px' },
    backgroundImage: `url(${PasivoLogo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${HoverLogo})`
    },
}


const logoOnScroll = {
    height: '42px',
    width: '120px',
    backgroundImage: `url(${PasivoScroll})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${HoverScroll})`
    },
}
const carritoNoScroll = {
    height: '44px',
    width: '44px',
    borderRadius: '24px',
    backgroundImage: `url(${CarritoPasivoNoScroll})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${CarritoHover})`,
        cursor: 'pointer'
    },
}


const carritoOnScroll = {
    height: '44px',
    width: '44px',
    borderRadius: '24px',
    //backgroundColor: 'red',
    backgroundImage: `url(${CarritoPasivo})`,
    backgroundPosition: 'center',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    ":hover": {
        backgroundImage: `url(${CarritoHover})`,
        cursor: 'pointer'
    },
}

function disableScroll() {
    // Store the current X & Y scroll positions.
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    // If any scroll event is attempted on the window, change the window's 
    // onscroll function to always scroll back to the saved X & Y positions.
    window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
    };
}
function enableScroll() {
    // Reset the window's onscroll function.
    window.onscroll = function () { };
}


const MuiNavbar = () => {

    const navigate = useNavigate()

    const [navBackground, setNavBackground] = useState(appBarSticky)
    const [linkFonts, setLinkFonts] = useState('links')

    const { sidebarToggle, toggleSidebar, selectedIndexMenu, idSelectedIndexMenu } = useContext(SidebarContext);

    const [logo, setLogo] = useState(logoNoScroll);
    const [carrito, setCarrito] = useState(carritoNoScroll);
    const [arrowUp, setArrowUp] = useState(false);
    const [arrowColor, setArrowColor] = useState({ color: 'white' })

    const [loggedIn, setLoggedIn] = useState(false)

    const changeBackground = () => {
        console.log(window.scrollY);
    }

    const navRef = useRef()
    const linksRef = useRef()
    const bgRef = useRef()
    const carritoRef = useRef()
    const arrowRef = useRef()


    navRef.current = navBackground
    linksRef.current = linkFonts
    bgRef.current = logo;
    arrowRef.current = arrowColor;

    carritoRef.current = carrito;

    window.addEventListener('scroll', changeBackground)


    useEffect(() => {
        const handleScroll = () => {
            const show = window?.scrollY > 60
            if (show) {
                setNavBackground(appBarScroll)
                setLinkFonts('linksDark')
                setLogo(logoOnScroll);
                setCarrito(carritoOnScroll);
                setArrowColor({ color: 'black' })
            } else {
                setNavBackground(appBarSticky)
                setLinkFonts('links')
                setLogo(logoNoScroll);
                setCarrito(carritoNoScroll);
                setArrowColor({ color: 'white' })
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => document.removeEventListener('scroll', handleScroll)

    })

    const [open, setOpen] = useState(false)

    const onOpenHandler = () => {
        setOpen(true);
    }

    const onCloseHandler = () => {
        setOpen(false);
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setArrowUp(true)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };




    useEffect(() => {
        const handleWindowWheel = (event) => {
            if (open) {
                event.preventDefault();
            }
        };

        window.addEventListener('wheel', handleWindowWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWindowWheel);
        };
    }, [open]);

    const [openDialog, setOpenDialog] = useState(false);

    const { auth, setAuth } = useContext(LandingContext);


    return (
        <AppBar component={'nav'} position="fixed" sx={navRef.current}>
            <Toolbar sx={{
                // position: 'fixed',
                width: '100%',
                display: 'flex',
                justifyContent: { xs: 'space-between', md: 'space-around' }
            }}>
                <Box className="imgLogo"
                    sx={[bgRef.current]} onClick={() => navigate("/")}></Box>


                <Hidden smDown>

                    <Box className="navbarRoutes" sx={{
                        display: {
                            xs: 'none',
                            md: 'flex'
                        },
                        alignItems: 'center', justifyContent: 'center',

                    }}>
                        <Link className={`${linksRef.current}`} to="/" >Nosotros</Link>
                        <Link className={`${linksRef.current}`} to="productos">Productos</Link>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '2px',

                            }}
                        >

                            <Link className={`${linksRef.current}`} to="soluciones">Soluciones</Link>
                            {arrowUp ? <KeyboardArrowUpIcon onClick={() => setArrowUp(false)}
                                style={arrowRef.current} /> : <KeyboardArrowDownIcon onClick={handleClick} style={arrowRef.current} />}

                            <Fade in={arrowUp}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        position: 'absolute',
                                        width: '200px',
                                        top: 50,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: '4px',
                                        paddingTop: '12px'

                                    }}
                                >
                                    <Link className={`subLinkDark`} to="/producto/software/inforest" onClick={() => setArrowUp(false)}>INFOrest</Link>
                                    <Link className={`subLinkDark`} to="/producto/software/infohotel" onClick={() => setArrowUp(false)}>INFOhotel</Link>
                                    <Link className={`subLinkDark`} to="/producto/software/infoback" onClick={() => setArrowUp(false)}>INFOback</Link>
                                    <Link className={`subLinkDark`} to="productos" onClick={() => setArrowUp(false)}>INFOfact</Link>
                                    <Link className={`subLinkDark`} to="productos" onClick={() => setArrowUp(false)}>AMC Technology</Link>
                                </Box>
                            </Fade>

                        </Box>

                        <Link className={`${linksRef.current}`} to="soporte">Soporte</Link>
                        <Link className={`${linksRef.current}`}>Blog</Link>
                        {(auth !== '') ? <Link to={'/intranet'} onClick={() => idSelectedIndexMenu(0)} className={`${linksRef.current}`}>Zona Cliente</Link> : <Link onClick={() => { setOpenDialog(true) }} className={`${linksRef.current}`}>Iniciar Sesion</Link>}

                    </Box>

                </Hidden>

                <Box className="navbarRoutes" sx={{
                    display: {
                        xs: 'flex',
                    }
                    ,
                    backgroundColor: ''
                }}>
                    <Box sx={carritoRef.current}></Box>
                    <Box sx={{

                        height: '36px',
                        width: '36px',
                        backgroundImage: `url(${UsuarioPasivo})`,
                        backgroundColor: '',
                        ':hover': {
                            backgroundImage: `url(${UsuarioHover})`
                        }

                    }}

                        onClick={() => {

                            if (auth === '') {
                                setOpenDialog(true)
                            } else {
                                setLoggedIn(!loggedIn);
                            }

                        }}

                    >
                    </Box>

                    <Fade in={loggedIn}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                color: 'black',
                                position: 'absolute',
                                width: '360px',
                                top: 60,
                                right: { xs: 0, sm: 0, md: 120 },
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 16px 0px rgba(25, 67, 127, 0.12)'

                            }}
                        >

                            <Box
                                sx={{
                                    width: '100%',
                                    padding: '20px'
                                }}

                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#4884E2',
                                        width: '100%',
                                        borderRadius: '14px',
                                        height: '28px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'

                                    }}
                                >
                                    <Typography sx={{
                                        fontFamily: 'Poppins',
                                        color: '#FFF',
                                        width: '100%',
                                        fontSize: '12px',
                                        textAlign: 'center',
                                        // backgroundColor: 'red'
                                    }}>
                                        La Victoria Restobar S.A.C</Typography>
                                </Box>
                            </Box>


                            <Box
                                sx={{
                                    width: '100%',
                                    backgroundColor: '',
                                    gap: '8px',
                                    marginTop: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >

                                <Box sx={{

                                    height: '80px',
                                    width: '80px',
                                    backgroundImage: `url(${UsuarioPasivo})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',


                                }}></Box>
                                <Typography sx={{
                                    color: '#2A6EDB',
                                    letterSpacing: '1px',
                                    fontSize: '20px',
                                    fontFamily: 'Poppins'
                                }}>
                                    Cliente General
                                </Typography>


                                <Box>
                                    <Typography sx={{
                                        color: '#88919E',
                                        fontSize: '14px',
                                        fontFamily: 'Poppins'
                                    }}>
                                        Administración General Jr.
                                    </Typography>
                                    <Typography sx={{
                                        color: '#88919E',
                                        fontSize: '14px',
                                        fontFamily: 'Poppins'
                                    }}>
                                        cliente.general@gmail.com
                                    </Typography>

                                </Box>



                            </Box>

                            <Divider sx={{ backgroundColor: 'blue', marginTop: '20px' }} />

                            <Box sx={{
                                width: '100%',
                                paddingY: '12px',
                                paddingX: '20px',
                                ':hover': {
                                    cursor: 'pointer'
                                }
                            }}>
                                <Box >

                                </Box>
                                <Typography
                                    sx={{
                                        color: '#88919E',
                                        fontSize: '14px',
                                        fontFamily: 'Poppins',

                                    }}
                                >
                                    Cerrar Sesion
                                </Typography>
                            </Box>
                        </Box>
                    </Fade>

                    <Hidden mdUp>

                        <MenuIcon sx={{
                            color: 'black',
                        }}

                            onClick={() => setOpen(!open)}
                        />


                    </Hidden>


                </Box>


            </Toolbar>
            <Collapse
                in={open}
                sx={{
                    position: 'absolute',
                    top: 80,
                    left: 0,
                    width: '100%',
                    marginTop: '8px',

                }}
            >

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    borderRadius: '0px 0px 8px 8px',
                    gap: '24px',
                    paddingX: '20px',
                    paddingY: '12px'
                }}>
                    <Link className={`linksDark`} style={{

                    }} to="nosotros" onClick={() => setOpen(false)}>Nosotros</Link>
                    <Link className={`linksDark`} to="productos" onClick={onCloseHandler}>Productos</Link>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}
                    >

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Link className={`linksDark`} to="soluciones" onClick={() => setOpen(false)}>Soluciones</Link>
                            {arrowUp ? <KeyboardArrowUpIcon onClick={() => setArrowUp(false)}
                                style={{ color: 'black' }} /> : <KeyboardArrowDownIcon onClick={handleClick} style={{ color: 'black' }} />}

                        </Box>

                        <Collapse in={arrowUp} sx={{
                            width: '100%',

                        }}>
                            <Box
                                sx={{
                                    backgroundColor: 'inherit',
                                    color: 'black',
                                    width: '100%',
                                    top: 50,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '4px',

                                }}
                            >
                                <Link className={`subLinkDark`} to="/producto/software/inforest" onClick={() => setArrowUp(false)}>INFOrest</Link>
                                <Link className={`subLinkDark`} to="/producto/software/infohotel" onClick={() => setArrowUp(false)}>INFOhotel</Link>
                                <Link className={`subLinkDark`} to="/producto/software/infoback" onClick={() => setArrowUp(false)}>INFOback</Link>
                                <Link className={`subLinkDark`} to="productos" onClick={() => setArrowUp(false)}>INFOfact</Link>
                                <Link className={`subLinkDark`} to="productos" onClick={onCloseHandler}>AMC Technology</Link>
                            </Box>
                        </Collapse>

                    </Box>
                    <Link className={`linksDark`} to="soporte" onClick={onCloseHandler}>Soporte</Link>
                    <Link className={`linksDark`}>Blog</Link>
                    {(auth !== '') ? <Link to={'#'} className={`linksDark`}>Zona Cliente</Link> : <Box onClick={() => { setOpenDialog(true) }} className={`linksDark`}>Iniciar Sesion</Box>}
                </Box>
            </Collapse>

            <ResponsiveDialog open={openDialog} setOpen={setOpenDialog} />
        </AppBar>
    )
}

export default MuiNavbar


