import React, {useEffect} from "react";


import Logo from '../assets/INFOBACK.svg'
import DescripcionSolucion from "../components/DescripcionSolucion";

import InfohotelImg from '../assets/clienteInfohotel.jpg'
import ModulesComplements from "../components/ModulesComplements";


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Grid } from "@mui/material";



const inforestDesc = {
    description: 'Software de gestión logística , inventarios y costos directos. Se complementa con nuestras soluciones INFOrest o INFOhotel.',
    items: [
        'Controla todo desde un solo lugar.',
        'Manejo de la logística de entrada y salida.',
        'Compras, inventarios, proveedores y cuentas por pagar.',
        'Indicadores de gestión, control y planificación.'
    ],
    image: InfohotelImg
}

const inforestComp = {
    modulos: [
        {
            title: 'Solicitud de compra.',
            desc: ''
        },
        {
            title: 'Órdenes de compra.',
            desc: ''
        },
        {
            title: 'Control de Recetas Base y Propiedades.',
            desc: ''
        },
        {
            title: 'Porcionamiento.',
            desc: ''
        },
        {
            title: 'Orden de producción.',
            desc: ''
        },
        {
            title: 'Simulación de Costos.',
            desc: ''
        },
    ],
    complementos: [{
        title: 'GUI (Interfaz Gráfica de Usuarios):',
        desc: 'Observa los movimientos hechos en tiempo real. Realiza integraciones de balanzas. Calcula el peso exacto de los productos. Recalcula los precios de ventas por el impacto de mermas.'
    }]
}


const modulos = ['Ingresos al Almacén Central.', 'Kardex.', 'Requerimientos.', 'Transferencias.', 'Notas de Crédito.']


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, []);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




const Infoback = () => {


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box

            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >

            <Box
                sx={{
                    width: '100%',
                    height: '80px',
                    backgroundColor: '#F07B43',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}

            >
                <img width={'300px'} height={'200px'} src={Logo}></img>
            </Box>

            <DescripcionSolucion info={inforestDesc} />

            <Box

                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '60px'
                }}
                display={'none'}
            >
                <Typography color={'#F07B43'} fontSize={'40px'} sx={{
                    marginLeft: '350px',
                    fontFamily: 'Gotham',
                    display: 'none'
                }}>INFOback Estandar</Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    width: '1200px',
                    marginBottom: '60px',
                    flexDirection: 'column',
                    marginTop: '60px',
                    display: 'none'
                }}

            >


                <Box>

                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                        TabIndicatorProps={{
                            style: {
                                display: 'none',
                            }
                        }}

                        textColor="inherit"
                    >
                        <Tab label="Modulos" {...a11yProps(0)} sx={{ backgroundColor: (value === 0) ? '#EEF5FC' : 'transparent', fontFamily: 'Gotham', color: (value === 0) ? '#F07B43' : '#232323', fontSize: '20px', padding: '20px' }} />

                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Grid
                        container
                        sx={{
                            backgroundColor: '#EEF5FC',
                            width: '1220px',
                            height: '441px',
                            padding: '20px'
                        }}

                        columns={2}

                        columnGap={8}

                    >

                        {modulos.map((item, index) => (
                            <Box
                                sx={{
                                    width: '550px',
                                    height: '107px',
                                    backgroundColor: 'white',
                                    boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                                    borderRadius: '24px',
                                    padding: '12px'
                                }}

                            >
                                <Typography component={'span'} color={'black'}
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'PoppinsSemiBold'
                                    }}

                                >
                                        {item}
                                    

                                </Typography>
                            </Box>
                        ))}



                    </Grid>
                </CustomTabPanel>
            </Box>

            <Box

                sx={{
                    width: { xs: '90%', md:'100%'},
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '60px',
                    
                }}

            >
                <Typography color={'#F07B43'} fontSize={'40px'} sx={{
                    marginLeft: {xs: '0px', md:'350px'},
                    fontFamily: 'Gotham',
                    
                }}>INFOback Profesional</Typography>
            </Box>
            <ModulesComplements info={inforestComp} colorPrimary={'#F07B43'} />
        </Box>
    )
}

export default Infoback;