import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableHead2 from './EnhancedTableHead2';

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

                <TableCell

                    onClick={() => setOpen(!open)}
                    component="th" scope="row"
                >
                    <Typography sx={{
                        textDecoration: 'none',
                        background: 'linear-gradient(currentColor, currentColor) bottom / 0 .1em no-repeat',
                        transition: '0.5s background-size',
                        textTransform: 'uppercase',
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '18px',
                        backgroundPosition: 'left bottom',
                        '&:hover':
                        {
                            backgroundSize: '100% .1em',
                            backgroundPosition: 'left bottom',
                            color: '#2A6EDB',
                            cursor: 'pointer'
                        }
                    }}>
                        {row.name}
                    </Typography>
                </TableCell>
                <TableCell><Typography sx={{

                    fontSize: '14px'
                }}>{row.calories}</Typography></TableCell>
                <TableCell sx={{
                    backgroundColor: '',
                    padding: 0
                }}><Typography sx={{
                    backgroundColor: '#E3F4F9',
                    color: '#30ADD2',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    textAlign: 'center',
                    borderRadius: '20px',
                    paddingX: '6px',
                    paddingY: '5px',
                    width: '100%'
                }}>{row.fat}</Typography></TableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{paddingX: '10px',
                                    paddingBottom: '10px'}}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    
                                }}
                            >
                                <Typography sx={{
                                    fontFamily: 'Poppins',
                                    color: '#232323',
                                    fontSize: '14px'
                                }}>
                                    Responsable:
                                </Typography>
                                <Typography sx={{
                                    fontFamily: 'Poppins',
                                    color: '#232323',
                                    fontSize: '14px'
                                }} >
                                    General
                                </Typography>
                            </Box>

                            <Typography sx={{
                                    fontFamily: 'Poppins',
                                    color: '#232323',
                                    fontSize: '14px'
                                }} >
                                    correousuario@infomatica.pe
                                </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

const rows = [
    createData('T0000000', '08/08/23', 'En proceso'),
    createData('T0000000', '08/08/23', 'Atendido'),
    createData('T0000000', '08/08/23', 'En proceso'),
    createData('T0000000', '08/08/23', 'Atrasado'),
    createData('T0000000', '08/08/23', 'En proceso'),
    createData('T0000000', '08/08/23', 'Atendido'),
    createData('T0000000', '08/08/23', 'Atendido'),
    createData('T0000000', '08/08/23', 'En proceso'),
    createData('T0000000', '08/08/23', 'Atendido'),
    createData('T0000000', '08/08/23', 'Atendido'),
    createData('T0000000', '08/08/23', 'Atendido'),
];

export default function CollapsibleTable() {


    const classes = {
        root: {
            width: '100%',
            paddingLeft: {
                xs: '0px',
                sm: '20px',
                md: '88px'
            },
            paddingRight: {
                xs: '0px',
                sm: '20px',
            },

        },
        paper: {
            width: '100%',
            marginBottom: '12px',
        },
        table: {
            minWidth: '50px !important',
            marginBottom: '12px',
        },

        tableCell: {
            padding: '0px',
            height: '10px',
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
        },
        tablerow: {

        },

        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 10,
            width: 1,
        },
        imageIcon: {
            height: '24px',
        },
        iconRoot: {
            textAlign: 'center'
        },

        card: {

        }

    }

    const headCells =
        [
            { id: 'tickets', numeric: false, disablePadding: false, label: 'Ticket', width: '14px', left: '10px', visible: true },
            { id: 'enviado', numeric: true, disablePadding: true, label: 'Enviado', width: '80px', left: '0px', visible: true },
            { id: 'estado', numeric: true, disablePadding: true, label: 'Estado', width: '60px', left: '0px', visible: true },

            // { id: 'fechadeclaracion', numeric: true, disablePadding: false, label: 'fecha declaracion', width: '160px', left: '0px', visible: true },
            // { id: 'observacion', numeric: false, disablePadding: false, label: 'observación', width: '440px', left: '0px', visible: true },

        ];

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('documento');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <TableContainer component={Box} sx={{ border: 'none', width: '100%' }}>
            <Table stickyHeader aria-label="sticky table">
                {/* <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography>
                                Ticket
                            </Typography>
                        </TableCell>
                        <TableCell align="right">Calories</TableCell>
                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
 
                    </TableRow>
                </TableHead> */}

                <EnhancedTableHead2

                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={() => handleRequestSort}
                    headCellsp={headCells}
                    rowCount={10}
                />
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}