import React from 'react';
import { Box, Typography, Button } from '@mui/material';



const Producto = ({ bg, titulo, precio, description }) => {
    return (<Box
        sx={
            {
                width: { xs: '90%', md: '570px' },
                height: {xs: '700px'  ,md:'900px'},
                borderRadius: '30px',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
            }
        }
    >

        <Box
            sx={{
                width: {xs:'80%', md:'90%'},
                height: {xs:'300px', md:'490px'},
                backgroundImage: `url(${bg})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}

        >

        </Box>

        <Typography
            component={'h6'}
            sx={{
                color: '#232323',
                fontFamily: 'PoppinsSemiBold',
                textTransform: 'uppercase',
                fontSize: {xs: '28px' , md:'40px'},
            }}
        >{titulo}</Typography>

        <Typography
            component={'span'}
            sx={{
                fontFamily: 'PoppinsLight',
                color: 'black'
            }}
        >
            {precio}
        </Typography>
        <Typography
            component={'p'}
            sx={{
                fontFamily: 'Poppins',
                color: 'black',
                width: '70%',
                marginTop: '20px'
            }}
        >
           {description}
        </Typography>

        <Button
            sx={{
                width: '320px',
                height: '56px',
                backgroundColor: '#42C67E',
                borderRadius: '30px',
                boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                color: '#FFF',
                fontFamily: 'PoppinsLight',
                textTransform: 'uppercase',
                marginTop: '30px',
                ":hover": {

                    backgroundColor: '#79DD8B'

                }
            }}
        >
            Leer mas
        </Button>

    </Box>)
}

export default Producto
