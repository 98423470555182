import React, {useEffect} from 'react'
import { Box } from '@mui/material';
import Logo from '../assets/AMC_186X60.svg'
import Producto from '../components/Producto';
import ImpresoraBg from '../assets/impresora.webp'
import ModuloBg from '../assets/modulo.webp'
const Productos = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box
                sx={{
                    width: '100%',
                    height: '80px',
                    backgroundColor: '#4884E2',
                    display: 'flex',
                    alignItems: 'center',
                    
                }}
            >
                


            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '200px',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    
                }}
            >
                
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    
                    }}

                >
                    <img width={'300px'} height={'200px'} src={Logo}></img>
                </Box>

            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',

                    display: 'flex',
                    justifyContent: {xs: '', md:'center'},
                    alignItems: {xs: 'center' ,md: 'center'},

                    backgroundColor: 'transparent',
                    flexDirection: { xs: 'column', md: 'row'},
                    gap: '100px',
                    paddingY: '20px'
                }}
            >

            <Producto bg={ImpresoraBg} titulo={'impresora amc a6'} precio={'$182.90'} description={'Ideal para un entorno de pago concurrido donde se requiere una impresión de gran...'}/>
            <Producto bg={ModuloBg} titulo={'amc 1500'} precio={'$1298.00'}   description={'Ideal para restaurantes o tiendas minoristas con entornos operativos difíciles.'}/>
                
                

            </Box>
        </Box>
    )
}

export default Productos;