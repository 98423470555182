
import React from "react";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Selector from '../assets/SELECTOR_160x12.svg'
import { Grid } from "@mui/material";

import AdminLogo from '../assets/1-ADMIN_32X32.svg'

//import makeStyles from "@mui/material/styles";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ModulesComplements = ({ info, colorPrimary }) => {

    const { modulos, complementos } = info;

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box
            sx={{
                display: 'flex',
                width: { xs: '80%', md: '1200px' },
                marginBottom: '60px',
                flexDirection: 'column',
                marginTop: '60px',

            }}

        >

            <Box>

                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                    TabIndicatorProps={{
                        // style: {
                        //     display: 'none',
                        // },
                        sx: {
                            height: '20px',
                            backgroundImage: `url(${Selector})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundColor: 'transparent',
                            '& .Mui-selected': {
                                color: 'var(--colorPrimary-Green) !important',
                            }
                        }
                    }}
                    textColor="inherit"
                    sx={{
                        width: '100%',

                    }}
                >
                    <Tab label="Modulos" {...a11yProps(0)} sx={{ backgroundColor: (value === 0) ? 'transparent' : 'transparent', fontFamily: 'Gotham', color: (value === 0) ? colorPrimary : '#232323', fontSize: '20px', padding: '20px', flex: 1, marginBottom: '8px', textTransform: 'none' }} />
                    <Tab label="Complementos" {...a11yProps(1)} sx={{ backgroundColor: (value === 1) ? 'transparent' : 'transparent', fontFamily: 'Gotham', color: (value === 1) ? colorPrimary : '#232323', fontSize: '20px', padding: '20px', flex: 1, marginBottom: '8px', textTransform: 'none' }} />
                    {/* <Box

                        sx={{
                            minWidth: '160px',
                            height: '20px',
                            backgroundImage: `url(${Selector})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            position: "absolute",

                        }}

                    >

                    </Box> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} >
                <Grid
                    container
                    sx={{

                        width: '100%',
                        height: 'auto',
                        padding: '20px',

                    }}

                // columns={2}
                    columnSpacing={2}
                    rowSpacing={1}




                >

                    {modulos?.map((item, index) => (
                        <Grid
                            item

                            xs={12}
                            sm={6}
                            md={4}

                            
                        >
                            <Accordion
                                sx={{
                                    width:  '100%' ,

                                    height: '100%',
                                    backgroundColor: 'transparent',
                                    // boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                                    borderRadius: '24px',
                                    padding: '12px',
                                    boxShadow: 'none',
                                    '&:before': {
                                        display: 'none',
                                    }

                                }}

                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color="#42C67E" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}
                                >

                                    <img src={AdminLogo} />
                                    <Typography

                                        component={'p'} color={'black'}
                                        sx={{
                                            fontSize: '14px',
                                            fontFamily: 'PoppinsSemiBold',
                                            marginLeft: '20px',
                                            textAlign: 'left',

                                        }}
                                    >{item?.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        component={'p'} color={'black'}
                                        sx={{
                                            fontSize: '14px',
                                            fontFamily: 'PoppinsLight'
                                        }}

                                    >
                                        {item?.desc}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                    ))}



                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Grid
                    container
                    sx={{
                        backgroundColor: 'transparent',
                        width: { xs: '100%', sm: '100%', md: '100%', lg: '1180px' },
                        height: 'auto',
                        padding: '20px'
                    }}

                    // columns={2}

       


                >

                    {complementos?.map((item, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}

                        >
                            <Accordion
                                sx={{
                                    width: '100%',
                                    minHeight: '56px',
                                    height: 'auto',
                                    backgroundColor: 'transparent',
                                    // boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                                    borderRadius: '24px',
                                    padding: '12px',
                                    boxShadow: 'none',
                                    '&:before': {
                                        display: 'none',
                                    }

                                }}

                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color="#42C67E" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}
                                >

                                    <img src={AdminLogo} />
                                    <Typography

                                        component={'p'} color={'black'}
                                        sx={{
                                            fontSize: '14px',
                                            fontFamily: 'PoppinsSemiBold',
                                            marginLeft: '20px',
                                            textAlign: 'left',

                                        }}
                                    >{item?.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        component={'p'} color={'black'}
                                        sx={{
                                            fontSize: '14px',
                                            fontFamily: 'PoppinsLight'
                                        }}

                                    >
                                        {item?.desc}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </CustomTabPanel>



        </Box>
    )
}

export default ModulesComplements

{/* <Box
                            sx={{
                                width: '550px',
                                minHeight: '107px',
                                height: 'auto',
                                backgroundColor: 'white',
                                boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                                borderRadius: '24px',
                                padding: '12px'
                            }}

                        >
                            <Typography component={'p'} color={'black'}
                                sx={{
                                    fontSize: '14px',
                                    fontFamily: 'PoppinsLight'
                                }}

                            >
                                <Typography component={'span'} color={'black'}
                                    sx={{
                                        fontFamily: 'PoppinsSemiBold'
                                    }}

                                >
                                    {item?.title}
                                </Typography> {' '}

                                {item?.desc}

                            </Typography>
                        </Box> */}