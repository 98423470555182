import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { GlobalStyles } from '@mui/material';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(
    order,
    orderBy,
){
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}



const headCellsBajasFactura  =
    [
        { id: 'Id', numeric: false, disablePadding: false, label: 'Id', width: '20px', left: '10px', visible: false },
        { id: 'tipodocumento', numeric: true, disablePadding: true, label: 'Documento', width: '100px', left: '0px', visible: true },
        { id: 'Serie', numeric: true, disablePadding: true, label: 'Serie - Número', width: '100px', left: '0px', visible: true },
        { id: 'FechaEmision', numeric: false, disablePadding: false, label: 'fecha emision', width: '100px', left: '0px', visible: true },
        { id: 'MotivoBaja', numeric: true, disablePadding: false, label: 'Motivo', width: '100px', left: '0px', visible: true },

    ];



 
const headCellsBajasBoleta =
[
    { id: 'Id', numeric: false, disablePadding: false, label: 'Id', width: '100px', left: '10px', visible: false },
    { id: 'tipodocumento', numeric: true, disablePadding: true, label: 'Documento', width: '100px', left: '0px', visible: true },
    { id: 'Serie', numeric: true, disablePadding: false, label: 'Serie', width: '100px', left: '0px', visible: true },
    { id: 'CorrelativoInicio', numeric: true, disablePadding: true, label: 'Correlativo Inicio', width: '100px', left: '0px', visible: true },
    { id: 'CorrelativoFin', numeric: false, disablePadding: false, label: 'Correlativo Fin', width: '100px', left: '0px', visible: true },
    { id: 'Moneda', numeric: true, disablePadding: false, label: 'Moneda', width: '100px', left: '0px', visible: true },
    { id: 'Gravadas', numeric: true, disablePadding: false, label: 'Op. Gravadas', width: '100px', left: '0px', visible: true },
    { id: 'TotalIgv', numeric: false, disablePadding: false, label: 'IGV', width: '100px', left: '0px', visible: true },
    { id: 'Exoneradas', numeric: false, disablePadding: false, label: 'Monto Exonerado', width: '100px', left: '0px', visible: true },
    { id: 'TotalOtrosImpuestos', numeric: false, disablePadding: false, label: 'Op. No Gravadas', width: '100px', left: '0px', visible: true },
    { id: 'Exportacion', numeric: false, disablePadding: false, label: 'Op. Exportación', width: '100px', left: '0px', visible: true },
    { id: 'Gratuitas', numeric: false, disablePadding: false, label: 'Op. Gratuita', width: '100px', left: '0px', visible: true },
    { id: 'TotalVenta', numeric: false, disablePadding: false, label: 'Importe Total', width: '100px', left: '0px', visible: true },
    { id: 'FechaEmision', numeric: false, disablePadding: false, label: 'fecha emision', width: '100px', left: '0px', visible: true },

];

const TableCellColumn = {
    padding: '0px',
    height: '48px',
    paddingRight: '10px',
    paddingLeft: '10px',
    textTransform: 'capitalize',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
}


const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440, overflow: 'auto', borderRadius: '8px' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}