import React, { useEffect } from "react";
import Header from "../components/Header";
import Header2 from "../components/Header2";
import BoxClientes from "../components/BoxClientes";
import Innovar from "../components/Innovar";
import Soluciones from "../components/Soluciones";
import Posts from "../components/Posts";
import Resumen from "../components/Resumen";
import ConocerMas from "../components/ConocerMas";
import Formulario from "../components/Formulario";
import Footer from "../components/Footer";
import HeaderCambios from "../components/HeaderCambios";
import ReactVirtualizedTable from "../components/Shared/Intranet/TableTickets";

const Home = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, []);

    
    return (
    <div style={{backgroundColor: '#F8F9FC'}}>
        <Header2 />
        {/* <HeaderCambios /> */}
        <Innovar />
        <Soluciones/> 
        <Posts />
        <Resumen/> 
        <ConocerMas />

        


    </div>
    )
}

export default Home;