import React from "react";
import '../styles/BoxClientes.css'
import { Container, Box, Grid, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import Logo1 from '../assets/logos/MAIDO.svg'
import Logo2 from '../assets/logos/OSAKA.svg'
import Logo3 from '../assets/logos/CENTRAL.svg'
import Logo4 from '../assets/logos/RAFAEL.svg'
import Logo5 from '../assets/logos/ASTRID.svg'
import Logo6 from '../assets/logos/MERCADO.svg'
import Logo7 from '../assets/logos/COSTANERA.svg'
import Logo8 from '../assets/logos/FIESTA.svg'
import Logo9 from '../assets/logos/AMORAMAR.svg'
import Logo10 from '../assets/logos/DUNAS.svg'
import Logo11 from '../assets/logos/TALLANES.svg'
import Logo12 from '../assets/logos/ROOSEVELT.svg'
import Logo13 from '../assets/logos/ANDEANWINGS.svg'
import Logo14 from '../assets/logos/DMHOTELES.svg'
import Logo15 from '../assets/logos/BOULEVARD.svg'
import Logo16 from '../assets/logos/TAMBO.svg'
import Logo17 from '../assets/logos/CLUBCUSCO.svg'
import Logo18 from '../assets/logos/BRITANIA.svg'


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 720,
            md: 1080,
            lg: 1280,
            xl: 1440
        },
    },
});


/*
.cajaMarcas {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 840px;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 60px;
  padding-bottom: 48px;
  flex-direction: column;
  border-radius: 20;
  
}
*/

const BoxClientes = () => {

    return (
        <Box
            className="cajaMarcas"
            sx={{
                width: { xs: '90%', sm: '90%', md: '1222px' },
                height: { xs: 'max-content', sm: 'max-content', md: '432px' },
                boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                borderRadius: '20px',
                //marginBottom: '110px',
                justifyContent: 'center',
                // left: { xs: 20, sm: 20, md: 349 },
                //right: {xs: 20, sm: 20 ,md: 349},
                // right: 191,
                bottom: 0,
                top: { xs: 544, sm: 544, md: 840 },
                gap: '16px',

                paddingX: { xs: '12px', sm: '12px', md: '25px'},
                paddingTop: { xs: '32px', sm: '32px', md: '60px'},
                paddingBottom: { xs: '24px', sm: '24px', md: '48px'},

            }}>


            <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '' }}>
                <Typography className="description" sx={{
                    color: "#88919E", fontFamily: 'Gotham', fontSize: {
                        xs: '24px', sm: '24px', md: '32px',
                        height: 'auto'
                    }
                }}> Socio tecnológico de confianza para más de <span style={{ color: '#42C67E' }}>1000 clientes</span> alrededor del mundo </Typography>
            </Box>

            <Grid sx={{ justifyContent: 'center', alignItems: '' }} container columnGap={'12px'}
                rowGap={{ sm: '0px', md: '20px' }} >
                <Grid item>
                    <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo1})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item >
                    <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo2})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item >
                    <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo3})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item >
                    <Box
                        sx={{
                            width: { xs: '102.6px', sm: '102.6px', md: '154px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo4})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                    <Box
                        sx={{
                            width: { xs: '102.6px', sm: '102.6px', md: '154px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo5})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo6})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo7})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo8})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo9})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo10})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo11})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo12})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                    <Box
                        sx={{
                            width: { xs: '102.6px', sm: '102.6px', md: '154px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo13})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '102.6px', sm: '102.6px', md: '154px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo14})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '102.6px', sm: '102.6px', md: '154px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo15})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo16})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '85.3px', sm: '85.3px', md: '128px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo17})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>
                <Grid item>
                <Box
                        sx={{
                            width: { xs: '64px', sm: '64px', md: '96px' },
                            height: { xs: '64px', sm: '64px', md: '96px' },
                            backgroundImage: `url(${Logo18})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >

                    </Box>
                </Grid>

            </Grid>
        </Box>
    )

}

export default BoxClientes;