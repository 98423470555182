import React, { Fragment, useState } from 'react'
import { Box, Grid, Typography, Button, Stack, Paper, Container } from "@mui/material";
import { styled } from '@mui/material/styles';
import Carousel from "react-material-ui-carousel";
import PostBg from '../assets/COSME.webp'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



const OpinionBox = ({ testimonios }) => {
    const [postIndex, setPostIndex] = useState(0);

    return (
        <Container sx={{ backgroundColor: '', width: '100%', display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' }, flexDirection: 'column', alignItems: 'center' }}>

            <Carousel

                animation="fade"
                index={postIndex}
                next={(next, active) => {
                    console.log(`we left ${active}, and are now at ${next}`);
                    setPostIndex(next);
                }}
                prev={(prev, active) => {
                    setPostIndex(prev);
                    console.log(`we left ${active}, and are now at ${prev}`);
                }}


                autoPlay={true}
                interval={15000}

                sx={{
                    display: {
                        xs: 'none',
                        sm: 'flex',
                        md: 'none'
                    },
                    borderRadius: '20px 20px 60px 20px',
                    width: '90%',
                    height: { xs: '520px', sm: '324px' },
                    flexDirection: 'row',
                    justifyContent: 'center',

                    marginTop: '24px',

                    // backgroundColor: 'red'

                }}

                activeIndicatorIconButtonProps={{
                    style: {
                        color: '#42C67E'
                    }
                }}


                indicatorIconButtonProps={{
                    style: {
                        height: '8px',
                        fontWeight: '8px'
                    }
                }}

                indicatorContainerProps={{
                    style: {
                        position: 'absolute',
                        left: 0,
                        top: 290,
                    }
                }}
            >



                {
                    testimonios.map((item, index) => (
                        <Stack
                            direction={'row'}
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'flex',
                                    md: 'none'
                                },
                                width: '90vw',
                                height: '280px',
                                borderRadius: '20px 20px 60px 20px', flexDirection: 'row', gap: '12px',
                                backgroundColor: 'white',
                                boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',

                            }}
                        >


                            <Box sx={{
                                width: '328px', paddingY: '20px', paddingX: '24px',
                                backgroundColor: ''
                            }}>
                                <Box

                                    sx={{
                                        // backgroundColor: 'blue',
                                        height: '160px'
                                    }}
                                >
                                    <Typography component={"p"} sx={{ color: '#1E2947', fontFamily: 'PoppinsLight', fontSize: '14px', lineHeight: '20px', width: '280px', backgroundColor: '' }}>{item.testimonio}</Typography>
                                </Box>
                                <Box sx={{ marginTop: '8px' }}>
                                    <Typography component={'p'} sx={{ color: '#1E2947', fontFamily: 'Gotham', fontSize: '20px', letterSpacing: '1px' }}>{item.persona}</Typography>
                                    <Typography component={'p'} sx={{ color: '#88919E', fontFamily: 'PoppinsLight', fontSize: '14px', lineHeight: '20px', alignSelf: 'stretch' }}>{item.vocacion}</Typography>
                                    <Typography component={'p'} sx={{ color: '#2A6EDB', fontFamily: 'PoppinsSemiBold', fontSize: '14px' }}>{item.empresa}</Typography>
                                </Box>



                            </Box>

                            <Box
                                sx={
                                    {
                                        backgroundImage: `url(${item.BgLocal})`,
                                        width: '100%',
                                        height: 'auto',
                                        borderRadius: '0px 0px 60px 20px',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        padding: '12px'
                                    }
                                }
                            >

                                <Box

                                    sx={{
                                        width: '100px',
                                        height: '100px',
                                        backgroundImage: `url(${item.logo})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',

                                        boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.20)',
                                        borderRadius: '20px'
                                    }}

                                >

                                </Box>
                            </Box>


                        </Stack>

                    ))
                }


            </Carousel>


            <Carousel

                animation="fade"
                index={postIndex}
                next={(next, active) => {
                    console.log(`we left ${active}, and are now at ${next}`);
                    setPostIndex(next);
                }}
                prev={(prev, active) => {
                    setPostIndex(prev);
                    console.log(`we left ${active}, and are now at ${prev}`);
                }}


                autoPlay={true}
                interval={15000}
                sx={{
                    display: {
                        xs: 'flex',
                        sm: 'none',
                        md: 'none'
                    },
                    borderRadius: '20px 20px 60px 20px',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: '24px',
                    backgroundColor: '', padding: '30px'

                }}

                activeIndicatorIconButtonProps={{
                    style: {
                        color: '#42C67E'
                    }
                }}


                indicatorIconButtonProps={{
                    style: {
                        height: '8px',
                        fontWeight: '8px'
                    }
                }}

                indicatorContainerProps={{
                    style: {
                        // display: 'none',
                        position: 'absolute',
                        left: 0,
                        top: 545,
                        zIndex: 9999,
                    }
                }}
            >

                {
                    testimonios.map((item, index) => (
                        // <Item>
                        //     {item.empresa}
                        // </Item>
                        <Stack
                            direction={'column'}
                            sx={{
                                display: {
                                    xs: 'flex',
                                    sm: 'none',
                                    md: 'none'
                                },
                                width: '100%',
                                height: '520px',
                                borderRadius: '20px 20px 60px 20px', flexDirection: 'column', gap: '12px',
                                backgroundColor: 'white',
                                boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',

                            }}
                        >


                            <Box sx={{ width: '100%', height: '50%', padding: '20px', backgroundColor: '' }}>
                                <Typography component={"p"} sx={{ color: '#1E2947', fontFamily: 'PoppinsLight', fontSize: '14px', lineHeight: '20px' }}>{item.testimonio}</Typography>
                                <Box sx={{ marginTop: '8px' }}>
                                    <Typography component={'p'} sx={{ color: '#1E2947', fontFamily: 'Gotham', fontSize: '20px', letterSpacing: '1px' }}>{item.persona}</Typography>
                                    <Typography component={'p'} sx={{ color: '#88919E', fontFamily: 'PoppinsLight', fontSize: '14px', lineHeight: '20px', alignSelf: 'stretch' }}>{item.vocacion}</Typography>
                                    <Typography component={'p'} sx={{ color: '#2A6EDB', fontFamily: 'PoppinsSemiBold', fontSize: '14px' }}>{item.empresa}</Typography>
                                </Box>
                            </Box>

                            <Box
                                sx={
                                    {
                                        backgroundImage: `url(${item.BgLocal})`,
                                        width: '100%',
                                        height: '50%',
                                        borderRadius: '0px  0px 20px 20px',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        padding: '12px'
                                    }
                                }
                            >

                                <Box

                                    sx={{
                                        width: '100px',
                                        height: '100px',
                                        backgroundImage: `url(${item.logo})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',

                                        boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.20)',
                                        borderRadius: '20px'
                                    }}

                                >

                                </Box>
                            </Box>


                        </Stack>

                    ))
                }


            </Carousel>

            <Box
                sx={{
                    width: '108px',
                    height: '48px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '12px',
                    position: 'relative',
                    bottom: 70,
                    left: 0,
                    zIndex: 9999
                }}
            >
                <Box
                    sx={{
                        width: '48px',
                        height: '48px',
                        backgroundColor: '#42C67E',
                        borderRadius: '24px',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ':hover': {
                            backgroundColor: '#79DD8B',
                            cursor: 'pointer'
                        }
                    }}

                    onClick={() => {

                        if (postIndex === 0) {
                            setPostIndex(testimonios.length - 1)
                        } else {
                            setPostIndex(postIndex - 1)
                        }
                    }}
                >
                    <ArrowBackIosNewIcon style={{ color: 'white' }} />
                </Box>
                <Box
                    sx={{
                        width: '48px',
                        height: '48px',
                        backgroundColor: '#42C67E',
                        borderRadius: '24px',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ':hover': {
                            backgroundColor: '#79DD8B',
                            cursor: 'pointer'
                        }

                    }}
                    onClick={() => {
                        if (postIndex === testimonios.length - 1) {
                            setPostIndex(0)
                        } else {
                            setPostIndex(postIndex + 1)
                        }
                    }

                    }
                >
                    <ArrowForwardIosIcon sx={{ color: 'white' }} />
                </Box>
            </Box>

        </Container>



    )

}

export default OpinionBox;

