import { AppBar, Toolbar, Box, Hidden, Dialog, Drawer, SwipeableDrawer, Menu, MenuItem, Fade, backdropClasses, Collapse, Typography } from "@mui/material";

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const DropdownMenu = ({ valorDefault, setValorDefault, opciones }) => {

    const [arrowUp, setArrowUp] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setArrowUp(true)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box
            sx={{
                width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',
                display: 'flex',
                flexDirection: 'column',
                borderColor: (!(arrowUp) ? '#E2E5E8': '#2664C7'),
                borderWidth: '1px',
                borderStyle: 'solid',
                height: '56px'
            }}

            onClick={() => setArrowUp(!arrowUp)}
        >

            <Box
                sx={{

                    display: 'flex',
                    flexDirection: 'row',
                    paddingY: '19px',
                    paddingX: '12px',
                    justifyContent: 'space-between',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: '#E2E5E8',
                    height: '56px'

                }}
            >
                <Typography component={'h6'} sx={{
                    fontFamily: 'Poppins',
                    width: '100%'
                }}>{valorDefault}</Typography>
                {arrowUp ? <KeyboardArrowUpIcon onClick={() => setArrowUp(false)}
                    style={{ color: 'black' }} /> : <KeyboardArrowDownIcon onClick={handleClick} style={{ color: 'black' }} />}

            </Box>

            {arrowUp && <Fade in={arrowUp}

            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        position: 'static',
                        width: 'inherit',
                        marginTop: '20px',
                        zIndex: 9999,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        paddingTop: '12px',
                        borderColor: (!(arrowUp) ? '#E2E5E8': '#2664C7'),
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        height: '56px',
                        height: 'max-content'

                    }}
                >
                    {opciones.map((item, index) => {

                        return (
                            <Typography key={index}

                                sx={{
                                    color: '#1E2947',
                                    textTransform: 'uppercase',
                                    fontFamily: 'PoppinsLight',
                                    padding: '12px'
                                }}

                                onClick={() => { setValorDefault(item); setArrowUp(false); }}>{item}</Typography>)
                    })}

                </Box>
            </Fade>}

        </Box>

    )
};

export default DropdownMenu;