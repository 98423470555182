import React, {useEffect} from "react";
import { Box, Container, Typography, Button, Stack, Grid } from "@mui/material";
import Grupo from '../assets/Grupo.jpg'
import NosotrosBg from '../assets/nosostros.png'

import Logo1 from '../assets/restaurantelogo.svg'
import Logo2 from '../assets/hoteleslogo.svg'
import Logo3 from '../assets/cafeterialogo.svg'
import Logo4 from '../assets/bareslogo.svg'
import Logo5 from '../assets/pastelerialogo.svg'
import ItemLineaTiempo from "../components/ItemLineaTiempo";

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const lineaDeTiempo = [

    {
        year: '1991',
        descripcion: 'Se crea la empresa Turbosys S.R.L., desarrollando software de gestión de rentas municipales y facturación de servicios.'
    },
    {
        year: '1994',
        descripcion: 'Creamos el primer software para hoteles desarrollado íntegramente en el Perú, “TS-Hotel”.'
    },
    {
        year: '2005',
        descripcion: 'Turbosys S.R.L. es absorbida por Infhotel Servicios Informáticos S.A.C. TS-Hotel cambia de nombre a INFOhotel®.'
    },
    {
        year: '2006',
        descripcion: 'INFOrest® llega junto a la demanda local de software para restaurantes tras el inicio del boom gastronómico.'
    },
    {
        year: '2008',
        descripcion: 'Se crea BackOffice® y los motores de transferencia contable, integrando módulos contables, controlando los stocks, mermas y food cost.'
    },
    {
        year: '2016',
        descripcion: 'Nace INFOMÁTICA, consolidando sus productos INFOhotel®, INFOrest® y AMC Technologies® y sus complementos INFOback®, INFOfact®.'
    },
    {
        year: '2018',
        descripcion: 'Nace INFORMATE®, nuestro primer centro autorizado de capacitaciones, con el objetivo de certificar en el uso de nuestros productos.'
    },
]

const Nosotros = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box
                sx={{
                    width: '100%',
                    height: { xs: '300px', sm: '300px', md: '450px' },
                   // backgroundColor: { xs: 'red', sm: 'green', md: '#1877F2' },
                    backgroundColor: '#4884E2', 
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'center' },
                    alignItems: { xs: 'center', sm: 'center', md: 'flex-start' },
                    paddingX: { xs: '0px', md: '0px', lg: '330px' },
                    flexDirection: { xs: 'row', md: 'column' }

                }}
            >
                <Typography component={'h1'} color={'white'} sx={{ width: { xs: '70%', md: '581px' }, letterSpacing: '1.5px', fontSize: { xs: '18px', md: '45px' }, textAlign: { xs: 'center', md: 'left' }, fontFamily: 'PoppinsSemiBold', height: { xs: '70px' } }}>
                    Tu socio estratégico en el mercado HORECA.
                </Typography>
                <Box
                    sx={
                        {
                            width: { xs: '80%', md: '466px', lg: '566px' },
                            height: { xs: '200px', md: '286px', lg: '388px' },
                            borderRadius: '30px',
                            backgroundImage: `url(${Grupo})`,
                            opacity: 0.7,
                            position: 'absolute',
                            left: { xs: 40, sm: 80, md: 700, lg: 1000},
                            top: { xs: 200, md: 230 },
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }
                    }
                    className="grupoBg"
                >

                </Box>
            </Box>

            {/* <Box

                sx={{
                    width: '100%',
                    height: 'max-content',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingX: '300px',
                    flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' }

                }}
            >
                <Box
                    sx={
                        {
                            width: { xs: '450px', md: '570px' },
                            height: '388px',
                            borderRadius: '30px',
                            backgroundImage: `url(${NosotrosBg})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            marginRight: '100px',
                            marginTop: '160px',
                            flex: 1,

                        }
                    }

                >

                </Box>

                <Box display={'flex'} flexDirection={'column'} sx={{

                    height: '388px',
                    justifyContent: 'center',
                    alignItems: 'center',

                    paddingX: {
                        xs: '30px',
                        md: '0px'
                    },

                    flex: 1
                }}>


                    <Typography component={'h2'} color={'#1877F2'} sx={{ width: '581px', lineHeight: '68px', fontSize: { xs: '25px', md: '35px' }, textAlign: { xs: 'flex-start', md: 'left' }, fontFamily: 'PoppinsSemiBold', }}>
                        Comprometidos con tu exito
                    </Typography>
                    <Typography component={'h2'} color={'#232323'}
                        sx={{
                            width: '100%', fontSize: '16px', textAlign: 'left', fontFamily: 'Poppins'
                        }}>
                        Brindamos un servicio de alta calidad, con sentido de responsabilidad y en constante innovación; anticipándonos a sus necesidades y aportando a su negocio con nuestras soluciones.
                    </Typography>

                
                </Box>

            </Box> */}

            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingX: { xs: '0px', lg: '300px', xl:'300px'},
                    flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' },
                    
                    
                }}
            >
                <Grid container spacing={'20px'} sx={{
                    width: { xs: '90%', md: '90%', lg: '100%', xl: '100%' },
                    marginTop: '160px',

                    //backgroundColor: 'green'
                }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Box
                            sx={
                                {

                                    height: '388px',
                                    borderRadius: '30px',
                                    backgroundImage: `url(${NosotrosBg})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }
                            }

                        >
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Box display={'flex'} flexDirection={'column'} sx={{

                            height: '388px',
                            justifyContent: {xs: 'left', sm: 'flex-start' ,md: 'center'},
                            gap: '12px'
                        }}>


                            <Typography component={'h2'} color={'#1877F2'} sx={{ width: '100%', lineHeight: '32px', fontSize: { xs: '25px', md: '35px' }, textAlign: { xs: 'flex-start', md: 'left' }, fontFamily: 'PoppinsSemiBold', }}>
                                Comprometidos con tu exito
                            </Typography>
                            <Typography component={'h2'} color={'#232323'}
                                sx={{
                                    width: '100%', fontSize: '16px', textAlign: 'left', fontFamily: 'Poppins'
                                }}>
                                Brindamos un servicio de alta calidad, con sentido de responsabilidad y en constante innovación; anticipándonos a sus necesidades y aportando a su negocio con nuestras soluciones.
                            </Typography>


                        </Box>
                    </Grid>


                </Grid>

            </Box>

            <Box display={'flex'} flexDirection={'column'} sx={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '40px',
            }}>


                <Typography
                    component={'h2'} color="#1877F2"
                    sx={{
                        marginBottom: '40px',
                        fontSize: '35px',
                        fontFamily: 'Gotham'
                    }}
                >
                    Tenemos una larga trayectoria
                </Typography>



                <Box width={'100%'} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '60px'
                }}>

                    <Typography
                        component={'h2'} color="#1877F2"
                        sx={{
                            marginBottom: '40px',
                            fontSize: { xs: '30px', md: '35px' },
                            fontFamily: 'Gotham'
                        }}
                    >
                        Nuestra Mision y Vision
                    </Typography>


                    <Box sx={{
                        width: { xs: '100%', lg: '1220px' },
                        alignItems: 'center'
                    }} marginBottom={'20px'}>
                        <Box display={'flex'} sx={{
                            flexDirection: 'column',
                            paddingX: {
                                xs: '20px',
                                md: '0px'
                            }
                        }}>

                            <Box>
                                <Typography sx={{
                                    color: '#1877F2',
                                    letterSpacing: 1,
                                    fontSize: '26px',
                                    fontFamily: 'PoppinsLight'
                                }}
                                    component={'h3'}
                                >
                                    Mision
                                </Typography>
                                <Typography sx={{
                                    color: '#232323',
                                    fontSize: '16px',
                                    fontFamily: 'PoppinsLight'
                                }}
                                    component={'p'}
                                >
                                    Acompañar a cada cliente en la implantación de nuestras soluciones tecnológicas simplificando sus procesos de negocio.
                                </Typography>

                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: { xs: '100%', lg: '1220px' },
                        paddingX: {
                            xs: '20px',
                            md: '0px'
                        }

                    }}>
                        <Box display={'flex'} sx={{
                            flexDirection: 'column',

                        }}>

                            <Box>
                                <Typography sx={{
                                    color: '#1877F2',
                                    letterSpacing: 1,
                                    fontSize: '26px',
                                    fontFamily: 'PoppinsLight'
                                }}
                                    component={'h3'}
                                >
                                    Vision
                                </Typography>
                                <Typography sx={{
                                    color: '#232323',
                                    fontSize: '16px',
                                    fontFamily: 'PoppinsLight'
                                }}
                                    component={'p'}
                                >
                                    Ser una de las empresas líder en desarrollo de soluciones tecnológicas agregando valor a nuestros clientes, felicidad a nuestros colaboradoes y utilidades a nuestros accionistas.
                                </Typography>

                            </Box>
                        </Box>
                    </Box>

                </Box>

                <Box width={'100%'} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '60px'
                }}>
                    <Typography
                        component={'h2'} color="#1877F2"
                        sx={{
                            marginBottom: '40px',
                            fontSize: { xs: '30px', md: '35px' },
                            fontFamily: 'Gotham'
                        }}
                    >
                        Valores que nos identifican
                    </Typography>

                </Box>



            </Box>

            <Box width={'100%'} height={'210px'} sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '60px',
                backgroundColor: '#4884E2',
                justifyContent: 'center',
                alignItems: 'center',
                paddingX: {
                    xs: '20px',
                    md: '0px'
                }

            }}>

                <Typography component={'h3'} sx={{
                    width: '570px',
                    color: 'white',
                    fontSize: { xs: '30px', md: '42px' },
                    fontFamily: 'Poppins',

                }}>
                    Conoce las soluciones que tenemos para ti
                </Typography>
                <Box

                    onClick={() => console.log('On click')}
                    sx={{
                        backgroundColor: '#42C67E', borderRadius: '30px', padding: '12px', width: '320px', height: '56px', textTransform: 'uppercase',
                        ":hover": {
                            backgroundColor: '#79DD8B',
                            cursor: 'pointer'

                        },
                        fontFamily: 'PoppinsLight',
                        textAlign: 'center',
                        color: 'white',
                        fontSize: { xs: '14px', md: '20px' }
                    }}>Ver todas las soluciones</Box>

            </Box>

        </Box>


    )
}

export default Nosotros

{/* <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', paddingTop: '30px' }}>

                    <Box sx={{
                        width: '4px',
                        height: '1820px',
                        backgroundColor: '#1877F2',
                        position: 'relative',
                        left: 400
                    }}>
                    </Box>

                    <Box

                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            left: 240
                        }}


                    >

                        {lineaDeTiempo.map((item, index) => (
                            <ItemLineaTiempo key={index} item={item} />
                        ))}

                    </Box>


                </Box> */}


{/* <AutoPlaySwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={activeStep}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents sx={{
                                            borderRadius: '10px',
                                            border: '1px solid var(--camposgris, #F2F2F2)',
                                            boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                                        }}
                                    >
                                        {images.map((step, index) => (
                                            <div key={index} style={{
                                                borderRadius: '10px',
                                            }}>
                                                {Math.abs(activeStep - index) <= 2 ? (
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: '100%',
                                                            display: 'block',
                                                            // maxWidth: '100%',
                                                            overflow: 'hidden',
                                                            width: '100%',
                                                            borderRadius: '10px',
                                                            boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                                                        }}
                                                        src={step.imgPath}
                                                    />
                                                ) : null}
                                            </div>
                                        ))}
                                    </AutoPlaySwipeableViews> */}