import React, { useContext } from "react";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Selector from '../../../assets/intranet/SELECTOR_160X8.svg'
import { Grid } from "@mui/material";
import Dolar from '../../../assets/intranet/MONEDA-AZUL_24X24.svg'
import ArrowDropdown from '../../../assets/intranet/FLECHA_CAMPO_24X24.svg'
import DropdownIntranetMenu from "./DropdownIntranet";
import ExportarImg from '../../../assets/intranet/EXPORTAR_24X24.svg'
import BuscarPasivo from '../../../assets/intranet/BUSCAR-PASIVO_20X20.svg'
import { SidebarContext } from "../../context/SidebarContext";
//import makeStyles from "@mui/material/styles";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabPanelPagos = () => {

    // const { modulos, complementos } = info;

    const [value, setValue] = React.useState(0);
    const { numfilas, setNumFilas, moneda, setMoneda} = useContext(SidebarContext)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box
            sx={{
                display: 'flex',
                width: { xs: '80%', md: '100%' },
                paddingLeft: { xs: '0px', md: '88px' },
                paddingRight: '20px',
                // marginBottom: '60px',
                flexDirection: 'column',
                // marginTop: '60px',
                backgroundColor: ''
            }}

        >

            <Box>

                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                    TabIndicatorProps={{
                        // style: {
                        //     display: 'none',
                        // },
                        sx: {
                            backgroundColor: '',
                            width: '100%',
                            height: '6px',
                            backgroundImage: `url(${Selector})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundColor: 'transparent',
                            '& .Mui-selected': {
                                color: '#2A6EDB !important',
                            }
                        }
                    }}
                    textColor="inherit"
                    sx={{
                        width: '320px',
                        backgroundColor: ''
                    }}
                >
                    <Tab label="Por Pagar" {...a11yProps(0)} sx={{ backgroundColor: (value === 0) ? 'transparent' : 'transparent', fontFamily: 'Gotham', color: (value === 0) ? '#2A6EDB' : '#232323', fontSize: '20px', flex: 1, marginBottom: '8px', textTransform: 'none' }} />
                    <Tab label="Pagados" {...a11yProps(1)} sx={{ backgroundColor: (value === 1) ? 'transparent' : 'transparent', fontFamily: 'Gotham', color: (value === 1) ? '#2A6EDB' : '#232323', fontSize: '20px', flex: 1, marginBottom: '8px', textTransform: 'none' }} />
                    {/* <Box

                        sx={{
                            minWidth: '160px',
                            height: '20px',
                            backgroundImage: `url(${Selector})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            position: "absolute",

                        }}

                    >

                    </Box> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} >
                <Grid
                    container
                    sx={{

                        width: '100%',
                        height: 'auto',
                        padding: '20px',
                        backgroundColor: '',
                        justifyContent: 'space-between'
                    }}

                    // columns={2}
                    columnSpacing={'21px'}
                    rowSpacing={1}
                >
                    <Grid item sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box
                            sx={{
                                width: '24px',
                                height: '24px',
                                backgroundImage: `url(${Dolar})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                marginRight: '12px'
                            }}
                        >
                        </Box>
                        <Box sx={{
                            marginRight: '20px'
                        }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Poppins',
                                    color: '#1E2947',
                                    fontSize: '16px'
                                }}
                            >
                                Elige 01 o más documentos a pagar
                            </Typography>
                        </Box>
                        <Box

                            sx={{
                                backgroundColor: '#E7EFFC',
                                height: '24px',
                                width: 'max-content',
                                borderRadius: '20px',
                                padding: '5px 12px'
                            }}

                        >
                            <Typography
                                sx={{
                                    fontFamily: 'PoppinsSemiBold',
                                    color: '#2664C7',
                                    fontSize: '12px',

                                }}
                            >
                                120 registros
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item sx={{
                        backgroundColor: ''
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '12px'
                            }}

                        >

                            {/* <Box sx={{
                                display: { xs: 'none', sm: 'flex' },
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                width: 'max-content',
                                height: '48px',
                                paddingX: '12px',
                                paddingY: '3px',
                                alignItems: 'center',
                                gap: '12px'
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#232323',
                                            fontFamily: 'PoppinsSemiBold',
                                            fontSize: '12px'
                                        }}

                                    >Moneda</Typography>
                                    <Typography
                                        sx={{
                                            color: '#232323',
                                            fontFamily: 'Poppins',
                                            fontSize: '14px'
                                        }}
                                    >PEN-SOL</Typography>
                                </Box>
                                <Box

                                    sx={{
                                        width: '18px',
                                        height: '18px',
                                        backgroundColor: '',
                                        backgroundImage: `url(${ArrowDropdown})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}
                                >

                                </Box>
                            </Box> */}

                            <DropdownIntranetMenu

                                valorDefault={moneda}
                                label={'Moneda'}
                                setValorDefault={setMoneda}
                                opciones={['PEN-SOL', 'USD-DOL']}
                            />

                            <DropdownIntranetMenu

                                valorDefault={numfilas}
                                label={'Filas'}
                                setValorDefault={setNumFilas}
                                opciones={[15, 50, 100]}
                            />

             

                            {/* <Box sx={{
                                display: {xs: 'none' ,md:'flex'},
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                width: 'max-content',
                                height: '48px',
                                paddingX: '12px',
                                paddingY: '3px',
                                alignItems: 'center',
                                gap: '12px'
                            }}>
                                <Box
                                    sx={{
                                
                                        display: {
                                            xs: 'none',
                                            md: 'block'
                                        }

                                    }}
                                
                                >
                                    <Typography
                                        sx={{
                                            color: '#232323',
                                            fontFamily: 'PoppinsSemiBold',
                                            fontSize: '12px'
                                        }}

                                    >Filas</Typography>
                                    <Typography
                                        sx={{
                                            color: '#232323',
                                            fontFamily: 'Poppins',
                                            fontSize: '14px'
                                        }}
                                    >15</Typography>
                                </Box>
                                <Box

                                    sx={{
                                        width: '18px',
                                        height: '18px',
                                        backgroundColor: '',
                                        backgroundImage: `url(${ArrowDropdown})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        display: {
                                            xs: 'none',
                                            md: 'block'
                                        }
                                    }}
                                >

                                </Box>
                            </Box> */}





                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                width: 'max-content',
                                height: '48px',
                                // paddingX: '12px',
                                // paddingY: '3px'
                            }}>
                                <Box
                                    sx={{

                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '4px',
                                        borderRightWidth: '1px',
                                        borderRightColor: '#E2E5E8',
                                        borderRightStyle: 'solid', paddingX: '12px',
                                        backgroundColor: ''
                                    }}

                                >
                                    <Typography sx={{
                                        color: '#2A6EDB',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Todos</Typography>
                                    <Box

                                        sx={{
                                            width: '18px',
                                            height: '18px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${ArrowDropdown})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>
                                </Box>
                                <Box
                                    sx={{

                                        display: 'none',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '311px',
                                        height: '100%',
                                        // gap: 'auto',
                                        paddingX: '12px',
                                        backgroundColor: ''
                                    }}

                                >
                                    <Typography sx={{
                                        color: '#88919E',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Buscar en la tabla</Typography>
                                    <Box

                                        sx={{
                                            width: '18px',
                                            height: '18px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${BuscarPasivo})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>
                                </Box>
                            </Box>


                            <Box sx={{
                                display: {xs: 'none',sm:'flex'},
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '30px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                height: '48px',
                                paddingX: '12px',
                                paddingY: '3px',
                                width: '180px',
                                backgroundColor: '#4884E2',
                                boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                                alignItems: 'center',

                            }}>
                                {/* <Box
                                    sx={{

                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '4px'
                                    }}

                                >
                                    <Typography sx={{
                                        color: '#2A6EDB',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Todos</Typography>
                                    <Box

                                        sx={{
                                            width: '18px',
                                            height: '18px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${ArrowDropdown})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>
                                </Box> */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        gap: '12px',
                                        // gap: 'auto',
                                        paddingX: '12px',
                                        justifyContent: 'center'

                                    }}
                                >
                                    <Box

                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${ExportarImg})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>


                                    <Typography sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Exportar</Typography>

                                </Box>
                            </Box>
                            <Box sx={{
                                display: {xs: 'flex',sm:'none'},
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '30px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                height: '48px',

                                width: '48px',
                                backgroundColor: '#4884E2',
                                boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                                alignItems: 'center',

                            }}>
                                {/* <Box
                                    sx={{

                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '4px'
                                    }}

                                >
                                    <Typography sx={{
                                        color: '#2A6EDB',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Todos</Typography>
                                    <Box

                                        sx={{
                                            width: '18px',
                                            height: '18px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${ArrowDropdown})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>
                                </Box> */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        gap: '12px',
                                        // gap: 'auto',
                                        paddingX: '12px',
                                        justifyContent: 'center'

                                    }}
                                >
                                    <Box

                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${ExportarImg})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>




                                </Box>
                            </Box>



                        </Box>
                    </Grid>


                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Grid
                    container
                    sx={{

                        width: '100%',
                        height: 'auto',
                        padding: '20px',
                        backgroundColor: '',
                        justifyContent: 'space-between'
                    }}

                    // columns={2}
                    columnSpacing={'21px'}
                    rowSpacing={1}
                >
                    <Grid item sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box
                            sx={{
                                width: '24px',
                                height: '24px',
                                backgroundImage: `url(${Dolar})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                marginRight: '12px'
                            }}
                        >
                        </Box>
                        <Box sx={{
                            marginRight: '20px'
                        }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Poppins',
                                    color: '#1E2947',
                                    fontSize: '16px'
                                }}
                            >
                                Lista de Documentos
                            </Typography>
                        </Box>
                        <Box

                            sx={{
                                backgroundColor: '#E7EFFC',
                                height: '24px',
                                width: 'max-content',
                                borderRadius: '20px',
                                padding: '5px 12px'
                            }}

                        >
                            <Typography
                                sx={{
                                    fontFamily: 'PoppinsSemiBold',
                                    color: '#2664C7',
                                    fontSize: '12px',

                                }}
                            >
                                120 registros
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item sx={{
                        backgroundColor: ''
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '12px'
                            }}

                        >

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                width: 'max-content',
                                height: '48px',
                                paddingX: '12px',
                                paddingY: '3px',
                                alignItems: 'center',
                                gap: '12px'
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#232323',
                                            fontFamily: 'PoppinsSemiBold',
                                            fontSize: '12px'
                                        }}

                                    >Moneda</Typography>
                                    <Typography
                                        sx={{
                                            color: '#232323',
                                            fontFamily: 'Poppins',
                                            fontSize: '14px'
                                        }}
                                    >PEN-SOL</Typography>
                                </Box>
                                <Box

                                    sx={{
                                        width: '18px',
                                        height: '18px',
                                        backgroundColor: '',
                                        backgroundImage: `url(${ArrowDropdown})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}
                                >

                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                width: 'max-content',
                                height: '48px',
                                paddingX: '12px',
                                paddingY: '3px',
                                alignItems: 'center',
                                gap: '12px'
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#232323',
                                            fontFamily: 'PoppinsSemiBold',
                                            fontSize: '12px'
                                        }}

                                    >Filas</Typography>
                                    <Typography
                                        sx={{
                                            color: '#232323',
                                            fontFamily: 'Poppins',
                                            fontSize: '14px'
                                        }}
                                    >15</Typography>
                                </Box>
                                <Box

                                    sx={{
                                        width: '18px',
                                        height: '18px',
                                        backgroundColor: '',
                                        backgroundImage: `url(${ArrowDropdown})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}
                                >

                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                width: 'max-content',
                                height: '48px',
                                // paddingX: '12px',
                                // paddingY: '3px'
                            }}>
                                <Box
                                    sx={{

                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '4px',
                                        borderRightWidth: '1px',
                                        borderRightColor: '#E2E5E8',
                                        borderRightStyle: 'solid', paddingX: '12px',
                                        backgroundColor: ''
                                    }}

                                >
                                    <Typography sx={{
                                        color: '#2A6EDB',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Todos</Typography>
                                    <Box

                                        sx={{
                                            width: '18px',
                                            height: '18px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${ArrowDropdown})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>
                                </Box>
                                <Box
                                    sx={{

                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '311px',
                                        height: '100%',
                                        // gap: 'auto',
                                        paddingX: '12px',
                                        backgroundColor: ''
                                    }}

                                >
                                    <Typography sx={{
                                        color: '#88919E',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Buscar en la tabla</Typography>
                                    <Box

                                        sx={{
                                            width: '18px',
                                            height: '18px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${BuscarPasivo})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: 'white',
                                borderRadius: '30px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: '#E2E5E8',
                                height: '48px',
                                paddingX: '12px',
                                paddingY: '3px',
                                width: '180px',
                                backgroundColor: '#4884E2',
                                boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                                alignItems: 'center',

                            }}>
                                {/* <Box
                                    sx={{

                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        gap: '4px'
                                    }}

                                >
                                    <Typography sx={{
                                        color: '#2A6EDB',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Todos</Typography>
                                    <Box

                                        sx={{
                                            width: '18px',
                                            height: '18px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${ArrowDropdown})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>
                                </Box> */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        gap: '12px',
                                        // gap: 'auto',
                                        paddingX: '12px',
                                        justifyContent: 'center'

                                    }}
                                >
                                    <Box

                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            backgroundColor: '',
                                            backgroundImage: `url(${ExportarImg})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    >

                                    </Box>


                                    <Typography sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                    }}>Exportar</Typography>

                                </Box>
                            </Box>
                        </Box>
                    </Grid>


                </Grid>
            </CustomTabPanel>



        </Box>
    )
}

export default TabPanelPagos

{/* <Box
                            sx={{
                                width: '550px',
                                minHeight: '107px',
                                height: 'auto',
                                backgroundColor: 'white',
                                boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                                borderRadius: '24px',
                                padding: '12px'
                            }}

                        >
                            <Typography component={'p'} color={'black'}
                                sx={{
                                    fontSize: '14px',
                                    fontFamily: 'PoppinsLight'
                                }}

                            >
                                <Typography component={'span'} color={'black'}
                                    sx={{
                                        fontFamily: 'PoppinsSemiBold'
                                    }}

                                >
                                    {item?.title}
                                </Typography> {' '}

                                {item?.desc}

                            </Typography>
                        </Box> */}