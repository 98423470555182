import React from "react";
import { Box, Button, Typography } from "@mui/material";
import NosotrosBg from '../../assets/solucion1.jpg'
import HotelBg from '../../assets/hotel.jpg'
import '../../styles/Soluciones.css'
import { Link } from "react-router-dom";
const ConocerBox = ({ titulo, texto, textoBtn, imageUrl }) => {
    return (
        <Box className='solucionBg'
            sx={
                {
                    width: '320px',
                    height: '480px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#FFF',
                    borderRadius: '60px 20px 20px 20px',
                    boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                    ":hover": {
                        ".conocerImage": {
                            boxShadow: 'none'
                        }
                    },

                }}>
            <Box className="conocerImage" sx={
                {
                    width: '100%', height: '260px', backgroundImage: `url(${HotelBg})`
                    ,
                    transition: 'box-shadow 0.5s',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    boxShadow: 'inset 0 0 0 1000px rgba(30, 41, 71, 0.4)',
                    borderRadius: '60px 20px 20px 20px',

                }
            }>

                <Box
                    sx={{
                        width: '211px',
                        height: '44px',
                        background: 'rgba(255, 255, 255, 0.80)',
                        borderRadius: '0px 10px 0px 20px',
                        display: 'flex',
                        justifyContent : 'center',
                        alignItems: 'center'
                    }}  
                >
                    <Typography sx={{
                        color: '#2A6EDB',
                        fontSize: '14px',
                        fontFamily: 'PoppinsLight'
                    }}>20 noviembre 2023</Typography>
                </Box>

            </Box>

            <Box sx={{
                paddingTop: '20px', paddingBottom: '10px', paddingX: '16px',
            }}>

                <Typography sx={{ color: '#1E2947', fontFamily: 'PoppinsLight', fontSize: '18px', width: '100%' }}>{titulo}</Typography>
                <Typography color={'#88919E'} sx={{ fontFamily: 'PoppinsLight', marginTop: '12px', marginBottom: '10px' }}>{texto}</Typography>

                <Box
                    sx={{
                        backgroundColor: 'transparent', boxShadow: 'none', height: '48px', textTransform: 'uppercase',
                        textDecoration: 'underline',
                        fontSize: '14px',
                        fontFamily: 'PoppinsLight',
                        flexWrap: 'wrap',
                        color: '#4884E2',
                    }}

                >

                    Cuentame mas


                </Box>

                {/* <Button variant="contained"
                    sx={{
                        backgroundColor: 'transparent', boxShadow: 'none', height: '48px', textTransform: 'uppercase',
                        ":hover": {
                            backgroundColor: '#69C4DE'

                        },
                        fontSize: '14px',
                        fontFamily: 'PoppinsLight',
                        flexWrap: 'wrap',
                        color: '#4884E2',
                        
                    }}>Cuentame mas</Button> */}


            </Box>





        </Box>
    )
}

export default ConocerBox;