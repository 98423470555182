import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Typography } from '@mui/material';

import sbOpen from '../../../assets/intranet/SIDEBAR-ABRIR_52X48.svg';
import sbClose from '../../../assets/intranet/SIDEBAR-CERRAR_52X48.svg';

import homePasivo from '../../../assets/intranet/INICIO-PASIVO_68X56.svg';
import homeActivo from '../../../assets/intranet/INICIO-ACTIVO_68X56.svg';
import homeHover from '../../../assets/intranet/INICIO-HOVER_68X56.svg';

import pagoActivo from '../../../assets/intranet/PAGOS-ACTIVO_68X56.svg';
import pagoPasivo from '../../../assets/intranet/PAGOS-PASIVO_68X56.svg';
import pagoHover from '../../../assets/intranet/PAGOS-HOVER_68X56.svg';

import { SidebarContext } from '../SidebarContext';


function TemporaryDrawer() {


    const [showMenu, setShowMenu] = React.useState(false);


    const SidebarMenu = ({ }) => {




    }



    const { sidebarToggle, toggleSidebar, selectedIndexMenu, idSelectedIndexMenu } = React.useContext(SidebarContext);


    const handleListItemClick = (index) => {
        idSelectedIndexMenu(index)

    };
    const menuItems = [
        // {
        //   imgPasivo: inicioPasivo,
        //   imgHover: inicioHover,
        //   imgActivo: inicioActivo,
        //   descripcion: "Página de Inicio",
        //   rutaPrincipal: "/menu/comprobantes",
        //   opciones: [

        //   ]
        // },
        {
            imgPasivo: homePasivo,
            imgHover: homeHover,
            imgActivo: homeActivo,
            descripcion: "Pagina Principal",
            rutaPrincipal: "/intranet",
            opciones: [
                // { label: 'Submenú opción', link: '#' },
                // { label: 'Submenú opción', link: '#' },
            ]

        },
        {
            imgPasivo: pagoPasivo,
            imgHover: pagoHover,
            imgActivo: pagoActivo,
            descripcion: "Documentos de Pago",
            rutaPrincipal: "/intranet",
            opciones: [
            ]
        },

    ];

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
        setShowMenu(false);
    };


    const handleClick = () => {

    }

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '68px' }}
            role="presentation"
        >
            <Box
                sx={{
                    backgroundImage: `url(${sbClose})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '52px',
                    height: '48px',
                    marginBottom: '20px'
                }}


                onClick={toggleDrawer(anchor, false)}>


            </Box>



            {menuItems.map((item, index) => {
                return (
                    <Box key={item.descripcion} sx={{
                        padding: 0,
                        marginBottom: {
                            xs: '12px',
                            sm: '20px'
                        }
                    }}>
                        <Box
                            sx={{

                                width: '68px',
                                height: '56px',
                            }}
                            
                        >

                            <Box
                                sx={{
                                    backgroundImage: `url(${(selectedIndexMenu === index ? item?.imgActivo : item?.imgPasivo)})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    width: '68px',
                                    height: '56px',
                                    backgroundColor: '',
                                    display: ((selectedIndexMenu === index && showMenu) ? 'none' : 'block')
                                }}
                                onClick={() => {
                                    handleListItemClick(index);
                                    setShowMenu(true);
                                }}


                                className='icono'
                            >
                            </Box>

                            <Box
                                sx={{
                                    display: ((selectedIndexMenu === index && showMenu) ? 'flex' : 'none'),
                                    backgroundColor: '#E7EFFC',
                                    width: 'max-content',
                                    height: '56px',
                                    boxShadow: '0px 4px 14px 0px rgba(25, 67, 127, 0.16)',
                                    position: 'fixed',
                                    ':hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                onClick={toggleDrawer(anchor, false)}
                                className='hoverItem'
                            >

                                <img src={item?.imgHover} width={'68px'} height={'56px'}
                                    style={{

                                        backgroundColor: ''
                                    }}
                                />
                                <Typography
                                    sx={{
                                        color: '#2664C7',
                                        fontFamily: 'Poppins',
                                        width: 'auto',
                                        height: 'auto',
                                        fontSize: '16px',
                                        marginY: '16px',
                                        marginX: '20px',
                                        backgroundColor: ''
                                    }}

                                >{item?.descripcion}</Typography>
                            </Box>



                        </Box>

                    </Box>
                )
            })}



            {/* {menuItems.map((item, index) => (
                
            ))} */}

        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Box
                        sx={{
                            backgroundImage: `url(${sbOpen})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            width: '52px',
                            height: '48px',
                        }}


                        onClick={toggleDrawer(anchor, true)}>


                    </Box>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}

export default TemporaryDrawer;