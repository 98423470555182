import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import Solucion from "./Shared/Solucion";
import { Link } from "react-router-dom";
import PostBg from '../assets/post.jpg'
import CartaResumen from "./Shared/CartaResumen";
import ConocerBox from "./Shared/ConocerBox";
import '../assets/barranco.jpg'
import '../assets/hotel.jpg'
import CustomCarousel from "./Shared/CustomCarousel";

const ConocerMas = () => {

    const data = [1, 2, 3, 4, 5]

    return (
        <Box sx={{ width: '100%', height: '902px', backgroundColor: 'transparent', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingY: '60px' }}>
            <Box sx={{ width: '1200px', height: "880px", display: 'flex', alignItems: 'center', borderColor: '#000', borderWidth: '10px', flexDirection: 'column' }}>
                <Typography color={'#4884E2'} sx={{ fontSize: { xs: '24px', md: '36px' }, fontWeight: '500px', fontFamily: 'Gotham', marginBottom: '40px' }}>¡Tenemos mucho por contarte!</Typography>
                <Grid container sx={{ justifyContent: 'center', alignItems: 'center', marginBottom: '60px', marginTop: '40px' }} spacing={'20px'}>

                    <Grid item borderRadius={'60px 20px 20px 20px'}>
                        <ConocerBox titulo={'Las ventajas de un servicio eficaz y eficiente'} texto={'Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?'} textoBtn={'Una larga trayectoria'} imageUrl={'../../assets/barranco.jpg'} />
                    </Grid>

                    <Grid item borderRadius={'60px 20px 20px 20px'} sx={{

                        display: {
                            xs: 'none',
                            sm: 'block'
                        }
                    }}>
                        <ConocerBox titulo={'Las ventajas de un servicio eficaz y eficiente'} texto={'Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?'} textoBtn={'quienes somos'} imageUrl={'../../assets/hotel.jpg'} />
                    </Grid>
                    <Grid item borderRadius={'60px 20px 20px 20px'} sx={{

                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'block'
                        }
                    }}>
                        <ConocerBox titulo={'Las ventajas de un servicio eficaz y eficiente'} texto={'Llevamos más de 20 años en el mercado HORECA. ¿Quieres conocer nuestra historia?'} textoBtn={'lo que logramos'} imageUrl={'../../assets/hotel.jpg'} />
                    </Grid>


                </Grid>

                {/* <CustomCarousel component={Typography} content={data} /> */}

                <Button
                    sx={{
                        width: '320px',
                        height: '56px',
                        backgroundColor: '#42C67E',
                        borderRadius: '30px',
                        boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                        color: '#FFF',
                        fontFamily: 'PoppinsLight',
                        textTransform: 'uppercase',
                        ":hover": {

                            backgroundColor: '#79DD8B'

                        }
                    }}
                >
                    ¡quiero conocer más!
                </Button>

            </Box>

        </Box>
    )

};

export default ConocerMas;