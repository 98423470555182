import React, {useEffect} from 'react'
import { Box, Typography } from '@mui/material'
import ImageSoporte from '../assets/IMAGENSOPORTE.webp'


const Soporte = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box
                sx={{
                    width: '100%',
                    height: '300px',
                    backgroundColor: '#4884E2',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: { xs: '20px', sm: '330px', md: '150px' },

                }}
            >

                <Typography component={'h1'} color={'white'} sx={{ lineHeight: '68px', letterSpacing: '1.5px', fontSize: '60px', textAlign: 'left', fontFamily: 'PoppinsSemiBold' }}>
                    Soporte
                </Typography>

            </Box>

            <Box
                width={'100%'}

                display={'flex'}

                sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: { xs: '', md: 'center' },
                    alignItems: { xs: 'center', md: '' },
                    marginTop: '30px',
                }}
            >
                <Box


                    display={'flex'}

                    sx={{
                        flexDirection: 'column',
                        paddingTop: '30px',
                        width: { xs: '90%', md: '570px' },
                        height: '380px',

                        justifyContent: 'center',
                        alignItems: { xs: 'center', md: 'flex-start' }
                    }}
                >
                    <Typography

                        component={'p'}
                        color={'#232323'}
                        sx={{
                            fontFamily: 'Poppins',
                            lineHeight: '30px',

                        }}

                    >
                        Ponte en contacto con nosotros o crea un ticket de atención para recibir soporte en tu sistema INFOhotel®, INFOrest®, INFOback® o INFOfact®. Ofrecemos atención remota o presencial 24/7/365 exclusivamente para usuarios del servicio SCO.
                    </Typography>

                    <Box

                        onClick={() => console.log('On click')}
                        sx={{
                            backgroundColor: '#42C67E', borderRadius: '30px', padding: '18px', width: {
                                xs: '300px', md: '450px'
                            }, height: { xs: '80px', md: '56px' }, textTransform: 'uppercase',
                            ":hover": {
                                backgroundColor: '#79DD8B',
                                cursor: 'pointer'

                            },
                            fontFamily: 'PoppinsSemiBold',
                            textAlign: 'center',
                            color: 'white',
                            marginTop: '20px',
                            letterSpacing: '1px'
                        }}>Reglamento del servicio de emergencia</Box>
                </Box>

                <Box
                    sx={{
                        width: { xs: '80%', md: '474px' },
                        height: 'auto',
                        boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                        borderRadius: '20px',
                        padding: '30px',

                    }}

                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px'
                        }}
                    >

                        <Typography component={'h6'}

                            sx={{
                                color: '#1877F2',
                                fontFamily: 'Gotham',
                                letterSpacing: '1px',
                                fontSize: '28px'
                            }}

                        >
                            Atencion al Cliente
                        </Typography>


                        <Typography
                            component={'span'}
                            color={'#232323'}
                            sx={{
                                fontFamily: 'Poppins'
                            }}
                        >
                            Lunes a Viernes de 08:00 - 18:00
                        </Typography>
                        <Typography
                            component={'span'}
                            color={'#232323'}
                            sx={{
                                fontFamily: 'Poppins'
                            }}
                        >
                            (+01) 205-7550
                        </Typography>
                        <Typography component={'h6'}

                            sx={{
                                color: '#1877F2',
                                fontFamily: 'Gotham',
                                letterSpacing: '1px',
                                fontSize: '28px'
                            }}

                        >
                            Emergencias
                        </Typography>


                        <Typography
                            component={'span'}
                            color={'#232323'}
                            sx={{
                                fontFamily: 'Poppins'
                            }}
                        >
                            Lunes a Domingo 24 horas
                        </Typography>
                        <Typography
                            component={'span'}
                            color={'#232323'}
                            sx={{
                                fontFamily: 'Poppins'
                            }}
                        >
                            Software: 989 200 773
                        </Typography>
                        <Typography
                            component={'span'}
                            color={'#232323'}
                            sx={{
                                fontFamily: 'Poppins'
                            }}
                        >
                            Serv. Tecnico: 989 200 764
                        </Typography>
                    </Box>
                </Box>

            </Box>

            <Box
                width={'100%'}
                display={'flex'}

                sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // padding: '28px',
                    marginTop: '40px'

                }}
            >

                <Typography component={'h2'} color={'#1877F2'} sx={{ lineHeight: '68px', fontSize: { xs: '35px', md: '30px' }, fontFamily: 'PoppinsSemiBold', letterSpacing: '1px' }}>
                    Tickets de Servicio
                </Typography>

                <Typography
                    component={'p'}
                    color={'#232323'}
                    sx={{
                        fontFamily: 'PoppinsLight',
                        fontSize: '16px',
                        lineHeight: '26px',
                        width: { xs: '60%', md: '1050px' },
                        textAlign: 'justify'
                    }}
                >
                    Utilizamos la plataforma Zoho Desk para gestionar el servicio al cliente, separando las solicitudes e incidentes, aplicando acuerdos de servicio para mejorar el soporte y la continuidad operacional.
                </Typography>

                <Box

                    sx={{
                        width: { xs: '100%', md: '1020px' },
                        height: { xs: 'auto', md: '280px' },
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        paddingTop: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: { xs: '32px', md: '52px' },
                        marginTop: { xs: '40px', md: '0px' }
                    }}

                >

                    <Box
                        sx={{
                            width: { xs: '290px', md: '454px' },
                            height: { xs: '230px', md: '224px' },
                            backgroundColor: 'white',
                            boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                            borderRadius: '24px',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',

                        }}

                    >

                        <Typography component={'h3'} color={'#42C67E'} sx={{
                            fontSize: '28px',
                            fontFamily: 'Gotham'
                        }}>
                            Incidentes
                        </Typography>
                        <Typography
                            component={'p'} color={'#232323'}
                            sx={{
                                fontSize: '16px',
                                fontFamily: 'PoppinsLight',
                                textAlign: 'justify'
                            }}
                        >
                            Cualquier suceso que interrumpa o reduzca la calidad y productividad del usuario en nuestros servicios INFOhotel®, INFOrest®, INFOback® e INFOfact®.

                        </Typography>

                    </Box>
                    <Box



                        sx={{
                            width: { xs: '290px', md: '454px' },
                            height: { md: '224px' },
                            backgroundColor: 'white',
                            boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                            borderRadius: '20px',

                            padding: '20px'
                        }}

                    >

                        <Typography component={'h3'} color={'#42C67E'} sx={{
                            fontSize: '28px',
                            fontFamily: 'Gotham'
                        }}>
                            Solicitudes
                        </Typography>
                        <Typography
                            component={'p'} color={'#232323'}
                            sx={{
                                fontSize: '16px',
                                fontFamily: 'PoppinsLight',
                                textAlign: 'justify'
                            }}
                        >
                            Peticiones de servicio o productos adicionales: solicitudes de licencias adicionales, garantías, capacitaciones, reparaciones, nuevos equipos, entre otros.

                        </Typography>

                    </Box>


                </Box>
                <Box
                    sx={{
                        backgroundColor: '#42C67E', borderRadius: '30px', width: '300px', height: '56px',
                        ":hover": {
                            backgroundColor: '#79DD8B'

                        },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '60px'

                    }}>

                    <Typography
                        component={'span'}
                        sx={{
                            color: 'white',
                            fontFamily: 'Gotham',
                            textTransform: 'uppercase'
                        }}

                    >Ingresa al portal web</Typography>
                </Box>
            </Box>

            <Box
                width={'100%'}
                display={'flex'}

                sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '28px',
                    gap: '20px'
                }}
            >

                <Box

                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '90%', md:'530px'}
                    }}

                >
                    <Typography
                        component={'h3'}
                        sx={{
                            color: '#232323',
                            fontFamily: 'Gotham',
                            fontSize: '30px'
                            
                        }}
                    >
                        ¡Nos Actualizamos!
                    </Typography>
                    <Typography
                        component={'h3'}
                        sx={{
                            color: '#232323',
                            fontFamily: 'PoppinsLight',
                            fontSize: '16px',
                            textAlign: {xs: 'justify', md: 'left'}
                        }}
                    >
                        Nos renovamos para brindarte una mejor experiencia en nuestro portal de atención al cliente.
                    </Typography>

                </Box>

                <Box


                    sx={{
                        backgroundImage: `url(${ImageSoporte})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        width: {xs:'90%', md:'570px'},
                        height: {xs: '350px'  ,md: '434px'}
                    }}
                >

                </Box>


            </Box>

            <Box width={'100%'} height={'210px'} sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '60px',
                backgroundColor: '#4884E2',
                justifyContent: 'center',
                alignItems: 'center',
                paddingX: {
                    xs: '20px',
                    md: '0px'
                }

            }}>

                <Typography component={'h3'} sx={{
                    width: '570px',
                    color: 'white',
                    fontSize: { xs: '30px', md: '42px' },
                    fontFamily: 'Poppins'
                }}>
                    Conoce las soluciones que tenemos para ti
                </Typography>
                <Box
                    onClick={() => console.log('On click')}
                    sx={{
                        backgroundColor: '#42C67E', borderRadius: '30px', padding: '12px', width: '300px', height: '56px', textTransform: 'uppercase',
                        ":hover": {
                            backgroundColor: '#79DD8B',
                            cursor: 'pointer'

                        },
                        fontFamily: 'PoppinsLight',
                        textAlign: 'center',
                        color: 'white',
                        fontSize: { xs: '14px', md: '20px' }
                    }}>Ver todas las soluciones</Box>

            </Box>
        </Box>
    )
}

export default Soporte