import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import Solucion from "./Shared/Solucion";
import LogoInforest from '../assets/INFOREST.svg'
import LogoInfohotel from '../assets/INFOHOTEL.svg'
import LogoInfoback from '../assets/INFOBACK.svg'
import LogoInfofact from '../assets/INFOFACT.svg'
import LogoAMC from '../assets/AMC.svg'
import Bgsoluciones from '../assets/FONDOSOLUCIONES.webp'
import '../styles/Soluciones.css'

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




const Soluciones = () => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Box sx={{
                width: '100%', height: { xs: 'auto', lg: '1200px' },
                // backgroundImage: {xs: 'none',md:`url(${Bgsoluciones})`}, 
                display: 'flex', 
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundColor: { xs: '#E7EFFC', md: 'transparent' },
                marginTop: {
                    xs: '0px',
                    sm: '60px',
                    md: '0px'
                },
            }}>
                <Box sx={{ width: '100%', height: "auto", display: 'flex', alignItems: 'center', borderColor: '#000', borderWidth: '10px', flexDirection: 'column', paddingTop: { xs: '20px', md: '0px' } }}>
                    <Typography color={'#4884E2'} sx={{ fontSize: { xs: '24px', md: '36px' }, fontWeight: '500px', fontFamily: 'Gotham', textAlign: 'center', width: { xs: '320px', md: 'auto' } }}>Brindamos soluciones completas y eficientes</Typography>
                    <Typography color={'#88919E'} sx={{ fontSize: { xs: '18px', md: '32px' }, letterSpacing: '1px', fontWeight: '300px', marginBottom: '40px', fontFamily: 'PoppinsLight' }} >Encuentra el mejor servicio</Typography>

                    <Box
                        sx={{
                            width: '100%',
                           display: 'flex',
                           justifyContent: 'center',

                        }}

                    >
                        <Grid container sx={{ width: { xs: '90%', sm: '80%', md: '60%', lg: '60%', xl: '60%' }, justifyContent: 'center', alignItems: 'center'}} spacing={4}>

                            {/* <Grid item borderRadius={'60px 20px 20px 20px'}>
        <Solucion btnColorPasivo={'#42C67E'} btnColorHover={'#79DD8B'} logo={LogoInforest} />
    </Grid>
    <Grid item borderRadius={'60px 20px 20px 20px'}>
        <Solucion btnColorPasivo={'#4884E2'} btnColorHover={'#69C4DE'} logo={LogoInfohotel} />
    </Grid>
    <Grid item borderRadius={'60px 20px 20px 20px'}>
        <Solucion btnColorPasivo={'#80CFBC'} btnColorHover={'#69C4DE'} logo={LogoInfoback} />
    </Grid>
    <Grid item borderRadius={'60px 20px 20px 20px'}>
        <Solucion btnColorPasivo={'#F07B43'} btnColorHover={'#F5A458'} logo={LogoInfofact} />
    </Grid>
    <Grid item borderRadius={'60px 20px 20px 20px'}>
        <Solucion btnColorPasivo={'#4884E2'} btnColorHover={'#69C4DE'} logo={LogoAMC} />
    </Grid> */}
                            <Grid item borderRadius={'60px 20px 20px 20px'} sx={{

                            }} xs={12} sm={6} md={4} lg={4}>
                                <Solucion btnColorPasivo={'#42C67E'} btnColorHover={'#79DD8B'} logo={LogoInforest} />
                            </Grid>
                            <Grid item borderRadius={'60px 20px 20px 20px'} xs={12} sm={6} md={4} lg={4}>
                                <Solucion btnColorPasivo={'#4884E2'} btnColorHover={'#69C4DE'} logo={LogoInfohotel} />
                            </Grid>
                            <Grid item borderRadius={'60px 20px 20px 20px'} xs={12} sm={6} md={4} lg={4}>
                                <Solucion btnColorPasivo={'#80CFBC'} btnColorHover={'#69C4DE'} logo={LogoInfoback} />
                            </Grid>
                            <Grid item borderRadius={'60px 20px 20px 20px'} xs={12} sm={6} md={4} lg={4}>
                                <Solucion btnColorPasivo={'#F07B43'} btnColorHover={'#F5A458'} logo={LogoInfofact} />
                            </Grid>
                            <Grid item borderRadius={'60px 20px 20px 20px'} xs={12} sm={6} md={4} lg={4}>
                                <Solucion btnColorPasivo={'#4884E2'} btnColorHover={'#69C4DE'} logo={LogoAMC} />
                            </Grid>

                        </Grid>
                    </Box>


                    <Button variant="contained"
                        sx={{
                            backgroundColor: '#42C67E', borderRadius: '30px', padding: '12px 40px', width: { xs: '320px', md: '420px' }, height: '56px', textTransform: 'uppercase',
                            ":hover": {
                                backgroundColor: '#79DD8B'

                            },
                            color: 'white',
                            fontSize: { xs: '14px', md: '18px' },
                            boxShadow: 'none',
                            marginTop: {
                                xs: '28px',
                                lg: '60px'
                            }
                        }}>Descubre nuestros servicios</Button>
                </Box>

            </Box>

        </Box>
    )

};

export default Soluciones;