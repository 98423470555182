import React, { createContext, useState, useEffect } from 'react'


const LandingContext = createContext();

const LandingProvider = ({ children }) => {
    const [auth, setAuth] = useState('Token')

    return (
        <LandingContext.Provider

            value={{
                auth,
                setAuth
            }}
        >
            {children}
        </LandingContext.Provider>
    )

}

export {LandingContext, LandingProvider}