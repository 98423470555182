import React from "react";
import { Box, Typography, Button } from "@mui/material";
import BgProductos from '../assets/bgProductos.webp'

const Innovar = () => {
    return (
        <Box sx={{ width: '100%', height: { xs: '950px', sm: '900px',md:'1250px'}, display: 'flex', justifyContent: 'center', backgroundColor: '', paddingLeft: {md: '20px', lg: '30px'} }}>
            
            <Box sx={{
                marginTop: { xs: '532px', sm: '284px', md: '463px' }, marginLeft: { xs: '0px', md: '0px' }, width: { xs: '90%', md: '500px' },
                height: '276px', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingX: { xs: '10px', md: '0px' },
                zIndex: 1
            }}>
                <Box>
                    <Typography color={'#4884E2'} fontSize={'36px'} sx={{ width: '100%', fontSize: '36px', lineHeight: '44px', fontFamily: 'Gotham' }}>Optimizamos la gestion de tu negocio</Typography>
                    <Typography color={"#1E2947"} sx={{ marginTop: '20px', width: '100%', fontSize: '16px', fontFamily: 'PoppinsLight' }}>
                        En Infomática nos enfocamos en plantear soluciones expertas {" "}
                        <Typography component={'span'} sx={{ color: '#1E2947', fontSize: '16px', fontFamily: 'PoppinsBold' }}>
                            en productos de software y servicios
                        </Typography>

                        , para garantizar la excelencia de tu negocio en el mercado {" "}

                        <Typography component={'span'} sx={{ color: '#1E2947', fontSize: '16px', fontFamily: 'PoppinsBold' }}>
                            HORECA.
                        </Typography>
                    </Typography>



                </Box>
                <Button variant="contained"
                    sx={{
                        backgroundColor: '#42C67E', borderRadius: '30px', padding: '12px 40px', width: '300px', height: '56px', textTransform: 'uppercase',
                        ":hover": {
                            backgroundColor: '#79DD8B'

                        },
                        marginTop: '40px',
                        fontFamily: 'PoppinsLight'
                    }}

                >
                    ¡quiero innovar!
                </Button>

            </Box>


            
            <Box
                sx={
                    {
                        display: {xs: 'none', sm: 'none', md: 'block' },
                        width: {sm: '584px',md:'840px'},
                        height: {sm: '530px',md:'790px'},
                        marginTop: { sm: '0px', md: '412px'},
                        position: { sm:'absolute', md:'static'},
                        top: { sm: 1100, md:0},
                        bottom: 0,
                        left: { sm: 70,md:-170},
                        right: 0,
                       
                    }
                }

            >

                <img width={'100%'} height={'100%'} src={BgProductos}/>

            </Box>

            {/* <img width="840px" height="790px" src={BgProductos} style={{marginTop: '412px', position: 'relative', top: 0, bottom: 0, left: -170, right: 0}}/>  */}

        </Box>
    )
}

export default Innovar;