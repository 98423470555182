import Button from '@mui/material/Button'
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Image from 'react-image-webp';
import img from "./assets/headerBackground.webp";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home';
import Layout from './components/Shared/Layout';
import Nosotros from './pages/Nosotros';
import Productos from './pages/Productos';
import Soporte from './pages/Soporte';
import Inforest from './pages/Inforest';
import Infohotel from './pages/Infohotel';
import Infoback from './pages/Infoback';
import SolucionesPage from './pages/SolucionesPage';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LandingProvider } from './components/context/LandingContext';
import Pagos from './pages/Intranet/Pagos';
import IntranetLayout from './components/Shared/IntranetLayout';
import { SidebarProvider } from './components/context/SidebarContext';
import Inicio from './pages/Intranet/Inicio';
import ScrollToTop from './components/ScrollToTop';


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 640,
      md: 1280,
      lg: 1360,
      xl: 1920
    },
  },
});

function App() {
  return (
    <div style={{ backgroundColor: '#F8F9FC' }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <LandingProvider>
            <SidebarProvider>
            <Routes>
              <Route path='/' element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='/nosotros' element={<Nosotros />} />
                <Route path='/productos' element={<Productos />} />
                <Route path='/soluciones' element={<SolucionesPage />} />
                <Route path='/soporte' element={<Soporte />} />
                <Route path='/producto/software/inforest' element={<Inforest />} />
                <Route path='/producto/software/infohotel' element={<Infohotel />} />
                <Route path='/producto/software/infoback' element={<Infoback />} />
              </Route>

              <Route path='/intranet' element={<IntranetLayout />}>
                <Route index element={<Inicio />} />
                <Route path='/intranet/pagos' element={<Pagos />} />
              </Route>


            </Routes>
            </SidebarProvider>
          </LandingProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );

}

export default App;
