import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import Solucion from "./Shared/Solucion";
import { Link } from "react-router-dom";
import PostBg from '../assets/COSME.webp'
import RafaelBg from '../assets/RAFAEL.webp'
import LaMarBg from '../assets/LAMAR.webp'
import ElMercadoBg from '../assets/MERCADO.webp'

import { useTheme } from '@mui/material/styles';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import BannerSummum from '../assets/BannerSummum.webp'
import SummunTablet from '../assets/BannerSummunTablet.webp'
import SummunMovil from '../assets/BannerSummunMovil.webp'
import SummunLogo from '../assets/SUMMUM23_200X126.svg'

import CosmeLogo from '../assets/TESTIMONIOCOSME.webp'
import RafaelLogo from '../assets/TESTIMONIORAFAEL.webp'
import LaMarLogo from '../assets/TESTIMONIOLAMAR.webp'
import imagenCarousel2 from '../assets/TESTIMONIOLAMAR.webp'
import ElMercadoLogo from '../assets/TESTIMONIOELMERCADO.webp'
import Carousel from "react-material-ui-carousel";
import OpinionBox from "./OpinionBox";

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { ArrowForward } from "@mui/icons-material";



const images = [
    {
        imgPath: imagenCarousel2,
    },
    {
        imgPath: imagenCarousel2,
    },
    {
        imgPath: imagenCarousel2,
    },
    {
        imgPath: imagenCarousel2,
    },
];

const Posts = () => {




    const theme = useTheme();

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };



    const testimonios = [
        {
            testimonio: "Confiamos en INFOrest®. Tiene un sistema amigable tanto en el back como en el front office, es de fácil uso de nuestros colaboradores y administradores, es de mucha ayuda para la gestión y control del negocio.",
            persona: 'Daniel Rocca',
            vocacion: 'Director Gerente',
            empresa: 'Cosme',
            logo: CosmeLogo,
            BgLocal: PostBg
        },
        {
            testimonio: "Inforest es nuestra mejor herramienta de gestión, tanto el Front Office (Atención de nuestros clientes) como el Back (Permite un estricto control de nuestros costos e inventarios para cumplir con nuestro objetivo de mejorar continuamente la rentabilidad de nuestros negocios.)",
            persona: 'Marion Quirós',
            vocacion: 'Gerente de Administración y Finanzas',
            empresa: 'Rafael',
            logo: RafaelLogo,
            BgLocal: RafaelBg
        },
        {
            testimonio: "Desde nuestra apertura en el 2005, confiamos en INFOrest, han pasado ya 13 años de ininterrumpida labor y siempre ha estado a la altura de las exigencias de la información y control, tiene módulos muy potentes que nos hacen sentir cómodos y satisfechos.",
            persona: 'Jose Carpena',
            vocacion: 'Director Fundador',
            empresa: 'La Mar',
            logo: LaMarLogo,
            BgLocal: LaMarBg
        },
        {
            testimonio: "Inforest es nuestra mejor herramienta de gestión, tanto con el Front Office para la atención de nuestros clientes como en el Back, que nos permite tener un estricto control de nuestros costos e inventarios para cumplir con nuestro objetivo de mejorar continuamente la rentabilidad de nuestros negocios.",
            persona: 'Marion Quirós',
            vocacion: 'Gerente de Administración y Finanzas',
            empresa: 'El Mercado',
            logo: ElMercadoLogo,
            BgLocal: ElMercadoBg
        }


    ]



    const [postIndex, setPostIndex] = useState(0);

    return (
        <Box sx={{ width: '100%', height: 'max-content', backgroundColor: 'transparent', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingY: '60px' }}>
            <Box sx={{ width: '100%', height: "100%", display: 'flex', borderColor: '#000', borderWidth: '10px', flexDirection: 'column', backgroundColor: '', alignItems: 'center', }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: { xs: '28px', md: '40px' } }}>
                    <Typography color={'#4884E2'} sx={{ fontSize: { xs: '24px', md: '36px' }, fontWeight: '500px', fontFamily: 'Gotham', textAlign: 'center' }}>Nuestra experiencia se refleja en su éxito</Typography>
                    {/* <Typography color={'#88919E'} sx={{ fontSize: '20px', letterSpacing: '1px', fontWeight: '300px', marginBottom: '40px', fontFamily: 'PoppinsLight' }} >Celebramos el logro de nuestra familia</Typography> */}
                </Box>


                <Box

                    sx={
                        {
                            backgroundImage: {
                                xs: `url(${SummunMovil})`,
                                sm: `url(${SummunTablet})`,
                                md: `url(${BannerSummum})`
                            },
                            width: { xs: '90%', sm: '90%', md: '1200px' },
                            height: { xs: '520px', sm: '280px', md: '360px' },
                            borderRadius: '60px 20px',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            marginBottom: '80px',
                            paddingTop: { xs: '24px', sm: '24px', md: '40px' },
                            paddingLeft: { xs: '20px', sm: '20px', md: '40px' }
                        }
                    }
                >

                    <Box

                        sx={{
                            // backgroundColor: 'red',
                            width: { sm: '260px', md: '440px' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}

                    >

                        <Typography
                            component={'p'}
                            sx={{

                                width: { sm: '260px', md: '440px' },
                                height: 'auto',
                                color: '#1E2947',
                                fontSize: { sm: '14px', md: '16px' },
                                fontFamily: 'PoppinsLight',


                            }}
                        >
                            Felicitamos a nuestros clientes y agradecemos ser aliados del

                            <Typography component={'span'} sx={{ fontFamily: 'PoppinsSemiBold' }} >
                                {' '} 80%
                            </Typography>


                            {' '}
                            de los restaurantes destacados en el

                            <Typography component={'span'} sx={{ fontFamily: 'PoppinsSemiBold' }} >
                                {' '} Top 20
                            </Typography>

                            {' '}

                            de los premios:
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(${SummunLogo})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                marginTop: '24px',
                                // backgroundColor: 'red',
                                width: {
                                    xs: '140px',
                                    sm: '140xp',
                                    md: '200px'
                                },
                                height: {
                                    xs: '88px',
                                    sm: '88xp',
                                    md: '126px'
                                },
                            }}

                        >

                        </Box>

                        <Box
                            sx={{
                                width: {
                                    xs: '169px',
                                    sm: '169px',
                                    md: '188px'
                                },
                                paddingY: '12px',

                                display: 'flex',
                                flexDirection: 'row',
                                gap: '4px'
                            }}

                        >
                            <Typography component={'span'} sx={{
                                color: '#2664C7',
                                fontFamily: 'Poppins',
                                fontSize: {
                                    xs: '14px',
                                    md: '16px'
                                },

                                width: '100%',


                            }}>QUIERO SABER MAS</Typography>
                            {/* <Box sx={{
                                // backgroundColor: '#2664C7',
                                width: '20%'
                            }}>{'->'}</Box> */}
                        </Box>

                    </Box>



                </Box>

                <Box
                    sx={{
                        width: '100%',
                        // backgroundColor: 'red',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >


                    <Typography component={'h2'} sx={{
                        color: '#4884E2',
                        fontSize: { xs: '20px', md: '28px' },
                        // width: '519px',
                        height: '32px',
                        fontFamily: 'Gotham',
                        // backgroundColor: 'blue'

                    }}>Sus opiniones nos respaldan</Typography>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                         justifyContent: 'center',
                        // alignItems: 'center',
                        backgroundColor: '',


                    }}

                >


                    <OpinionBox testimonios={testimonios} />


                    <Box
                        sx={{
                            backgroundColor: '',
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'block'
                            },
                        }}
                    >

                        <Box
                            sx={{
                                width: '108px',
                                height: '48px',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '12px',
                                position: 'relative',
                                top: 350,
                                left: 1130,
                                zIndex: 9999
                            }}
                        >
                            <Box
                                sx={{
                                    width: '48px',
                                    height: '48px',
                                    backgroundColor: '#42C67E',
                                    borderRadius: '24px',
                                    color: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    ':hover': {
                                        backgroundColor: '#79DD8B',
                                        cursor: 'pointer'
                                    }
                                }}

                                onClick={() => {

                                    if (postIndex === 0) {
                                        setPostIndex(testimonios.length - 1)
                                    } else {
                                        setPostIndex(postIndex - 1)
                                    }
                                }}
                            >
                                <ArrowBackIosNewIcon style={{ color: 'white' }} />
                            </Box>
                            <Box
                                sx={{
                                    width: '48px',
                                    height: '48px',
                                    backgroundColor: '#42C67E',
                                    borderRadius: '24px',
                                    color: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    ':hover': {
                                        backgroundColor: '#79DD8B',
                                        cursor: 'pointer'
                                    }

                                }}
                                onClick={() => {
                                    if (postIndex === testimonios.length - 1) {
                                        setPostIndex(0)
                                    } else {
                                        setPostIndex(postIndex + 1)
                                    }
                                }

                                }
                            >
                                <ArrowForwardIosIcon sx={{ color: 'white' }} />
                            </Box>
                        </Box>


                        <Carousel
                            animation="fade"
                            index={postIndex}
                            next={(next, active) => {
                                console.log(`we left ${active}, and are now at ${next}`);
                                setPostIndex(next);
                            }}
                            prev={(prev, active) => {
                                setPostIndex(prev);
                                console.log(`we left ${active}, and are now at ${prev}`);
                            }}


                            autoPlay={true}
                            interval={15000}

                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'flex'
                                },
                                borderRadius: '20px 20px 60px 20px',
                                width: '1200px',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: '24px',

                            }}


                            navButtonsAlwaysInvisible
                            navButtonsProps={{
                                style: {
                                    alignItems: 'start',
                                    backgroundColor: '#42C67E',
                                    marginInline: '0',
                                    width: '48px',
                                    height: '48px',
                                    // marginTop: '360px',
                                    position: 'relative',
                                    color: 'white',
                                    // left: 400,
                                    // borderRadius: '24px',
                                    // display: 'flex',
                                    // flexDirection: 'column',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    // cursor: 'pointer',

                                }
                            }}

                            activeIndicatorIconButtonProps={{
                                style: {
                                    color: '#42C67E'
                                }
                            }}

                            indicatorContainerProps={{
                                style: {
                                    position: 'absolute',
                                    left: '300px',
                                    top: '370px',
                                }
                            }}

                            indicatorIconButtonProps={{
                                style: {
                                    height: '8px',
                                    fontWeight: '8px'
                                }
                            }}
                        >


                            {

                                testimonios.map((item, index) => (
                                    <>
                                        <Box
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'none',
                                                    md: 'flex'
                                                },
                                                borderRadius: '20px 20px 60px 20px',
                                                width: '1200px',
                                                height: '400px',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',

                                            }}

                                            key={index}
                                        >

                                            <Box sx={{ width: '620px', height: '280px', display: 'flex', flexDirection: 'column', gap: '12px', paddingX: '24px', paddingY: '36px', backgroundColor: 'white', borderRadius: '20px', boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)' }}>
                                                <Box sx={{}}>
                                                    <Typography component={"p"} sx={{ color: '#1E2947', fontFamily: 'PoppinsLight', fontSize: '16px', lineHeight: '25px' }}>{item.testimonio}</Typography>
                                                    <Box sx={{ marginTop: '8px' }}>
                                                        <Typography component={'p'} sx={{ color: '#1E2947', fontFamily: 'Gotham', fontSize: '24px', letterSpacing: '1px' }}>{item.persona}</Typography>
                                                        <Typography component={'p'} sx={{ color: '#88919E', fontFamily: 'PoppinsLight', fontSize: '18px', lineHeight: '22px', alignSelf: 'stretch' }}>{item.vocacion}</Typography>
                                                        <Typography component={'p'} sx={{ color: '#2A6EDB', fontFamily: 'PoppinsSemiBold', fontSize: '16px' }}>{item.empresa}</Typography>
                                                    </Box>

                                                </Box>

                                                <Box

                                                    sx={{
                                                        width: '180px',
                                                        height: '180px',
                                                        backgroundImage: `url(${item.logo})`,
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover',
                                                        position: 'absolute',
                                                        left: '420px',
                                                        top: '170px',
                                                        boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.20)',
                                                        borderRadius: '20px'
                                                    }}

                                                >

                                                </Box>

                                            </Box>

                                            <Box
                                                sx={
                                                    {
                                                        backgroundImage: `url(${item.BgLocal})`,
                                                        width: '540px',
                                                        height: '360px',
                                                        borderRadius: '60px 20px',
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover',
                                                    }
                                                }

                                            >





                                            </Box>

                                        </Box>


                                    </>

                                ))


                            }



                        </Carousel>
                    </Box>
                </Box>


                {/* <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents sx={{
                            borderRadius: '10px',
                            border: '1px solid var(--camposgris, #F2F2F2)',
                            boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                        }}
                    >
                        {images.map((step, index) => (
                            <div key={index} style={{
                                borderRadius: '10px',
                            }}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <Box
                                        component="img"
                                        sx={{
                                            height: '10%',
                                            display: 'block',
                                            // maxWidth: '100%',
                                            overflow: 'hidden',
                                            width: '10%',
                                            borderRadius: '10px',
                                            boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                                        }}
                                        src={step.imgPath}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews> */}



            </Box>

        </Box >
    )

};

export default Posts;