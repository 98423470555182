import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Typography, TextField, Slide } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import PasivoScroll from '../../assets/LOGIN-PC_260X90.svg';
import '../../styles/ModalStyles.css';
import { LandingContext } from '../context/LandingContext';


export default function ResponsiveDialog({ open, setOpen }) {

    const {setAuth} = React.useContext(LandingContext);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setAuth('Token');
        setOpen(false);
    };


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="right" ref={ref} {...props} />;
      });

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            PaperProps={
                {
                    style: {
                        borderRadius: '20px'
                    }
                }
            }
            keepMounted
        >

            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    // minWidth: '540px',
                    // minHeight: '588px',
                    paddingY: '60px',
                    paddingX: '40px',
                }}
                
            >

                <Box

                    sx={{
                        display: 'flex',
                        width: {

                            xs: '200px',
                            md: '260px'


                        },
                        height: {
                            xs: '69px',
                            md: '90px'
                        },
                        backgroundImage: `url(${PasivoScroll})`,
                        marginBottom: '40px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundColor: 'transparent',
                    }}
                >
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '32px',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                
                >

                <Typography
                    component={'h6'}
                    sx={{
                        color: '#2A6EDB',
                        fontFamily: 'Gotham',
                        fontSize: {
                            xs: '20px',
                            md: '24px'
                        },
                        
                    }}

                >
                    ¡Qué bueno tenerte de vuelta!
                </Typography>

                <Box

                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '32px',

                    }}

                >

                    <TextField

                        sx={{
                            width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)'
                        }} placeholder="Usuario"

                        inputProps={{
                            sx: {
                                fontFamily: 'PoppinsLight',
                                boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',

                            }
                        }}


                    />

                    <TextField

                        sx={{ width: '100%', boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)' }} placeholder="Contraseña"

                        inputProps={{
                            sx: {
                                fontFamily: 'PoppinsLight',
                                boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.08)',

                            }
                        }}

                    />

                    <Box onClick={handleClose} autoFocus
                        sx={{
                            textAlign: 'right',
                            color: '#4884E2',
                            fontFamily: 'PoppinsSemiBold',
                            transition: 'color 0.3s',
                            fontSize: {

                                xs: '14px',
                                md: '16px'
                            },
                            ':hover': {
                                cursor: 'pointer',
                                color: '#69C4DE'
                                
                            }
                        }}
                    >
                        ¿Olvidaste tu contraseña?
                    </Box>


                </Box>

                <Box
                    onClick={handleClose} autoFocus
                    sx={{
                        width: '280px',
                        height: '56px',
                        backgroundColor: '#4884E2',
                        borderRadius: '30px',
                        paddingX: '40px',
                        paddingY: '12px',
                        transition: 'background-color 0.3s',
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: '#69C4DE'
                        }
                    }}
                >
                    <Typography
                        component={'h6'}
                        sx={{
                            color: 'white',
                            fontFamily: 'Poppins',
                            textAlign: 'center',
                            width: '100%',
                            height: '100%',
                            
                        }}
                    >
                        Iniciar Sesion
                    </Typography>
                </Box>

                </Box>

                <Box
                    sx={{
                        width: '48px',
                        height: '48px',
                        padding: '12px',
                        borderRadius: '24px',
                        boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: '#69C4DE',
                            '.closeBtn': {
                                color: 'white'
                            }
                        }
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon 
                        className='closeBtn'
                    />
                </Box>

            </DialogContent>

        </Dialog>
    );
}