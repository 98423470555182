import React from "react";

import { Box, Typography, List, ListItem } from "@mui/material";

import ModuloImg from '../assets/CARTADIGITAL.webp'


const Modulo = ({infomodule}) => {

    const {description, items, image, imgWidth, imgHeight} = infomodule;



    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: {xs:'column', md:'row'},
                marginBottom: '30px',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    width: {xs: '70%' ,  md: `${imgWidth}`},
                    height: {xs: '350px' ,  md:`${imgHeight}`},
                    backgroundImage: `url(${image})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    marginBottom: '20px'
                }}
            >
            </Box>

            <Box
                sx={{
                    width: { xs: '80%', md: '570px'},
                    height: 'auto',
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: '30px'
                }}
            >
                <Typography
                    component={'h3'}
                    sx={{
                        color: '#42C67E',
                        fontFamily: 'PoppinsSemiBold',
                        fontSize: '26px'
                    }}
                >
                    {description}
                </Typography>

                <List
                    sx={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside'
                    }}
                >
                    {/* <ListItem sx={{ display: 'list-item' }}>
                        
                        </ListItem> */}


                    {items?.map((item, index) => (
                        <ListItem key={index} sx={{ display: 'list-item' }}>
                            <Typography 
                                component={'span'}
                                sx={{
                                    color: '#232323',
                                    fontFamily: 'PoppinsLight'
                                }}
                            
                            >{item}</Typography>
                        </ListItem>
                    ))}
                </List>

            </Box>

        </Box>
    )
}

export default Modulo