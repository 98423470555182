import React from "react";

import { Box, Typography, List, ListItem } from "@mui/material";

const DescripcionSolucion = ({ info }) => {


    const {image, description, items} = info;

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column',md:'row'},
            justifyContent: 'center',
            alignItems: 'center',
            gap: {xs: '30px',  md:'30px'}  

        }}>

            <Box
                sx={{
                    width: {xs:'80%' ,  md:'600px'},
                    height: {xs:'300px' ,  md:'400px'},
                    backgroundImage: `url(${image})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '24px'
                }}

            ></Box>
            <Box
                sx={{
                    width: {xs:'80%' ,  md:'600px'},
                    height: '400px',
                    borderRadius: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}

            >
                <Typography

                    color={'#232323'}

                    sx={{
                        fontFamily: 'PoppinsLight'
                    }}

                >
                    {description}
                </Typography>

                <List
                    sx={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside'
                    }}
                >
                    {/* <ListItem sx={{ display: 'list-item' }}>
                        
                        </ListItem> */}


                    {items?.map((item, index) => (
                        <ListItem key={index} sx={{ display: 'list-item' }}>
                            <Typography 
                                component={'span'}
                                sx={{
                                    color: '#232323',
                                    fontFamily: 'PoppinsLight'
                                }}
                            
                            >{item}</Typography>
                        </ListItem>
                    ))}
                </List>

            </Box>

        </Box>
    )
}


export default DescripcionSolucion