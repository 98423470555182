import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Logo from '../../assets/INFOREST.svg'
import Imagen from '../../assets/post.jpg'
import { useNavigate } from "react-router-dom";

const SolucionBox2 = ({ solucion }) => {

    const navigate = useNavigate()

    return (
        <Box
            sx={{
            
                height: {xs: '492px'},
                borderRadius: '60px 20px',
                boxShadow: '0px 4px 24px 0px rgba(25, 67, 127, 0.10)',
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: { xs: 'column', md:'row'},
                justifyContent: 'space-between',
                ':hover': {
                    '.bgCapacitacion': {
                        boxShadow: "none",
                    },
                    '.textCapacitacion': {
                        display: "none"
                    }
                },
                
            }}
            
        >

            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', width: {xs: '100%', md:'600px'}, paddingX: {xs: '20px', md:'40px'}, paddingBottom: { xs: '20px', md:'40px'}, paddingTop: {xs: '28px', md:'60px'}, justifyContent: 'center', alignItems: 'center' }}>

                <Box
                    sx={{
                        width: {xs:'212px', md:'280px'},
                        height: {xs: '40px', md:'60px'},
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '24px',

                    }}
                >
                    <img width={'100%'} height={'100%'} src={solucion?.logo}></img>
                </Box>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component={'p'}

                        sx={{
                            color: '#1E2947',
                            fontFamily: 'PoppinsLight',
                            fontSize: {xs: '14px',  md:'16px'},
                            lineHeight: '24px',
                            width: {  xs: '280px', md: '520px'},
                            height: 'auto',
                        }}
                    >
                        200 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit. Aenean ligula eget dolor. Aenean massa cum sociis natoque penatibus et magnis dis pamonte, nascetur ridiculus mus. Donec quo.
                    </Typography>
                    <Button variant="contained"
                        onClick={() => navigate(solucion?.ruta)}
                        sx={{
                            backgroundColor: `${solucion?.pasivo}`, borderRadius: '30px', padding: '12px 40px', width: {xs: '220px', md:'240px'}, height: {xs: '48px' , md:'56px'}, textTransform: 'uppercase',
                            ":hover": {
                                backgroundColor: `${solucion?.activo}`
                            },
                            ButtonShadow: 'none',
                            fontSize: '14px',
                            paddingX: '24px',
                            paddingY: '12px',
                            fontFamily: 'PoppinsLight',
                            alignSelf: 'center',
                            marginTop: '24px'
                        }}>Me interesa</Button>
                </Box>

            </Box>

            <Box
                className='bgCapacitacion'
                sx={{
                    backgroundImage: `url(${solucion?.imagen})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: {xs: '0px 0px 20px 20px ', md:'60px 20px'},
                    boxShadow: 'inset 0 0 0 1000px rgba(30, 41, 71, 0.4)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                }}
            >


            </Box>







        </Box>
    )

}

export default SolucionBox2;