import * as React from 'react';
import { useState, useContext } from "react";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
// import {saveAs} from 'file-saver'
import { ConsultDocumentRecep } from '../../../models/models/consultaDocumento';
import { Checkbox } from '@mui/material';
import DialogScreen from './DialogScreen';
// import CSS from 'csst'
import CSS from 'csstype';
// import { makeStyles } from '@mui/styles';
// import { createStyles, lighten, Theme } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';

import { ConsultaDetalleDocumento } from '../../../models/models/consultaDocumento';
import { BajaDocumento } from '../../../models/models/bajaDocumento';

// import { DialogContent } from '@material-ui/core';
import { Box, CardContent, DialogTitle, Grid, Icon, IconButton, Stack, Card, Pagination, Container } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useNavigate } from 'react-router-dom';

import pdf_pasivo from '../../../assets/intranet/PDF-PASIVO_48X48.svg'
import pdf_activo from '../../../assets/intranet/PDF-PASIVO_48X48.svg'

import xml_pasivo from '../../../assets/intranet/XML-PASIVO_48X48.svg'
import xml_activo from '../../../assets/intranet/XML-HOVER_48X48.svg'

import cdr_pasivo from '../../../assets/intranet/CDR-PASIVO_48X48.svg'
import cdr_activo from '../../../assets/intranet/CDR-HOVER_48X48.svg'

import ActivoVisible from '../../../assets/intranet/VER_24X24.svg';

import cerrar_pasivo from '../../../assets/CERRAR SESION-PASIVO_24X24.svg';
import cerrar_activo from '../../../assets/CERRAR SESION-PASIVO_24X24.svg';
import { Buffer } from "buffer";
import EnhancedTableHead from './EnhancedTableHead';

import DetailTable from './DetailTable';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



const headCellsBajasFactura: any =
    [
        { id: 'Id', numeric: false, disablePadding: false, label: 'Id', width: '20px', left: '10px', visible: false },
        { id: 'tipodocumento', numeric: true, disablePadding: true, label: 'Documento', width: '100px', left: '0px', visible: true },
        { id: 'Serie', numeric: true, disablePadding: true, label: 'Serie - Número', width: '100px', left: '0px', visible: true },
        { id: 'FechaEmision', numeric: false, disablePadding: false, label: 'fecha emision', width: '100px', left: '0px', visible: true },
        { id: 'MotivoBaja', numeric: true, disablePadding: false, label: 'Motivo', width: '100px', left: '0px', visible: true },

    ];


const headCellsBajasBoleta: any =
    [
        { id: 'Id', numeric: false, disablePadding: false, label: 'Id', width: '100px', left: '10px', visible: false },
        { id: 'tipodocumento', numeric: true, disablePadding: true, label: 'Documento', width: '100px', left: '0px', visible: true },
        { id: 'Serie', numeric: true, disablePadding: false, label: 'Serie', width: '100px', left: '0px', visible: true },
        { id: 'CorrelativoInicio', numeric: true, disablePadding: true, label: 'Correlativo Inicio', width: '100px', left: '0px', visible: true },
        { id: 'CorrelativoFin', numeric: false, disablePadding: false, label: 'Correlativo Fin', width: '100px', left: '0px', visible: true },
        { id: 'Moneda', numeric: true, disablePadding: false, label: 'Moneda', width: '100px', left: '0px', visible: true },
        { id: 'Gravadas', numeric: true, disablePadding: false, label: 'Op. Gravadas', width: '100px', left: '0px', visible: true },
        { id: 'TotalIgv', numeric: false, disablePadding: false, label: 'IGV', width: '100px', left: '0px', visible: true },
        { id: 'Exoneradas', numeric: false, disablePadding: false, label: 'Monto Exonerado', width: '100px', left: '0px', visible: true },
        { id: 'TotalOtrosImpuestos', numeric: false, disablePadding: false, label: 'Op. No Gravadas', width: '100px', left: '0px', visible: true },
        { id: 'Exportacion', numeric: false, disablePadding: false, label: 'Op. Exportación', width: '100px', left: '0px', visible: true },
        { id: 'Gratuitas', numeric: false, disablePadding: false, label: 'Op. Gratuita', width: '100px', left: '0px', visible: true },
        { id: 'TotalVenta', numeric: false, disablePadding: false, label: 'Importe Total', width: '100px', left: '0px', visible: true },
        { id: 'FechaEmision', numeric: false, disablePadding: false, label: 'fecha emision', width: '100px', left: '0px', visible: true },

    ];



const TableCellColumn: CSS.Properties = {
    padding: '0px',
    height: '44px',
    paddingRight: '10px',
    paddingLeft: '10px',
    textTransform: 'capitalize',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily: 'Poppins'
}


type Order = 'asc' | 'desc';

interface Props {
    datos: any[],
    page: number,
    rowsPerPage: number,
    setCantPage: (newValue: number) => void,
    headCells: any,
    menu: string
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


const TablaMenu = ({ datos, page, rowsPerPage, setCantPage, headCells, menu }: Props) => {


    const classes = {
        root: {
            width: '100%',
            paddingLeft: {
                xs: '0px',
                sm: '20px',
                md: '88px'
            },
            paddingRight: {
                xs: '0px',
                sm: '20px',
            },

        },
        paper: {
            width: '100%',
            marginBottom: '12px',
        },
        table: {
            minWidth: '50px !important',
            marginBottom: '12px',
        },

        tableCell: {
            padding: '0px',
            height: '10px',
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
        },
        tablerow: {

        },

        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 10,
            width: 1,
        },
        imageIcon: {
            height: '24px',
        },
        iconRoot: {
            textAlign: 'center'
        },

        card: {

        }

    }

    // const useStyles = makeStyles((theme: Theme) =>
    //     createStyles({
    //         root: {
    //             width: '100%',
    //         },
    //         paper: {
    //             width: '100%',
    //             marginBottom: theme.spacing(1),
    //         },
    //         table: {
    //             minWidth: '50px !important',
    //             marginBottom: theme.spacing(1),
    //         },

    //         tableCell: {
    //             padding: '0px',
    //             height: '10px',
    //             display: 'flex',
    //             justifyItems: 'center',
    //             alignItems: 'center',
    //         },
    //         tablerow: {

    //         },

    //         visuallyHidden: {
    //             border: 0,
    //             clip: 'rect(0 0 0 0)',
    //             height: 1,
    //             margin: -1,
    //             overflow: 'hidden',
    //             padding: 0,
    //             position: 'absolute',
    //             top: 10,
    //             width: 1,
    //         },
    //         imageIcon: {
    //             height: '24px',
    //         },
    //         iconRoot: {
    //             textAlign: 'center'
    //         },

    //         card: {

    //         }
    //     }),
    // );

    // const classes = useStyles();
    // const { token, idToken, idRuta, idResponseLogin, loading, idLoading } = useContext(TokenContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof ConsultDocumentRecep>('documento');
    const [selected, setSelected] = React.useState<readonly any[]>([]);
    const [dense, setDense] = React.useState(false);
    const rows = datos;

    const [openModal, setOpenModal] = useState(false);

    setCantPage(Math.ceil(rows?.length / rowsPerPage));
    // setCantPage(5)
    const [pdf_documento, setpdfDoc] = useState<ConsultaDetalleDocumento>();
    const [bajaDoc, setBajaDoc] = useState<ConsultaDetalleDocumento>();
    const [jsonBajaDoc, setJsonBajaDoc] = useState<BajaDocumento>();
    const [isShown, setIsShown] = useState(false);
    const [isShown2, setIsShown2] = useState(false);
    const [isShown3, setIsShown3] = useState(false);
    const [isShown4, setIsShown4] = useState(false);
    const [isShown5, setIsShown5] = useState(false);
    const navigate = useNavigate();
    const [rowsPerPageDet, setRowsPerPageDet] = useState(15);
    const [pageDet, setPageDet] = useState(0);
    const [cantPageDet, setCantPageDet] = useState(0);


    const handleChangePageDet = (event: unknown, newPage: number) => {
        setPageDet(newPage - 1);

    };


    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            console.log(newSelected);

            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: any) => {

        console.log(name.toString());

        
        const arrayOfIds = selected?.map(e => e.id);

        // console.log(arrayOfIds);
        
        const selectedIndex = arrayOfIds.indexOf(name)
        
        console.log(selectedIndex);
        

        // console.log(selectedIndex);

        
        let newSelected: readonly any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            console.log(newSelected);
            
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        console.log(newSelected);
        setSelected(newSelected);
    };


    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ConsultDocumentRecep) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

    const handleOpen = (iddocumento: string) => {
        // idLoading(true);
        // idToken(localStorage.getItem("token"));
        // const decodeToken: any = Buffer.from(token, 'base64').toString('binary');  // decodificamos   
        // const validaContenido: any = decodeToken.includes('"exp":')

        // if (validaContenido === true) {
        //     const segundos: any = decodeToken.split('"exp":', 2)[1].split(',"iss"', 1);  // obtenemos los m..segundos

        //     const fechaValid = dayjs(segundos * 1000).format("YYYY-MM-DD");
        //     // const fechaValid = '2023-07-20';             
        //     const hoy = new Date().toLocaleDateString('sv');

        //     if (fechaValid >= hoy) {
        //         if (menu === 'comprobantes') {
        //             fetchPDF(iddocumento);
        //         }

        //         if (menu === 'resumenBajas') {
        //             fetchBajas(iddocumento);
        //         }

        //     }
        //     else {

        //         idRuta(undefined);
        //         idToken(undefined);
        //         idResponseLogin(undefined);
        //         return navigate('/');

        //     }

        // }


        setIsShown5(false);
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const fetchPDF = async (iddocumento: string) => {
        try {
            // const lstPDF = await getDetalleDocumento(iddocumento, token);

            // if (lstPDF !== undefined) {
            //     const DocPDF: ConsultaDetalleDocumento = JSON.parse(JSON.stringify(lstPDF!.data));
            //     setpdfDoc(DocPDF);
            //     // idLoading(false)
            //     setOpenModal(true);
            // }
        } catch (error: any) {
            const response = error.message;
            console.log('fetchPDF', response);
        }
    };

    const fetchBajas = async (iddocumento: string) => {
        try {
            // // const lstResumenBajas = await getDetalleResumenBaja(iddocumento, token);
            // if (lstResumenBajas !== undefined) {
            //     const DocResumenBajas: ConsultaDetalleDocumento = JSON.parse(JSON.stringify(lstResumenBajas!.data));
            //     // const BajaDocumento: BajaDocumento = JSON.parse(DocResumenBajas[0].jsondoc);
            //     // console.log(JSON.parse(JSON.stringify(BajaDocumento.Bajas.jsondoc)));                     
            //     setBajaDoc(DocResumenBajas[0]);
            //     setJsonBajaDoc(BajaDocumento);
            //     idLoading(false)
            //     setOpenModal(true);
            // }
        } catch (error: any) {
            const response = error.message;
            console.log('fetchBajas', response);
        }
    };


    const handleDescargar = (tipo: string) => {
        var ex: string;
        var base64String: string;
        var nombreArchivo: string;
        if (tipo == '1') {
            tipo = 'application/pdf';
            ex = '.pdf';
            // if (menu === 'comprobantes') {
            //     base64String = pdf_documento.pdf;
            //     nombreArchivo = pdf_documento.documento + ex;
            // }

        }
        else if (tipo == '2') {
            tipo = 'application/xml';
            ex = '.xml';

            // if (menu === 'comprobantes') {
            //     base64String = pdf_documento.xml;
            //     nombreArchivo = pdf_documento.documento + ex;
            // }

            // if (menu === 'resumenBajas') {
            //     base64String = bajaDoc.xml;
            //     nombreArchivo = bajaDoc.documento + ex;
            // }



        }

        else if (tipo == '3') {
            tipo = 'application/zip';
            ex = '.zip';

            if (menu === 'comprobantes') {
                // base64String = pdf_documento.cdr;
                // nombreArchivo = pdf_documento.documento + ex;
            }
        }

        // descargarArchivo(base64String, nombreArchivo, tipo);
    };


    function descargarArchivo(base64String: string, nombreArchivo: string, tipo: string) {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters?.length);

        for (let i = 0; i < byteCharacters?.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: tipo });
        // saveAs(blob, nombreArchivo);
    }



    function BootstrapDialogTitle(props: DialogTitleProps) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 20,
                            top: 20,
                            borderRadius: 100,
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}
                        onMouseEnter={() => setIsShown5(true)}
                        onMouseLeave={() => setIsShown5(false)}
                    >
                        <Icon style={{
                            boxShadow: '6px 6px 20px 0px rgba(25, 67, 127, 0.12)', background: isShown5 == true ? 'var(--colorSecondary-Green)' : '#ffffff',
                            borderRadius: 100, width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <img style={classes.imageIcon} src={isShown5 == true ? cerrar_activo : cerrar_pasivo} />
                        </Icon>
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    return (
        <React.Fragment>
            <Box sx={classes.root} >

                <GlobalStyles
                    styles={{
                        '&::-webkit-scrollbar': {
                            width: '0.4em',
                            height: '0.5em',
                        },
                        '&::-webkit-scrollbar-track': {
                            display: 'flex',
                            marginTop: '20px',
                            boxShadow: 'inset 0 0 6px #E3E3E3',
                            webkitBoxShadow: 'inset 0 0 6px #E3E3E3',


                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#E7EFFC',
                            outline: '1px solid var(--colorHoverText-Green)',
                            borderRadius: '14px',
                        }
                    }}
                />

                <TableContainer  sx={{ maxHeight: 500, overflow: 'auto', borderRadius: '8px', borderColor: '#B5CEF3', borderWidth: '1px', borderStyle: 'solid' }}>
                    <Table sx={classes.table} aria-labelledby="tableTitle" stickyHeader aria-label="sticky table" >
                        {/* <Table  aria-labelledby="tableTitle" stickyHeader aria-label="sticky table" > */}

                        <EnhancedTableHead
                            numSelected={selected.length}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={() => handleRequestSort}
                            headCellsp={headCells}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id.toString());
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            onClick={(event) => handleClick(event, row.id.toString())}
                                            onMouseEnter={() => setIsShown(true)}
                                            onMouseLeave={() => setIsShown(false)}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: `#E7EFFC`,
                                                    "& .MuiTableCell-root": {
                                                        "&.columnaResaltada": {
                                                            color: 'var(--colorHoverText-Green)',
                                                            cursor: 'pointer',
                                                        }
                                                    },
                                                }
                                            }}  >

                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell id={labelId} onClick={() => { handleOpen(row.id.toString()) }}
                                                className={'columnaResaltada'} scope="row" style={TableCellColumn}
                                                sx={{
                                                    display: 'flex',
                                                    justifyItems: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img src={ActivoVisible}
                                                    style={{ padding: '10px' }} />

                                                <Typography
                                                    sx={{
                                                        textDecoration: 'none',
                                                        background: 'linear-gradient(currentColor, currentColor) bottom / 0 .1em no-repeat',
                                                        transition: '0.5s background-size',
                                                        textTransform: 'uppercase',
                                                        fontFamily: 'Poppins',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        lineHeight: '18px',
                                                        backgroundPosition: 'left bottom',
                                                        '&:hover':
                                                        {
                                                            backgroundSize: '100% .1em',
                                                            backgroundPosition: 'left bottom',
                                                            color: '#2A6EDB',
                                                        }
                                                    }}
                                                >{row.documento}
                                                </Typography>
                                            </TableCell>

                                            <TableCell style={TableCellColumn}>
                                                {row.receptor}
                                            </TableCell>

                                            <TableCell id={labelId} className={'columnaResaltada'}
                                                onClick={() => { handleOpen(row.id.toString()) }}
                                                scope="row" style={{ height: '44px', padding: '0px', }}
                                                sx={{
                                                }}>


                                                <Typography sx={{
                                                    fontFamily: 'Poppins',
                                                    paddingX: '10px',
                                                    // fontWeight: 500,
                                                    fontSize: '14px',
                                                    lineHeight: '18px',
                                                    textDecoration: 'none',
                                                    background: 'linear-gradient(currentColor, currentColor) bottom / 0 .1em no-repeat',
                                                    transition: '0.5s background-size',
                                                    backgroundPosition: 'left bottom',
                                                    // '&:hover':
                                                    // {
                                                    //     backgroundSize: '100% .1em',
                                                    //     backgroundPosition: 'left bottom',
                                                    //     color: 'var(--colorHoverText-Green)'
                                                    // }
                                                }}>{'SAN JUAN DE MIRAFLORES'}
                                                </Typography>
                                            </TableCell>

                                            <TableCell style={TableCellColumn}>
                                                {row.tipodocumento.toString().charAt(0).toUpperCase() + row.tipodocumento.toString().slice(1).toLocaleLowerCase()}

                                            </TableCell>

                                            <TableCell style={TableCellColumn} sx={{ textAlign: 'left' }}>
                                                {row.fechaemision}</TableCell>


                                            <TableCell style={TableCellColumn} sx={{ textAlign: 'center' }}>
                                                {(row.moneda === 'PEN' ? 's/ ' : '$ ') + parseFloat(row.totalventa.toString()).toFixed(2)}
                                            </TableCell>


                                            <TableCell style={TableCellColumn} sx={{ fontFamily: 'Poppins' }}>{row.observacion}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{ height: (dense ? 33 : 53) * emptyRows, }}  >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* { 
                    <DialogScreen open={openModal} handleClose={handleClose} paper={{
                        sx: {
                            borderRadius: '10px',
                            minWidth: { xs: '340px', sm: '400px', md: '500px', lg: '880px', xl: '880px' },
                            maxWidth: { xs: '340px', sm: '400px', md: '600px', lg: '880px', xl: '880px' }
                        }
                    }} direction={'down'}
                        contentOfDialog={
                                (<DialogContent style={{ padding: 0 }} >
                                    <BootstrapDialogTitle id="title-tabla" onClose={handleClose}>
                                    </BootstrapDialogTitle>
                                    <Box sx={{
                                        height: { xs: '154px', sm: '154px', md: '120px', lg: '120px', xl: '120px' },
                                        width: '100%',
                                        backgroundColor: 'var(--colorHoverText-Green)',
                                        color: 'white',
                                        paddingTop: { xs: '20px', sm: '20px', md: '40px', lg: '40px', xl: '40px' },
                                        paddingLeft: { xs: '20px', sm: '20px', md: '40px', lg: '40px', xl: '40px' },

                                    }}>
                                        <Box sx={{ width: { xs: '240px', sm: '240px', md: '100%', lg: '100%', xl: '100%' } }}>
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '20px', sm: '20px', md: '24px', lg: '24px', xl: '24px' },
                                                    fontFamily: 'Gotham',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: '26px'
                                                }}
                                            >
                                                {
                                                    (pdf_documento !== undefined) ? 'Detalle de comprobante ' + pdf_documento.documento : ''
                                                }

                                            </Typography>
                                        </Box>

                                        <Card sx={{
                                            height: '56px',
                                            borderRadius: '40px',
                                            float: 'left',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: { xs: '24px', sm: '24px', md: '26px', lg: '26px', xl: '26px' },
                                        }}>
                                            <CardContent sx={{
                                                padding: 0,
                                                paddingTop: '12px',
                                                paddingBottom: '12px !important',
                                                paddingLeft: '28px',
                                                paddingRight: '28px'
                                            }}>
                                                <Stack direction="row">
                                                    <img onMouseEnter={() => setIsShown(true)}
                                                        onMouseLeave={() => setIsShown(false)}
                                                        onClick={() => { handleDescargar('1') }}
                                                        src={isShown == true ? pdf_activo : pdf_pasivo}
                                                        style={{ paddingRight: '24px' }}
                                                    />
                                                    <img onMouseEnter={() => setIsShown2(true)}
                                                        onMouseLeave={() => setIsShown2(false)}
                                                        onClick={() => { handleDescargar('2') }}
                                                        src={isShown2 == true ? xml_activo : xml_pasivo}
                                                        style={{ paddingRight: '24px' }}
                                                    />
                                                    <img onMouseEnter={() => setIsShown3(true)} onMouseLeave={() => setIsShown3(false)}
                                                        onClick={() => { handleDescargar('3') }}
                                                        src={isShown3 == true ? cdr_activo : cdr_pasivo} />

                                                </Stack>
                                            </CardContent>
                                        </Card>

                                    </Box>
                                    <Box sx={{

                                        width: '100%',
                                        paddingTop: '36px',
                                        paddingBottom: '36px',
                                        paddingLeft: { xs: '20px', sm: '20px', md: '40px', lg: '40px', xl: '40px' },

                                    }}>
                                        <Typography
                                            sx={{
                                                width: '200px',
                                                color: 'var(--colorPrimary-Gray)',
                                                fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
                                                fontFamily: 'Poppins',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: { xs: '14px', sm: '14px', md: '16px', lg: '16px', xl: '16px' },
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Descargar
                                        </Typography>

                                    </Box>

                                    <Box sx={{
                                        display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex' },
                                        paddingLeft: { xs: '20px', sm: '20px', md: '40px', lg: '40px', xl: '40px' },
                                        paddingRight: { xs: '20px', sm: '20px', md: '40px', lg: '40px', xl: '40px' },
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        {(pdf_documento !== undefined) ?
                                            <embed src={`data:application/pdf;base64,${pdf_documento.pdf}`}

                                                type="application/pdf"
                                                style={{
                                                    height: '60vh', width: '100vh', display: 'flex',
                                                    justifyContent: 'center', alignItems: 'center'
                                                }} />
                                            :
                                            'Hubo un error!'}
                                    </Box>
                                </DialogContent>)
                        }
                    />
                } */}

            </Box>
        </React.Fragment>
    );

}

export default TablaMenu;



