import React from "react";
import { Box, Typography } from "@mui/material";
import Boleta from '../../../assets/intranet/DOC_24X24.svg'

const BoxCantidad = ({ label, valor }) => {
    return (
        <Box
            sx={{
                paddingX: '24px',
                paddingY: '28px',
                backgroundColor: '#E7EFFC',
                display: 'flex', 
                flex: 1,
                maxHeight: '98px',
                alignItems: 'center',
                gap: '16px'
            }}
        >

            <Box
                sx={{
                    backgroundImage: `url(${Boleta})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '24px',
                    height: '24px'
                }}
            >

            </Box>

            <Box>
                <Typography sx={{
                    color: '#88919E',
                    fontSize: '14px',
                    fontFamily: 'Poppins'
                }}>Comprobantes</Typography>
                <Typography sx={{
                    color: '#4884E2',
                    fontSize: '20px',
                    fontFamily: 'Gotham'
                }}>{`${valor} ${label}`}</Typography>
            </Box>


        </Box>
    )
}


export default BoxCantidad