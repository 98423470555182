import React from "react";
import { Box, Button, Typography } from "@mui/material";
import NosotrosBg from '../../assets/solucion1.jpg'
import '../../styles/Soluciones.css'

const CartaResumen = ({ titulo, texto, textoBtn, imageUrl }) => {
    return (
        <Box className='solucionBg'
            sx={
                {
                    width: '300px',
                    height: '420px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#FFF',
                    borderRadius: '60px 20px 20px 20px',
                    
                    ":hover": {
                        ".resumenImage": {
                            boxShadow: 'none',
                            
                        },

                        ".titulo": {
                            display: 'none'
                        }
                    }
                }}>
            <Box className="resumenImage" sx={
                {
                    width: '100%', height: '260px', backgroundImage: `url(${imageUrl})`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'inset 0 0 0 1000px rgba(30, 41, 71, 0.4)',
                    borderRadius: '60px 20px 20px 20px',

                    transition: 'box-shadow 0.5s',
                    
                }
            }>

                <Typography className="titulo" sx={{ color: '#FFF', background: 'none', textAlign: 'center', textShadow: '0px 2px 2px rgba(35, 35, 35, 0.25)', fontFamily: 'GothamLight', fontSize: '24px', width: '184px' }}>{titulo}</Typography>

            </Box>
            <Typography color={'#1E2947'} sx={{ paddingY: '20px', paddingX: '16px', fontFamily: 'PoppinsLight' }}>{texto}</Typography>

            <Box display={'flex'} justifyContent={'flex-end'} sx={{ width: '100%' }}>

                <Button variant="contained"
                    sx={{
                        backgroundColor: '#4884E2', borderRadius: '20px 0px', padding: '12px 40px', height: '48px', textTransform: 'uppercase',
                        ":hover": {
                            backgroundColor: '#69C4DE'

                        },
                        fontSize: '14px',
                        paddingX: '24px',
                        paddingY: '12px',
                        fontFamily: 'PoppinsLight',
                        flexWrap: 'wrap'
                    }}>{textoBtn}</Button>

            </Box>



        </Box>
    )
}

export default CartaResumen;