
import * as React from 'react';
import CSS from 'csstype';
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ConsultDocumentRecep } from '../../../models/models/consultaDocumento';
import { BajasBoleta, BajasFactura } from '../../../models/models/bajaDocumento';
import { Checkbox } from '@mui/material';

const TableRowColumn: CSS.Properties = {
    padding: '10px',
    height: '48px',
}

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             width: '100%',
//         },
//         paper: {
//             width: '100%',
//             marginBottom: theme.spacing(1),
//         },
//         table: {
//             minWidth: 1200,
//             marginBottom: theme.spacing(1),
//         },

//         tableCell: {
//             padding: '0px',
//             height: '10px',
//             display: 'flex',
//             justifyItems: 'center',
//             alignItems: 'center',
//         },
//         tablerow: {

//         },

//         visuallyHidden: {
//             border: 0,
//             clip: 'rect(0 0 0 0)',
//             height: 1,
//             margin: -1,
//             overflow: 'hidden',
//             padding: 0,
//             position: 'absolute',
//             top: 10,
//             width: 1,
//         },
//     }),
// );

interface HeadCell {
    disablePadding: boolean;
    id: keyof ConsultDocumentRecep;
    label: string;
    numeric: boolean;
    width: string;
    left: string;
    visible: boolean
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    classes: any;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ConsultDocumentRecep | keyof BajasFactura | keyof BajasBoleta) => void;
    order: Order;
    orderBy: string;
    headCellsp: any;
    rowCount: number
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { classes, order, orderBy, onRequestSort, headCellsp, numSelected, onSelectAllClick, rowCount } = props;
    // const {  order, orderBy, onRequestSort, headCellsp } = props;
    const createSortHandler = (property: keyof ConsultDocumentRecep | keyof BajasFactura | keyof BajasBoleta) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const headCells: readonly HeadCell[] = headCellsp;


    

    return (

        <TableHead sx={{ backgroundColor: '#B5CEF3', color: '#232323', }}>
            <TableRow sx={{
                borderBottomColor: '#B5CEF3', borderBottomStyle: 'solid',
                borderWidth: '1px',
            }}
            >
                <TableCell padding='checkbox' sx={{backgroundColor: '#E7EFFC'} }>
                    <Checkbox

                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',

                        }}
                    />
                </TableCell>
                {headCells.map((headCell, idx) => (
                    <TableCell
                        sx={{ backgroundColor: '#E7EFFC', color: '#232323', display: headCell.visible === true ? '' : 'none' }}
                        key={idx}
                        style={TableRowColumn}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={{
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                width: headCell.width, color: '#232323', paddingLeft: headCell.left,
                                textAlign: headCell.numeric == true ? 'center' : 'left',
                                backgroundColor: '#E7EFFC',
                                fontFamily: 'Poppins'
                            }} >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span style={classes.visuallyHidden}>
                                    {/* <span > */}
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export default EnhancedTableHead;