import React, { useState, useEffect } from "react";
import HeaderBackground from '../assets/headerBackground.webp'
import HeaderImage1 from '../assets/HeaderUsuario1.webp'
import HeaderImage2 from '../assets/HeaderUsuario2.webp'
import HeaderImage3 from '../assets/HeaderUsuario3.webp'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import { createTheme } from "@mui/material/styles";

import { AppBar, Box, Button, Container, makeStyles, Grid, ThemeProvider } from "@mui/material";

import Carousel from "react-material-ui-carousel";
import '../styles/HeaderStyles.css'
import Navbar from "./Shared/Navbar";
import Logo1 from '../assets/restaurantelogo.svg'
import Logo2 from '../assets/hoteleslogo.svg'
import Logo3 from '../assets/cafeterialogo.svg'
import Logo4 from '../assets/bareslogo.svg'
import Logo5 from '../assets/pastelerialogo.svg'
import MensajeIcon from '../assets/mensajepasivo.svg'
import BoxClientes from "./BoxClientes";
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { green } from '@mui/material/colors';
import Innovar from "./Innovar";

import GoBackPasive from '../assets/SUBIR-PASIVO.svg'
import GoBackActive from '../assets/SUBIR-ACTIVO.svg'

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// const SpanIndustria = styled.span`
// opacity: 0;
// transition: 1s,
// &.loaded {
//     opacity: 1;
// }
// `

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 720,
            md: 1080,
            lg: 1280,
            xl: 1440
        },
    },
});


const HeaderCambios = (props) => {

    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const { children, value, index, ...other } = props;


    const industrias = ['restaurantes', 'hoteles', 'cafeterias', 'bares', 'pastelerias', 'panaderias']
    const images = [HeaderImage1, HeaderImage2, HeaderImage3];

    const [indexIndustrias, setIndexIndustrias] = useState(0);
    const [industriaSelected, setIndustriaSelected] = useState(industrias[0]);

    const selectNewIndustria = (index, industrias, next = true) => {
        const condition = next ? indexIndustrias < industrias.length - 1 : indexIndustrias > 0;
        const nextIndex = next ? condition ? indexIndustrias + 1 : 0 : condition ? indexIndustrias - 1 : industrias.length - 1
        setIndustriaSelected(industrias[nextIndex]);
        setIndexIndustrias(nextIndex);
    }

    const previousIndustria = () => {
        selectNewIndustria(indexIndustrias, industrias, false);
    }

    const nextIndustria = () => {
        selectNewIndustria(indexIndustrias, industrias);
    }

    const [scrolling, setScrolling] = useState(false);


    const fabGreenStyle = {
        color: 'common.white',
        bgcolor: green[500],
        '&:hover': {
            bgcolor: green[600],
        },
    };

    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
    };

    const fabs = [
        {
            color: 'primary',
            sx: fabStyle,
            icon: <AddIcon />,
            label: 'Add',
        },
        {
            color: 'secondary',
            sx: fabStyle,
            icon: <EditIcon />,
            label: 'Edit',
        },
        {
            color: 'inherit',
            sx: { ...fabStyle, ...fabGreenStyle },
            icon: <UpIcon />,
            label: 'Expand',
        },
    ];



    useEffect(() => {
        const handleScroll = () => {
            const show = window?.scrollY > 100
            if (show) {
                setScrolling(true)
            } else {
                setScrolling(false)
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])


    return (

        <Box className="headerContent" style={{ backgroundImage: `url(${HeaderBackground})` }} sx={{
            height: { xs: '100%', sm: '500px', md: '960px' },
            display: { xs: 'flex', sm: 'flex', md: 'block' },
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            alignItems: 'center',

        }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', height: '100%', alignItems: 'center'}}>

                <Box
                sx={{ display: 'flex', flexDirection: 'row', width: '50%', height: 'max-content'}}
                
                >
                    <Box sx={{  width: '100%', height: '484px', 
                    // marginLeft: { xs: '10px', sm: '100px', md: '360px' }, 
                    // marginTop: { xs: '60px', sm: '60px', md: '110px' }, 
                    // marginTop: { xs: '120px', xl: '188px' },  
                    }}>

                    <Typography component={'h1'} sx={{
                        textAlign: 'left', width: { xs: '100%', md: '100%' }, height: 'auto', lineHeight: { xs: '38px', sm: '38px', md: '68px' }, color: 'white', fontFamily: 'PoppinsSemiBold', fontSize: {
                            xs: '36px',
                            sm: '36px',
                            md: '64px'
                        }
                    }}>Soluciones tecnologicas para
                        <Carousel
                            autoPlay
                            indicators={false}
                            navButtonsAlwaysInvisible
                            duration={500}

                        >
                            {
                                industrias.map((item, i) =>
                                    <Typography component={'span'} sx={{
                                        fontSize: {
                                            xs: '36px',
                                            sm: '36px',
                                            md: '64px'
                                        },
                                        color: '#6CD39B',
                                        fontFamily: 'PoppinsSemiBold'
                                    }} key={i}>{" "}{item}</Typography>
                                )
                            }
                        </Carousel>


                    </Typography>

                    <Typography component={'p'} sx={
                        { width: { xs: '320px', sm: '320px', md: '538px' }, fontFamily: 'PoppinsLight', lineHeight: { xs: '24px', sm: '24px', md: '32px' }, fontSize: { xs: '16px', sm: '16px', md: '20px' }, color: 'white' }}>Somos pioneros con 20 años de experiencia en el desarrollo e innovación de la industria HORECA. </Typography>

                    <Grid container spacing={1} sx={{ marginTop: '28px', width: { xs: '100%', sm: '100%', md: '100%' }, height: '68px', marginRight: { xs: '10px', md: '0px' }}}>

                        <Grid item xs={2.4}>
                            <img src={Logo1} />
                        </Grid>
                        <Grid item xs={2.4} sx={{ display: 'block', alignItems: 'center'}}>
                            <img src={Logo2} />
                        </Grid>
                        <Grid item xs={2}>
                            <img src={Logo3} />
                        </Grid>
                        <Grid item xs={2}>
                            <img src={Logo4} />
                        </Grid>
                        <Grid item xs={2.4}>
                            <img  src={Logo5} />
                        </Grid>


                    </Grid>

                    <Box style={{ marginTop: '60px', width: '50%' }}>
                        <Box
                            sx={{
                                backgroundColor: '#42C67E', borderRadius: '30px', padding: '12px 40px',
                                width: { xs: '320px', md: '300px' }, height: '56px', textTransform: 'uppercase',
                                ":hover": {
                                    backgroundColor: '#79DD8B'

                                },
                                textAlign: 'center',
                                color: 'white',
                                fontFamily: 'PoppinsLight'
                            }}>Encuentra tu solucion</Box>
                    </Box>


                    </Box>


                    <Box

                    sx={{
                        marginRight: '158px',
                        display: { xs: 'none', sm: 'none', md: 'flex' }
                    }}

                    >
                    <Carousel
                        autoPlay
                        sx={{ height: '680px', marginTop: { xs: '0px', sm: '0px', md: '0px' }, width: '780px' }}
                        indicators={false}
                        navButtonsAlwaysInvisible
                        duration={1000}
                    >
                        {
                            images.map((item, i) => (
                                <img className="imgHeader" key={i} src={item} />
                            )
                            )
                        }
                    </Carousel>
                    </Box>
                </Box>

                
            </Box>

            {/* <AppBar position="absolute" sx={{
                    left: 0, right: 0, bottom: 0, top: 0,
                    backgroundColor: 'transparent',
                    width: '64px', height: '64px',
                    boxShadow: 'none'
                }}> */}

            {/* <Button sx={{backgroundColor: '#42C67E', borderRadius: '24px', width: '64px', height: '64px', padding: '16px', position: 'absolute'}}>
                        <img src={MensajeIcon}/>
                </Button> */}
            {/* 
                    {fabs.map((fab, index) => (
                        <Zoom
                            key={fab.color}
                            in={value === index}
                            timeout={transitionDuration}
                            style={{
                                transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
                            }}
                            unmountOnExit
                        >
                            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
                                {fab.icon}
                            </Fab>
                        </Zoom>
                    ))} */}

            {/* </AppBar> */}



            <BoxClientes />

            <FloatingWhatsApp
                buttonStyle={
                    {

                        bottom: 16,
                        right: 95
                    }
                }
            />






        </Box>


    )
}

export default HeaderCambios