import React, {useEffect} from "react";
import {
    Box
} from "@mui/material";

import Logo from '../assets/INFOHOTEL.svg'
import DescripcionSolucion from "../components/DescripcionSolucion";

import InfohotelImg from '../assets/clienteInfohotel.jpg'
import ModulesComplements from "../components/ModulesComplements";





const inforestDesc = {
    description: 'Software que ayuda a administrar, planificar y controlar los procesos de negocios hoteleros.',
    items: [
        'Integración de tu página web con los motores de búsqueda para hoteles.',
        'Reserva y recepción de los huéspedes.',
        'Servicio técnico 24/7.',
        'Cierre y reportes de auditoria.',
        'Control de deudas de sus clientes, comandas y cargos automáticos y mensuales.',
    ],
    image: InfohotelImg
}

const inforestComp = {
    modulos : [
        {
            title: 'Funciones de Administración y Reglas de Negocio:',
            desc: 'Módulo de mantenimiento de tablas, administra y controla la información para la estandarización de procesos hoteleros.'
        }, 
        {
            title: 'Reservas:',
            desc: 'Inicio del proceso de venta de alojamiento, registra tarifas, fechas de ingreso y salida, anticipos facturados, tratamientos especiales, fichas de huésped, habitaciones y tipos, descuentos y promociones.'
        }, 
        {
            title: 'Auditoría:',
            desc: 'Central de control de resultados y saldos. Emite todo tipo de reportes solicitados en los negocios hoteleros.'
        }, 
        {
            title: 'Recepción: ',
            desc: 'Después de cada reserva, el módulo la convierte en una cuenta recepcionada. Asigna habitaciones a huéspedes y maneja reportes de control.'
        }, 
        {
            title: 'Cuentas Corrientes:',
            desc: 'Se acumulan los saldos de las cuentas corrientes de habitación y las cuentas corrientes de reserva.'
        }, 
    ],
    complementos: [{
        title: 'Eventos & Banquetes: ',
        desc: 'Organice y controle los eventos del hotel. Informe de instrucción. Gestione la ocupabilidad de sus salones por hora.'
    }, 
    {
        title: 'Booking Engine:',
        desc: 'Consulte en línea disponibilidad y ocupabilidad de los servicios. Procese en tiempo real sus reservas. Integrado a CM reservas.'
    }, 
    {
        title: 'Fidelización:  ',
        desc: 'Analice y segmente la información de sus huéspedes.'
    } ]
}

const Infohotel = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }, []);


    return (
        <Box

            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >

            <Box
                sx={{
                    width: '100%',
                    height: '80px',
                    backgroundColor: '#4884E2',
                    display: 'flex',
                    alignItems: 'center',
                    
                }}
            >
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}

            >
                <img width={'300px'} height={'200px'} src={Logo}></img>
            </Box>

            <DescripcionSolucion info={inforestDesc}/>
            <ModulesComplements info={inforestComp} colorPrimary={'#1877F2'}/>
        </Box>
    )
}

export default Infohotel;