import { Box, useScrollTrigger, Fade, Typography, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import MessageIcon from '../../assets/MENSAJERIA-PASIVO.svg'
import MessageIconHover from '../../assets/MENSAJERIA-HOVER.svg'
import GoBackPasive from '../../assets/SUBIR-PASIVO.svg'
import GoBackActive from '../../assets/SUBIR-ACTIVO.svg'

const WhatsappButton = () => {


    const [openChat, setOpenChat] = useState(false);
    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const show = window?.scrollY > 100
            if (show) {
                setScrolling(true)
            } else {
                setScrolling(false)
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])


    return (
        <Box sx={{
            width: '100px',
            height: '150px',
            backgroundColor: '',
            position: 'fixed',
            bottom: 60,
            right: { xs: 0,md:80},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 9999,

        }}>

            <Box
            
                sx={{width: '100%', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >

            
            {scrolling && <Box

                onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}
                sx={{
                    width: '48px',
                    height: '48px',
                    padding: '12px',
                    justifyContent: 'center',
                    alignItems: 'center',

                    borderRadius: '24px',
                    backgroundImage: `url(${GoBackPasive})`,
                    backgroundColor: "transparent",

                    ':hover': {
                        cursor: 'pointer',
                        backgroundImage: `url(${GoBackActive})`,

                    }
                }}

            >

            </Box>}

            </Box>


            <Fade in={openChat}>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        position: 'absolute',
                        width: '360px',
                        bottom: 80,
                        right: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 16px 0px rgba(25, 67, 127, 0.12)'

                    }}
                >



                    <Box
                        sx={{
                            width: '100%',
                            padding: '20px',
                            backgroundColor: '#32de84'
                        }}

                    >


                        
                    </Box>


                    <Box
                        sx={{
                            width: '100%',
                            backgroundColor: '',
                            gap: '8px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >

                        <Typography sx={{
                            color: '#2A6EDB',
                            letterSpacing: '1px',
                            fontSize: '20px',
                            fontFamily: 'Poppins'
                        }}>
                            Cliente General
                        </Typography>


                        <Box>
                            <Typography sx={{
                                color: '#88919E',
                                fontSize: '14px',
                                fontFamily: 'Poppins'
                            }}>
                                Administración General Jr.
                            </Typography>
                            <Typography sx={{
                                color: '#88919E',
                                fontSize: '14px',
                                fontFamily: 'Poppins'
                            }}>
                                cliente.general@gmail.com
                            </Typography>

                        </Box>



                    </Box>

                    <Divider sx={{ backgroundColor: '#88919E', marginTop: '20px' }} />

                    <Box sx={{
                        width: '100%',
                        paddingY: '12px',
                        paddingX: '20px',
                        display: 'flex',
                        justifyContent: 'flex-end',

                        ':hover': {
                            cursor: 'pointer'
                        }
                    }}>
                        <Box 
                            sx={{
                                width: '160px',
                                height: '56px',
                                backgroundColor: '#42C67E',
                                boxShadow: '0px 2px 8px 0px rgba(25, 67, 127, 0.14)',
                                borderRadius: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                        <Typography
                            sx={{
                                color: '#FFF',
                                fontSize: '16px',
                                fontFamily: 'Poppins',

                            }}
                        >
                           Abrir chat
                        </Typography>
                        </Box>
                        
                    </Box>
                </Box>
            </Fade>
            <Box

                onClick={() => setOpenChat(!openChat)}
                sx={{
                    width: '64px',
                    height: '64px',
                    // backgroundColor: '#42C67E',
                    borderRadius: '24px',
                    backgroundImage: `url(${MessageIcon})`,

                    ':hover': {
                        backgroundImage: `url(${MessageIconHover})`,
                        cursor: 'pointer'
                    }
                }}

            >

            </Box>
        </Box>
    )
}

export default WhatsappButton