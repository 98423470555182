import React, { useContext, useState } from "react";
import { Box, Typography, Grid, Stack, Pagination } from "@mui/material";
import StickyHeadTable from "../../components/Shared/Intranet/Tabla";
import RenderType from "../../components/Shared/Intranet/RenderType";
import TablaMenu from "../../components/Shared/Intranet/TablaPagos";
import { ConsultDocumentRecep } from "../../models/models/consultaDocumento";
import TabPanelPagos from "../../components/Shared/Intranet/TapPanelPagos";
import { SidebarContext } from "../../components/context/SidebarContext";



const Pagos = () => {

    const {cantPage, setCantPage, numfilas, setNumFilas} = useContext(SidebarContext);

    const handleChangePage = (event: unknown, newPage: number) => {
        console.log(event);
        setPage(newPage - 1);

    };

    

    const headCells: any =
        [
            { id: 'serienumero', numeric: false, disablePadding: false, label: 'Serie-Número', width: '220px', left: '10px', visible: true },
            { id: 'marca', numeric: true, disablePadding: true, label: 'Marca', width: '240px', left: '0px', visible: true },
            { id: 'local', numeric: true, disablePadding: true, label: 'Local', width: '280px', left: '0px', visible: true },
            { id: 'documento', numeric: true, disablePadding: false, label: 'Documento', width: '160px', left: '0px', visible: true },
            { id: 'emision', numeric: true, disablePadding: true, label: 'Emisión', width: '120px', left: '0px', visible: true },
            { id: 'montototal', numeric: false, disablePadding: false, label: 'monto total', width: '140px', left: '0px', visible: true },
            { id: 'descripcion', numeric: true, disablePadding: false, label: 'Descripcion', width: '588px', left: '0px', visible: true },
            // { id: 'fechadeclaracion', numeric: true, disablePadding: false, label: 'fecha declaracion', width: '160px', left: '0px', visible: true },
            // { id: 'observacion', numeric: false, disablePadding: false, label: 'observación', width: '440px', left: '0px', visible: true },

        ];

    const [documentos, setDocumentos] = useState<ConsultDocumentRecep[]>([
        {
            id: '1',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '2',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '3',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '4',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '5',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '6',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '7',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '8',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '9',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '10',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '11',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '12',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '13',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '14',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '15',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '16',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '16',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
        {
            id: '16',
            receptor: 'LA VICTORIA RESTOBAR',
            tipodocumento: 'Boleta',
            documento: 'B000-000000008',
            moneda: 'PEN-SOL',
            gravada: 1,
            inafecta: 1,
            exonerada: 1,
            exportacion: 1,
            gratuita: 1,
            igv: 1,
            servicio: 1,
            totalventa: 999.99,
            fechaemision: '08/08/2023',
            estado: 'Estado',
            fechadeclaracion: '08/08/2023',
            observacion: 'Ninguna',
            rucreceptor: 'RUC',
            documentoReferencia: 'REF',
        },
    ]);
    const [page, setPage] = useState(0);

    return (
        <Box sx={{

        }}>
            <Box sx={{
                 paddingLeft: { xs: '20px' ,md:'88px'},
                 paddingRight: '20px'
            }}>
                <Typography sx={{
                    fontSize: '24px',
                    fontFamily: 'Poppins'
                }}>
                    <Typography component={'span'} sx={{
                        color: '#2A6EDB',
                        fontSize: '24px',
                        fontFamily: 'Poppins'

                    }}>Mis Documentos /</Typography> Aqui puedes revisar y gestionar el estado de tus documentos de pago
                </Typography>
            </Box>
            <TabPanelPagos />

            {/* <StickyHeadTable /> */}
            <TablaMenu

                datos={documentos}
                page={page}
                rowsPerPage={numfilas}
                setCantPage={setCantPage}
                headCells={headCells}
                menu='comprobantes' />

            <Grid item lg={12} md={12} sm={12} xs={12} spacing={5} paddingTop={'40px'} paddingBottom={'40px'} >
                <Stack spacing={2} sx={{ alignItems: 'center' }}>
                    <Pagination
                        defaultPage={page + 1}
                        count={cantPage}
                        // count={10}
                        color="primary"
                        onChange={handleChangePage}
                    // boundaryCount={3} 
                    />
                </Stack>
            </Grid>
            {/* <RenderType texto={'Hola Mundo'} /> */}
        </Box>
    )
}

export default Pagos;